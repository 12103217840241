import { AxiosResponse } from "axios";
import {
  AverageTime,
  LineReport,
  MachineReport,
  Report,
  TimeSeries,
  UserReport,
} from "../types";
import { instance } from "./axios";

export interface IReportApi {
  getMachineReports(): Promise<AxiosResponse<Array<MachineReport>>>;
  getLineReports(): Promise<AxiosResponse<Array<LineReport>>>;
  getReports(): Promise<AxiosResponse<Array<Report>>>;
  getAverageReactionTime(user_id: number): Promise<AxiosResponse<AverageTime>>;
  getAverageTimeToHandleTicket(
    user_id: number
  ): Promise<AxiosResponse<AverageTime>>;
  getUserReport(user_id: number): Promise<AxiosResponse<UserReport>>;
  getTimeSeriesByLine(
    line_id: number
  ): Promise<AxiosResponse<Array<TimeSeries>>>;
}

export const ReportApi: IReportApi = {
  async getLineReports(): Promise<AxiosResponse<Array<LineReport>>> {
    const route = "/reports/line_reports";
    return await instance.get<Array<LineReport>>(route);
  },
  async getReports(): Promise<AxiosResponse<Array<Report>>> {
    const route = "/reports/";
    return await instance.get<Array<Report>>(route);
  },
  async getAverageReactionTime(
    user_id: number
  ): Promise<AxiosResponse<AverageTime>> {
    const route = `reports/average-reaction-time/${user_id}`;
    return await instance.get<AverageTime>(route);
  },
  async getAverageTimeToHandleTicket(
    user_id: number
  ): Promise<AxiosResponse<AverageTime>> {
    const route = `reports/average-time-to-handle-ticket/${user_id}`;
    return await instance.get<AverageTime>(route);
  },
  async getUserReport(user_id: number): Promise<AxiosResponse<UserReport>> {
    const route = `reports/user_report/${user_id}`;
    return await instance.get<UserReport>(route);
  },
  async getTimeSeriesByLine(
    line_id: number
  ): Promise<AxiosResponse<Array<TimeSeries>>> {
    const route = `reports/time-series/${line_id}`;
    return await instance.get<Array<TimeSeries>>(route);
  },
  async getMachineReports(): Promise<AxiosResponse<Array<MachineReport>>> {
    const route = "reports/machine_reports";
    return await instance.get<Array<MachineReport>>(route);
  },
};
