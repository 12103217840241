import React, { useContext } from "react";
import { FormikContext } from "formik";
export const TestButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, disabled, type, ...rest }) => {
  const formik = useContext(FormikContext);
  return (
    <button
      disabled={!formik.isValid || !formik.touched || disabled}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
};
