import React from "react";
import { Machine, MachineProblemTypeEnum, Reason } from "../../types";
import { getMachineProblemType } from "../../utils";

import DatePicker from "react-datepicker";
import { CalendarIcon } from "@heroicons/react/outline";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
type Props = {
  isGrid: boolean;
  handleChange: () => any;
  machines: Array<Machine>;
  reasons: Array<Reason>;
  handleReasonChange: (reason: number) => void;
  handleTicketChange: (type: MachineProblemTypeEnum) => void;
  handleMachineChange: (machine: number) => void;
  ticketType: MachineProblemTypeEnum;
  machine: number;
  reason: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
  setStartDate: (date: moment.Moment) => void;
  setEndDate: (date: moment.Moment) => void;
};

export const TicketsHeader: React.FC<Props> = ({
  isGrid,
  handleChange,
  machines,
  handleMachineChange,
  handleTicketChange,
  ticketType,
  machine,
  reason,
  reasons,
  handleReasonChange,
  startDate,
  endDate,
  setStartDate,
  setEndDate
}) => {
  const ticketTypeOptions = () => {
    return Object.keys(MachineProblemTypeEnum)
      .filter((e) => typeof MachineProblemTypeEnum[e as any] === "number")
      .map((key) => MachineProblemTypeEnum[key as any])
      .map((k) => {
        return (
          <option value={String(k)} key={k}>
            {getMachineProblemType(parseInt(k))}
          </option>
        );
      });
  };

  const machineOptions = () => {
    return machines.map(({ id_, name }, key) => (
      <option value={id_} key={key}>
        {name}
      </option>
    ));
  };

  const reasonOptions = () => {
    return reasons.map(({ id_, reason }, key) => (
      <option value={id_} key={key}>
        {reason}
      </option>
    ));
  };
  // {
  //   name : "",
  //   material_part_number : ""
  // }
  return (
    <form
      className={`flex gap-x-3 mx-4 justify-around justify-items-center ${
        isGrid ? "mb-16" : "mb-8"
      } mt-8`}
    >
      <div className="">
        <div className="relative">
          <div className="text-lg text-white">Време од</div>
          <DatePicker
            className="bg-gray-800 text-lg text-white"
            selected={startDate.toDate()}
            onChange={(date: Date) => setStartDate(moment(date))}
          />
          <CalendarIcon className="absolute top-8 right-0 h-4 w-4 bg-white" />
        </div>
      </div>
      <div className="">
        <div className="relative">
          <div className="text-lg text-white">Време до</div>
          <DatePicker
            className="bg-gray-800 text-lg text-white"
            selected={endDate.toDate()}
            onChange={(date: Date) => setEndDate(moment(date))}
          />
          <CalendarIcon className="absolute top-8 right-0 h-4 w-4 bg-white" />

        </div>
      </div>
      <select
        name="ticketType"
        id="ticketType"
        value={ticketType}
        className="flex rounded-lg border-2 bg-black p-5 text-white"
        onChange={(e) => handleTicketChange(parseInt(e.target.value))}
      >
        <option value={undefined}>Селектирајте тип на тикет</option>
        {ticketTypeOptions()}
      </select>
      <select
        name="reason"
        id="reason"
        value={reason}
        className="flex rounded-lg border-2 bg-black p-5 text-white"
        onChange={(e) => handleReasonChange(parseInt(e.target.value))}
      >
        <option value="">Селектирајте причина</option>
        {reasonOptions()}
      </select>
      <button
        type="button"
        className="flex rounded-lg border-2 p-5 text-white"
        onClick={handleChange}
      >
        <div className="mr-3">Промени приказ</div>
        <div className="">
          {!isGrid ? (
            <img
              src="./view-grid.svg"
              alt="grid icon"
              className="h-8 w-8 rounded-sm bg-white"
            />
          ) : (
            <img
              src="./table.svg"
              className="h-8 w-8 rounded-sm bg-white"
              alt="table icon"
            />
          )}
        </div>
      </button>
      <select
        name="machine"
        id="machine"
        value={machine}
        className="flex rounded-lg border-2 bg-black p-5 text-white"
        onChange={(e) => handleMachineChange(parseInt(e.target.value))}
      >
        <option value="">Селектирајте машина</option>
        {machineOptions()}
      </select>
    </form>
  );
};
