import { useEffect, useState } from "react";
import { ActiveLine } from "../components/Lines/ActiveLine";
import { InactiveLine } from "../components/Lines/InactiveLine";
import { Loader } from "../components/Loader/Loader";
import { Modal } from "../components/Modal/Modal";
import { useSessionStorage } from "../hooks/useSessionStorage";
import { LineService } from "../service/lines";
import { ReportService } from "../service/reports";
import { LineStatus, ModalType, Status } from "../types";

export const ConsoleHomePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [user] = useSessionStorage("user");

  const [open, setIsOpen] = useState(false);

  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);

  useEffect(() => {
    setIsLoading(true);
    ReportService.getTimeSeriesByLine(user.id_).then((resp) => {
      if (resp.status === Status.SUCCESS) {
        if (resp.value.length === 0) {
          setIsActive(true);

          setIsLoading(false);
          return;
        }
        console.log(resp.value[resp.value.length - 1]);

        if (
          resp.value[resp.value.length - 1].status === LineStatus.NOT_RUNNING
        ) {
          setIsActive(false);
        } else {
          setIsActive(true);
        }
        setIsLoading(false);
      }
      if (resp.status === Status.ERROR) {
        setIsOpen(true);
        setModalProps({
          title: "Настана грешка",
          subtitle: `${resp.message}`,
        });
      }
      setIsLoading(false);
    });
  }, [user.id_]);

  const handleDeactivateLine = () => {
    LineService.inactivateLine(user.id_).then((resp) => {
      if (resp.status === Status.SUCCESS) {
        setIsActive(false);
      } else if (resp.status === Status.ERROR) {
        setIsOpen(true);
        setModalProps({
          title: "Настана грешка",
          subtitle: `${resp.message}`,
        });
      }
    });
  };

  const handleActivateLine = () => {
    LineService.activateLine(user.id_).then((resp) => {
      if (resp.status === Status.SUCCESS) {
        setIsActive(true);
      } else if (resp.status === Status.ERROR) {
        setIsOpen(true);
        setModalProps({
          title: "Настана грешка",
          subtitle: `${resp.message}`,
        });
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isActive ? (
            <ActiveLine deactivateLine={handleDeactivateLine} />
          ) : (
            <InactiveLine activateLine={handleActivateLine} />
          )}
        </>
      )}
      <Modal open={open} setOpen={setIsOpen} modalProps={modalProps} />
    </>
  );
};
