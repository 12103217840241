import { AxiosResponse } from "axios";
import { instance } from "./axios";
import { Machine, Page } from "../types";
export interface IMachineApi {
  fetchAll(
    line_id: number,
    page_number: number,
    page_size: number
  ): Promise<AxiosResponse<Page<Machine>>>;
  fetchById(machine_id: number): Promise<AxiosResponse<Machine>>;
}
export const MachineApi: IMachineApi = {
  async fetchAll(
    line_id: number,
    page: number,
    page_size: number
  ): Promise<AxiosResponse<Page<Machine>>> {
    const route = `lines/${line_id}/machines`;
    return await instance.get<Page<Machine>>(route, {
      params: { page, page_size },
    });
  },
  async fetchById(machine_id: number): Promise<AxiosResponse<Machine>> {
    const route = `machines/${machine_id}`;
    return await instance.get<Machine>(route);
  },
};
