type ButtonProps = {
  classes: string;
  onClick?: () => any;
  buttonName: string;
  disabled?: boolean;
};

export const ButtonComponent: React.FC<ButtonProps> = ({
  classes,
  onClick,
  buttonName,
  disabled,
  children,
}) => {
  return (
    <button
      className={
        classes + " text-center bg-aptiv-orange font-normal text-white"
      }
      onClick={onClick}
      disabled={disabled}
    >
      {buttonName}
    </button>
  );
};
