import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Ticket,
  Status,
  Result,
  Page,
  MachineProblemTypeEnum,
  Machine,
  Reason,
  TicketOrderBy,
  OrderDirection,
} from "../types";
import { TicketService } from "../service/tickets";
import { GridList } from "../components/TicketsOverview/GridList";
import { TableList } from "../components/TicketsOverview/TableList";
import { TicketsHeader } from "../components/TicketsOverview/TicketsHeader";
import { StatusEnum } from "../types";
import { useSessionStorage } from "../hooks/useSessionStorage";
import { usePagination } from "../hooks/usePagination";
import { Pagination } from "../components/Pagination/Pagination";
import { Loader } from "../components/Loader/Loader";
import { MachineService } from "../service/machines";
import { ReasonService } from "../service/reason";
import moment from "moment";

type Props = {
  status?: Array<StatusEnum>;
  type?: Array<MachineProblemTypeEnum>;
};

const options = {
  root: null,
  rootMargin: "0px",
  threshold: 1.0,
};

type SortState = {
  key: TicketOrderBy;
  direction: OrderDirection;
};

export const OngoingTickets: React.FC<Props> = ({ status, type }) => {
  const [isGrid, setIsGrid] = useState<boolean>(true);
  const [gridPage, setGridPage] = useState(0);
  const [gridState, setGridState] = useState<Array<Ticket>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [machines, setMachines] = useState<Array<Machine>>([]);
  const [reasons, setReasons] = useState<Array<Reason>>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().startOf("month")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(
    moment().endOf("month")
  );
  const [sortedColumn, setSortedColumn] = useState<SortState>({
    key: TicketOrderBy.CREATED_AT,
    direction: OrderDirection.DESCENDING,
  });

  const requestSort = (key: TicketOrderBy) => {
    let direction = OrderDirection.DESCENDING;
    if (
      sortedColumn.key === key &&
      sortedColumn.direction === OrderDirection.DESCENDING
    ) {
      direction = OrderDirection.ASCENDING;
    }
    setSortedColumn({ key, direction });
  };

  const icons = (columnName: TicketOrderBy) => {
    if (columnName === sortedColumn.key) {
      if (sortedColumn.direction === OrderDirection.DESCENDING) {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="-mt-1 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 13l-5 5m0 0l-5-5m5 5V6"
            />
          </svg>
        );
      } else {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="-mt-1 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 11l5-5m0 0l5 5m-5-5v12"
            />
          </svg>
        );
      }
    }
  };

  const [ticketType, setTicketType] = useState<
    MachineProblemTypeEnum | undefined
  >(undefined);
  const [machine, setMachine] = useState<number | undefined>(undefined);
  const [reason, setReason] = useState<number | undefined>(undefined);
  const typeFilter =
    ticketType === undefined || isNaN(ticketType)
      ? [
          MachineProblemTypeEnum.MACHINE_PROBLEM,
          MachineProblemTypeEnum.MAINTENANCE_PROBLEM,
          MachineProblemTypeEnum.MATERIALS_PROBLEM,
          MachineProblemTypeEnum.PLANNED_ACTION,
          MachineProblemTypeEnum.QUALITY_PROBLEM,
        ]
      : [ticketType];
  const {
    max,
    page,
    content,
    handleNextPage,
    handlePrevPage,
    handleChangeContent,
    handleGotoPage,
  } = usePagination<Ticket>(
    TicketService.fetchAllByLineId,
    7,
    status,
    typeFilter || null,
    [sortedColumn.key],
    sortedColumn.direction,
    startDate.format("YYYY-MM-DD[T]HH:mm:ss"),
    endDate.format("YYYY-MM-DD[T]HH:mm:ss")
    //type || [ticketType!]
  );

  const loader = useRef(null);
  const [user] = useSessionStorage("user");

  const handleObserver = useCallback((entries: any) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setShouldRefresh(false);
      setGridPage((g) => g + 1);
    }
  }, []);

  useEffect(() => {
    MachineService.fetchAll(user.id_, 0, 100).then(
      (resp: Result<Page<Machine>>) => {
        if (resp.status === Status.SUCCESS) {
          setMachines(resp.value.content);
        }
      }
    );
    ReasonService.fetchAll(0, 100).then((resp: Result<Page<Reason>>) => {
      if (resp.status === Status.SUCCESS) {
        setReasons(resp.value.content);
      }
    });
  }, [user.id_]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
    //return () => observer.disconnect();
  }, [handleObserver]);

  useEffect(() => {
    // if(!isGrid){
    setIsLoading(true);
    if (machine) {
      TicketService.fetchAllByMachineId(machine, gridPage, 10, status!).then(
        (result: Result<Page<Ticket>>) => {
          if (result.status === Status.SUCCESS) {
            handleChangeContent(result);
            if (shouldRefresh) {
              setGridState(result.value.content);
              return;
            }
            setGridState((old_state: Array<Ticket>) => [
              ...old_state,
              ...result.value.content,
            ]);
            setIsLoading(false);
          }
        }
      );
    } else {
      TicketService.fetchAllByLineId(
        user.id_,
        gridPage,
        10,
        status,
        typeFilter,
        reason,
        [sortedColumn.key],
        sortedColumn.direction,
        startDate.format("YYYY-MM-DD[T]HH:mm:ss"),
        endDate.format("YYYY-MM-DD[T]HH:mm:ss")
      ).then((result: Result<Page<Ticket>>) => {
        if (result.status === Status.SUCCESS) {
          // handleChangeContent(result);
          if (shouldRefresh) {
            setGridState(result.value.content);
            return;
          }
          setGridState((old_state: Array<Ticket>) => [
            ...old_state,
            ...result.value.content,
          ]);
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line
  }, [
    user.id_,
    gridPage,
    status,
    type,
    ticketType,
    shouldRefresh,
    machine,
    reason,
    sortedColumn,
    startDate,
    endDate,
    // eslint-disable-next-line
    JSON.stringify(typeFilter),
  ]);

  const renderer = () => {
    if (isGrid) {
      return (
        <GridList tickets={gridState} loader={loader} isLoading={isLoading} />
      );
    } else {
      switch (content.status) {
        case Status.ERROR:
          return <div>{content.message}</div>;
        case Status.LOADING:
          return <Loader />;
        case Status.SUCCESS:
          return (
            <TableList
              tickets={content.value.content}
              requestSort={requestSort}
              icons={icons}
            />
          );
      }
    }
  };

  const handleChange = () => {
    setIsGrid(!isGrid);
  };

  const handleTicketTypeChange = (type: MachineProblemTypeEnum) => {
    setTicketType(type);
    setMachine(undefined);
    setReason(undefined);
    setGridPage(0);
    setShouldRefresh(true);
  };

  const handleMachineChange = (machine: number) => {
    setMachine(machine);
    setReason(undefined);
    setTicketType(undefined);
    setGridPage(0);
    setShouldRefresh(true);
  };

  const handleReasonChange = (reason: number) => {
    setReason(reason);
    setMachine(undefined);
    setTicketType(undefined);
    setGridPage(0);
    setShouldRefresh(true);
  };

  const handleStartDateChange = (date: moment.Moment) => {
    setStartDate(date);
    setGridPage(0);
    setShouldRefresh(true);
    setMachine(undefined);
  };

  const handleEndDateChange = (date: moment.Moment) => {
    setEndDate(date);
    setGridPage(0);
    setShouldRefresh(true);
    setMachine(undefined);
  };

  // console.log(moment(startDate.setHours(0,0,0,0)).format("YYYY-MM-DD[T]HH:mm:ss"))
  // console.log(moment(endDate.setHours(0,0,0,0)).format("YYYY-MM-DD[T]HH:mm:ss"))

  return (
    <div>
      <TicketsHeader
        isGrid={isGrid}
        handleChange={handleChange}
        machines={machines}
        reasons={reasons}
        handleTicketChange={handleTicketTypeChange}
        handleMachineChange={handleMachineChange}
        handleReasonChange={handleReasonChange}
        ticketType={ticketType!}
        machine={machine!}
        reason={reason!}
        startDate={startDate}
        setStartDate={handleStartDateChange}
        endDate={endDate}
        setEndDate={handleEndDateChange}
      />
      {!isGrid && (
        <Pagination
          current={page}
          last={max}
          onNext={handleNextPage}
          onPrev={handlePrevPage}
          onSelect={handleGotoPage}
        />
      )}

      {renderer()}
    </div>
  );
};
