import { TextInput } from "../TextInput/TextInput";
import React, { useRef, useState } from "react";
import { ErrorMessage, Form, Formik, useField, useFormikContext } from "formik";
import * as Yup from "yup";
//import { useNavigate } from "react-router-dom";
import { useKeyboard } from "../../hooks/useKeyboard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useTicket } from "../../TicketContext";
import {
  CreateTicketRequest,
  Machine,
  MachineProblemTypeEnum,
  PriorityTypeEnum,
  StatusEnum,
} from "../../types";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import Keyboard from "react-simple-keyboard";

export const DatePickerField = ({ ...props }) => {
  //const { dispatch } = useTicket();
  const { setFieldValue } = useFormikContext();
  //@ts-ignore
  const [field] = useField(props);

  return (
    <DatePicker
      {...field}
      {...props}
      dateFormat={"dd/MM/yyyy"}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

const OpenRequestSchema = Yup.object().shape({
  id: Yup.string().required("Задолжително поле"),
  date: Yup.date()
    .nullable()
    .required("Задолжително поле")
    .min(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      "Акцијата не може да биде пред денешен ден"
    ),
  start_hour: Yup.number()
    .required("Задолжително поле")
    .lessThan(24, "Часот не може да биде поголем од 24")
    .moreThan(-1, "Часот не може да биде помал од 0"),
  start_minute: Yup.number()
    .required("Задолжително поле")
    .lessThan(60, "Минутите не може да бидат поголеми од 59")
    .moreThan(-1, "Минутите не може да бидат помали од 0"),
  end_hour: Yup.number()
    .required("Задолжително поле")
    .lessThan(24, "Часот не може да биде поголем од 24")
    .moreThan(-1, "Часот не може да биде помал од 0"),
  end_minute: Yup.number()
    .required("Задолжително поле")
    .lessThan(60, "Минутите не може да бидат поголеми од 59")
    .moreThan(-1, "Минутите не може да бидат помали од 0"),
});

type RequestProps = {
  onSubmit: (action: CreateTicketRequest) => void;
};

const options = {
  layout: {
    default: ["1 2 3", "4 5 6", "7 8 9", "{clr} 0 {bksp}"],
  },
  mergeDisplay: true,
  display: {
    "{bksp}": "избриши",
    "{clr}": "бриши сѐ",
  },
  buttonTheme: [
    {
      class: "bg-red-500",
      buttons: "1",
    },
  ],
  theme: "hg-theme-default myTheme1",
};

export const OpenRequest: React.FC<RequestProps> = ({ onSubmit }) => {
  const { dispatch, state } = useTicket();
  const [user] = useSessionStorage("user");

  const [isVisible, toggleVisible] = useKeyboard(false);
  const keyboard = useRef(null);
  const [inputs, setInputs] = useState({});
  console.log(inputs);
  const [inputName, setInputName] = useState("default");
  const [layoutName, setLayoutName] = useState("default");

  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  const onChangeAll = (inputs: any) => {
    setInputs({ ...inputs });
  };

  const handleKeyboardStatusChange = () => {
    toggleVisible();
  };

  const onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  return (
    <div>
      <Formik
        initialValues={{
          id: "",
          date: "",
          start_hour: "",
          start_minute: "",
          end_hour: "",
          end_minute: "",
        }}
        onSubmit={(values, actions) => {
          console.log(values);
          var date = moment(values.date);
          var dateTimeFrom = moment()
            .hour(parseInt(values.start_hour))
            .minute(parseInt(values.start_minute))
            .date(date.date())
            .month(date.month())
            .year(date.year())
            .second(0);
          var dateTimeTo = moment()
            .hour(parseInt(values.end_hour))
            .minute(parseInt(values.end_minute))
            .date(date.date())
            .month(date.month())
            .year(date.year())
            .second(0);

          dispatch({
            type: "SET_DATE",
            payload: values.date,
          });
          dispatch({
            type: "SET_TIME_FROM",
            payload: dateTimeFrom,
          });
          dispatch({
            type: "SET_TIME_TO",
            payload: dateTimeTo,
          });
          const action: CreateTicketRequest = {
            ticket_type: MachineProblemTypeEnum.PLANNED_ACTION,
            created_by_card_id: values.id,
            line_id: user.id_,
            machine_ids: state.machines.map((m: Machine) => m.id_),
            datetime_from: moment(dateTimeFrom).format("YYYY-MM-DD[T]HH:mm:ss"),
            datetime_to: moment(dateTimeTo).format("YYYY-MM-DD[T]HH:mm:ss"),
            priority: PriorityTypeEnum.PLANNED_ACTION,
            status: StatusEnum.WAITING_FOR_APPROVAL,
          };
          onSubmit(action);
        }}
        validationSchema={OpenRequestSchema}
      >
        {(props) => (
          <Form
            className="mt-5 w-full"
            onSubmit={(e) => e.preventDefault()}
            action="return false;"
          >
            <div className="grid justify-center">
              <div className="text-center text-white">Изберете датум</div>
              <DatePickerField
                className="h-16 w-72 rounded-md border"
                name="date"
                autoComplete="off"
                closeCalendar={true}
                openCalendarOnFocus={false}
                startDate={new Date()}
              />
              <div className="mb-5 mt-2 h-2 text-center">
                <ErrorMessage name={"date"}>
                  {(errorMessage) => (
                    <span className="font-regular text-xl text-red-600">
                      {errorMessage}
                    </span>
                  )}
                </ErrorMessage>
              </div>
            </div>
            <div className="mt-2 grid justify-center">
              <div className="mx-auto text-white">
                Изберете време за почеток на акција
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <TextInput
                  type="text"
                  name="start_hour"
                  id="start_hour"
                  autoFocus={true}
                  placeholder="Почеток (час)"
                  onClick={handleKeyboardStatusChange}
                  setInputName={setInputName}
                  fieldAttributes={{ maxLength: 2 }}
                />
                <TextInput
                  type="text"
                  name="start_minute"
                  id="start_minute"
                  autoFocus={true}
                  placeholder="Почеток (минути)"
                  onClick={handleKeyboardStatusChange}
                  setInputName={setInputName}
                  fieldAttributes={{ maxLength: 2 }}
                />
              </div>
            </div>

            <div className="mt-2 grid justify-center">
              <div className="mx-auto text-white">
                Изберете време за завршена акција
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <TextInput
                  type="text"
                  name="end_hour"
                  id="end_hour"
                  autoFocus={true}
                  placeholder="Крај (час)"
                  onClick={handleKeyboardStatusChange}
                  setInputName={setInputName}
                  fieldAttributes={{ maxLength: 2 }}
                />
                <TextInput
                  type="text"
                  name="end_minute"
                  id="end_minute"
                  autoFocus={true}
                  placeholder="Крај (минути)"
                  onClick={handleKeyboardStatusChange}
                  setInputName={setInputName}
                  fieldAttributes={{ maxLength: 2 }}
                />
              </div>
            </div>
            <label
              htmlFor="name"
              className="block text-center font-medium text-white"
            >
              Инженер ид
            </label>

            <div className="flex justify-center">
              <TextInput
                type="text"
                name="id"
                id="id"
                autoFocus={true}
                placeholder="id"
                onClick={handleKeyboardStatusChange}
                setInputName={setInputName}
              ></TextInput>
            </div>
            <div className="mt-3 flex justify-center">
              <button
                className={
                  "mt-3 mr-2 mb-2 rounded-md bg-aptiv-orange py-4 px-4 text-3xl"
                }
                type="button"
                onClick={() => props.handleSubmit()}
              >
                Прати барање за акција
              </button>
            </div>
            {isVisible && (
              <div className="absolute right-0 bottom-10 w-3/5">
                <Keyboard
                  keyboardRef={(r) => (keyboard.current = r)}
                  inputName={inputName}
                  onChangeAll={(inputs: any) => {
                    props.setFieldValue("id", inputs.id);
                    props.setFieldValue("start_hour", inputs.start_hour);
                    props.setFieldValue("start_minute", inputs.start_minute);
                    props.setFieldValue("end_hour", inputs.end_hour);
                    props.setFieldValue("end_minute", inputs.end_minute);
                    onChangeAll(inputs);
                  }}
                  onKeyPress={onKeyPress}
                  layoutName={layoutName}
                  {...options}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
