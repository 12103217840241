import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { IssueTypeHeader } from "../components/IssueTypeHeader/IssueTypeHeader";
import * as Yup from "yup";
import { TextInput } from "../components/TextInput/TextInput";
import { ButtonComponent } from "../components/ButtonComponent/ButtonComponent";
import { useTicket } from "../TicketContext";
import { useNavigate } from "react-router-dom";
import {
  CreateTicketRequest,
  ModalType,
  PriorityTypeEnum,
  Result,
  Status,
  Ticket,
  User,
} from "../types";
import { TicketService } from "../service/tickets";
import { useKeyboard } from "../hooks/useKeyboard";
import Keyboard from "react-simple-keyboard";
import { TestButton } from "../components/TestButton/TestButton";
import { AccountService } from "../service/accounts";
import { useSessionStorage } from "../hooks/useSessionStorage";
import { Modal } from "../components/Modal/Modal";
const MaterialIssueSchema = Yup.object().shape({
  operatorId: Yup.string().required("Задолжително поле"),
  componentNumber: Yup.string().required("Задолжително поле"),
});

const layout = {
  default: [
    "1 2 3 4 5 6 7 8 9 0 {bksp}",
    "q w e r t y u i o p",
    "a s d f g h j k l",
    "{shift} z x c v b n m",
  ],
  shift: [
    "1 2 3 4 5 6 7 8 9 0 {bksp}",
    "Q W E R T Y U I O P",
    "A S D F G H J K L",
    "{shift} Z X C V B N M",
  ],
};

export const MaterialIssueForm: React.FC = () => {
  const { dispatch } = useTicket();
  const ticketContext = useTicket();
  const ticketState = ticketContext.state;
  const navigate = useNavigate();
  const dispatchTicket = ticketContext.dispatch;
  const [isVisible, toggleVisible] = useKeyboard(false);
  const keyboard = useRef(null);
  const [inputs, setInputs] = useState({});
  const [inputName, setInputName] = useState("default");
  const [layoutName, setLayoutName] = useState("default");
  const [user] = useSessionStorage("user");

  const [open, setIsOpen] = useState(false);

  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);

  const onChangeAll = (inputs: any) => {
    setInputs({ ...inputs });
  };

  const handleKeyboardStatusChange = () => {
    toggleVisible();
  };

  const handleShift = () => {
    console.log(inputs);
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  const onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const onSubmit = async (ticket: CreateTicketRequest, id: string) => {
    const user: Result<User> = await AccountService.fetchByCardId(id);
    if (user.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }

    dispatchTicket({
      type: "SET_OPERATOR_ID",
      payload: user.value,
    });

    dispatch({
      type: "SET_COMPONENT_NUMBER",
      payload: ticket.component_id,
    });
    const result: Result<Ticket> = await TicketService.createTicket(ticket);
    if (result.status === Status.SUCCESS) {
      dispatchTicket({
        type: "SET_TICKET_ID",
        payload: result.value.id_,
      });
      const id = result.value.id_;
      navigate(`/pending-material-ticket/${id}`);
    } else if (result.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${result.message}`,
      });
    }
  };

  return (
    <div className="grid h-full">
      {!isVisible && <IssueTypeHeader issueType="Проблем со материјали" />}
      <Formik
        initialValues={{
          operatorId: "",
          componentNumber: ticketState.componentNumber,
        }}
        onSubmit={(values) => {
          const ticket: CreateTicketRequest = {
            ticket_type: ticketState.ticketType,
            created_by_card_id: values.operatorId,
            reason_id: ticketState.reason.id_,
            component_id: values.componentNumber,
            priority: PriorityTypeEnum.EFFICIENCY_REDUCTION,
            line_id: user.id_,
          };

          onSubmit(ticket, values.operatorId);
        }}
        validationSchema={MaterialIssueSchema}
      >
        {(props) => (
          <form
            className="mt-10"
            onSubmit={(e) => e.preventDefault()}
            action="return false;"
          >
            <div className="flex justify-evenly">
              <div className="">
                <div className="w-80 rounded-md bg-aptiv-dark-slate-blue py-4 text-center text-2xl">
                  Оператор ИД
                </div>
                <TextInput
                  id={"operatorId"}
                  name={"operatorId"}
                  placeholder={"Оператор ИД"}
                  type={"text"}
                  onClick={handleKeyboardStatusChange}
                  setInputName={setInputName}
                />
              </div>
              <div className="relative">
                <div className="w-80 rounded-md bg-aptiv-dark-slate-blue py-4 text-center text-2xl">
                  Број на компонента
                </div>
                <TextInput
                  id={"componentNumber"}
                  name={"componentNumber"}
                  placeholder={"Број на компонента"}
                  type={"text"}
                  onClick={handleKeyboardStatusChange}
                  setInputName={setInputName}
                />
                <div className="">
                  <button
                    className="w-80 rounded-lg bg-aptiv-orange text-4xl text-white"
                    type="button"
                    onClick={() => navigate("/add-new-component")}
                  >
                    Додади нова компонента
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-3 flex justify-center">
              <TestButton
                className="rounded-lg bg-aptiv-sun px-24 py-6 text-4xl"
                type="button"
                onClick={() => {
                  props.handleSubmit();
                }}
              >
                Отвори тикет
              </TestButton>
            </div>
            {isVisible && (
              <Keyboard
                keyboardRef={(r) => (keyboard.current = r)}
                inputName={inputName}
                onChangeAll={(inputs: any) => {
                  props.setFieldValue("operatorId", inputs.operatorId);
                  props.setFieldValue(
                    "componentNumber",
                    inputs.componentNumber
                  );
                  onChangeAll(inputs);
                }}
                layout={layout}
                theme="hg-theme-default myTheme2"
                onKeyPress={onKeyPress}
                layoutName={layoutName}
              />
            )}
          </form>
        )}
      </Formik>
      {!isVisible && (
        <div className="mx-8 mb-4 mt-auto flex justify-between">
          <ButtonComponent
            onClick={() => navigate("/")}
            classes="bg-aptiv-dark-gray px-8  rounded-md pb-4 text-6xl"
            buttonName="Почетна"
          />

          <ButtonComponent
            onClick={() => navigate(-1)}
            classes="bg-aptiv-dark-gray px-8  rounded-md pb-4 text-6xl"
            buttonName="Назад"
          />
        </div>
      )}
      <Modal open={open} setOpen={setIsOpen} modalProps={modalProps} />
    </div>
  );
};
