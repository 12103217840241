import { AxiosResponse } from "axios";
import { instance } from "./axios";
import {
  Ticket,
  CreateTicketRequest,
  Page,
  AcceptTicketRequest,
  CloseTicketRequest,
  TicketOrderBy,
  UpdateTicket,
  StatusEnum,
  MachineProblemTypeEnum,
  ApprovePlannedAction,
  CSVResponse,
  OrderDirection,
} from "../types";

export interface ITicketApi {
  fetchAllTickets(
    page: number,
    page_size: number,
    order_by?: Array<TicketOrderBy>,
    status?: Array<StatusEnum>,
    line_id?: number
  ): Promise<AxiosResponse<Page<Ticket>>>;
  fetchAllByLineId(
    line_id: number,
    page: number,
    page_size: number,
    status?: Array<StatusEnum>,
    type?: Array<MachineProblemTypeEnum>,
    reason_id?: number,
    order_by?: Array<TicketOrderBy>,
    order?: OrderDirection,
    start_date?: string,
    end_date?: string
  ): Promise<AxiosResponse<Page<Ticket>>>;
  fetchById(ticket_id: number): Promise<AxiosResponse<Ticket>>;
  createTicket(ticket: CreateTicketRequest): Promise<AxiosResponse<Ticket>>;
  updateTicket(
    ticket_id: number,
    ticket: UpdateTicket
  ): Promise<AxiosResponse<Ticket>>;
  acceptTicket(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<AxiosResponse<Ticket>>;
  closeTicket(
    ticket_id: number,
    ticket: CloseTicketRequest
  ): Promise<AxiosResponse<Ticket>>;
  forwardTicket(
    ticket_id: number,
    from_card_id: string,
    to_card_id: string
  ): Promise<AxiosResponse<Ticket>>;
  forwardToOnCallEngineer(
    ticket_id: number,
    from_card_id: string
  ): Promise<AxiosResponse<Ticket>>;
  fetchAllByMachineId(
    machine_id: number,
    page_number: number,
    page_size: number,
    status: Array<StatusEnum>
  ): Promise<AxiosResponse<Page<Ticket>>>;
  fetchAllByUserId(
    user_id: number,
    page: number,
    page_size: number
  ): Promise<AxiosResponse<Page<Ticket>>>;
  prioritizeTicket(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<AxiosResponse<Ticket>>;
  pauseTicket(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<AxiosResponse<Ticket>>;
  approvePlannedAction(
    ticket_id: number,
    ticket: ApprovePlannedAction
  ): Promise<AxiosResponse<Ticket>>;
  materialNotAvailable(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<AxiosResponse<Ticket>>;
  rejectPlannedAction(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<AxiosResponse<Ticket>>;
  exportCSV(user_id: number): Promise<AxiosResponse<CSVResponse>>;
}

export const TicketApi: ITicketApi = {
  async fetchAllByUserId(
    user_id: number,
    page: number,
    page_size: number
  ): Promise<AxiosResponse<Page<Ticket>>> {
    const route = `/users/${user_id}/tickets`;
    return await instance.get<Page<Ticket>>(route, {
      params: { page, page_size },
    });
  },
  async fetchAllTickets(
    page: number,
    page_size: number,
    order_by?: Array<TicketOrderBy>,
    status?: Array<StatusEnum>,
    line_id?: number
  ): Promise<AxiosResponse<Page<Ticket>>> {
    const route = "tickets/";
    var params = new URLSearchParams();
    order_by?.forEach((o) => {
      params.append("order_by", o.toString());
    });
    status?.forEach((s) => {
      params.append("status", s.toString());
    });
    if (line_id) params.append("line_id", line_id.toString());
    params.append("page", page.toString());
    params.append("page_size", page_size.toString());
    return await instance.get<Page<Ticket>>(route, {
      params,
    });
  },
  async fetchAllByLineId(
    line_id: number,
    page: number,
    page_size: number,
    status?: Array<StatusEnum>,
    type?: Array<MachineProblemTypeEnum>,
    reason_id?: number,
    order_by?: Array<TicketOrderBy>,
    order?: OrderDirection,
    start_date?: string,
    end_date?: string
  ): Promise<AxiosResponse<Page<Ticket>>> {
    var params = new URLSearchParams();
    status?.forEach((s: StatusEnum) => {
      params.append("status", s.toString());
    });

    type?.forEach((t: MachineProblemTypeEnum) => {
      params.append("type", t.toString());
    });
    params.append("page", page.toString());
    params.append("page_size", page_size.toString());
    if (start_date) params.append("start_date", start_date);
    if (end_date) params.append("end_date", end_date);
    if (order_by) {
      order_by.forEach((o) => {
        params.append("sort_by", o.toString());
      });
    }
    if (order) {
      params.append("order", order.toString());
    }
    if (reason_id) {
      params.append("reason_id", reason_id.toString());
    }
    const route = `lines/${line_id}/tickets`;
    return await instance.get<Page<Ticket>>(route, {
      params,
    });
  },

  async fetchById(ticket_id: number): Promise<AxiosResponse<Ticket>> {
    const route = `tickets/${ticket_id}`;
    return await instance.get<Ticket>(route);
  },
  async createTicket(
    ticket: CreateTicketRequest
  ): Promise<AxiosResponse<Ticket>> {
    const route = "tickets/";
    return await instance.post<Ticket>(route, ticket);
  },
  async updateTicket(
    ticket_id: number,
    ticket: UpdateTicket
  ): Promise<AxiosResponse<Ticket>> {
    const route = `tickets/${ticket_id}`;
    return await instance.patch<Ticket>(route, ticket, {
      params: { card_id: ticket.card_id },
    });
  },
  async acceptTicket(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<AxiosResponse<Ticket>> {
    const route = `tickets/${ticket_id}/accept`;
    return await instance.post<Ticket>(route, ticket);
  },
  async closeTicket(
    ticket_id: number,
    ticket: CloseTicketRequest
  ): Promise<AxiosResponse<Ticket>> {
    const route = `tickets/${ticket_id}/close`;
    return await instance.post<Ticket>(route, ticket);
  },
  async forwardTicket(
    ticket_id: number,
    from_card_id: string,
    to_card_id: string
  ): Promise<AxiosResponse<Ticket>> {
    const route = `tickets/${ticket_id}/forward`;
    return await instance.post<Ticket>(route, { to_card_id, from_card_id });
  },
  async forwardToOnCallEngineer(
    ticket_id: number,
    from_card_id: string
  ): Promise<AxiosResponse<Ticket>> {
    const route = `tickets/${ticket_id}/forward_to_on_call_engineer`;
    return await instance.post<Ticket>(route, { from_card_id });
  },
  async fetchAllByMachineId(
    machine_id: number,
    page_number: number,
    page_size: number,
    status: Array<StatusEnum>
  ): Promise<AxiosResponse<Page<Ticket>>> {
    const route = `machines/${machine_id}/tickets`;
    var params = new URLSearchParams();

    status.forEach((s: StatusEnum) => {
      params.append("status", s.toString());
    });
    params.append("page_number", page_number.toString());
    params.append("page_size", page_size.toString());
    return await instance.get<Page<Ticket>>(route, {
      params,
    });
  },
  async prioritizeTicket(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<AxiosResponse<Ticket>> {
    const route = `tickets/${ticket_id}/prioritize`;
    return await instance.post<Ticket>(route, ticket);
  },
  async pauseTicket(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<AxiosResponse<Ticket>> {
    const route = `tickets/${ticket_id}/pause`;
    return await instance.post<Ticket>(route, ticket);
  },
  async materialNotAvailable(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<AxiosResponse<Ticket>> {
    const route = `tickets/${ticket_id}/material_not_available`;
    return await instance.post<Ticket>(route, ticket);
  },
  async approvePlannedAction(
    ticket_id: number,
    ticket: ApprovePlannedAction
  ): Promise<AxiosResponse<Ticket>> {
    const route = `tickets/${ticket_id}/approve_planned_action`;
    return await instance.post<Ticket>(route, ticket);
  },
  async rejectPlannedAction(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<AxiosResponse<Ticket>> {
    const route = `tickets/${ticket_id}/reject_planned_action`;
    return await instance.post<Ticket>(route, ticket);
  },
  async exportCSV(user_id: number): Promise<AxiosResponse<CSVResponse>> {
    const route = "/tickets/export";
    return await instance.get<CSVResponse>(route, {
      params: { user_id },
    });
  },
};
