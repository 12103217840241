import { ButtonComponent } from "../components/ButtonComponent/ButtonComponent";
import { IssueTypeHeader } from "../components/IssueTypeHeader/IssueTypeHeader";
import { useNavigate } from "react-router-dom";
import { MachineProblemTypeEnum } from "../types";
import { useTicket } from "../TicketContext";
import { useTranslation } from "react-i18next";
export const CreateTicket: React.FC = () => {
  const navigate = useNavigate();
  const { dispatch } = useTicket();
  const { t } = useTranslation();
  return (
    <div className="grid h-full">
      <IssueTypeHeader issueType={t("createTicket.reasonForStoppage")} />
      <div className="mx-4 my-auto grid grid-cols-2 justify-between">
        <ButtonComponent
          onClick={() => {
            navigate("/machine-interruption");
          }}
          classes="py-6 mr-6 rounded-md text-7xl"
          buttonName={t("createTicket.machineInterruption")}
        />
        <ButtonComponent
          onClick={() => {
            dispatch({
              type: "SET_TICKET_TYPE",
              payload: MachineProblemTypeEnum.MATERIALS_PROBLEM,
            });
            navigate("/material-issue");
          }}
          classes="py-6 rounded-md text-7xl"
          buttonName={t("createTicket.materialIssue")}
        />
      </div>
      <div className="mx-8 mb-4 mt-auto flex justify-between">
        <ButtonComponent
          onClick={() => navigate("/")}
          classes="px-8  rounded-md pb-4 text-6xl"
          buttonName={t("common.home")}
        />
        <ButtonComponent
          onClick={() => navigate(-1)}
          classes="px-8 rounded-md pb-4 text-6xl"
          buttonName={t("common.back")}
        />
      </div>
    </div>
  );
};
