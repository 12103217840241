import { ErrorMessage, Form, Formik } from "formik";
import { TextInput } from "../TextInput/TextInput";
import { TestButton } from "../TestButton/TestButton";
import { useRef } from "react";
import { useKeyboard } from "../../hooks/useKeyboard";
import * as Yup from "yup";
import moment from "moment";
import { useTicket } from "../../TicketContext";
import { KeyboardWrapper } from "../../components/Keyboard/KeyboardWrapper";
import { DatePickerField } from "../RequestAction/OpenRequest";

type ApproveActionProps = {
  onApprove: (
    card_id: string,
    datetime_from: string,
    datetime_to: string
  ) => void;
  onReject: (card_id: string) => void;
  isMoreThan30Minutes: boolean;
};

const ApproveActionSchemaLessThan30 = Yup.object().shape({
  id: Yup.string().required("Задолжително поле"),
});

const ApproveActionSchemaMoreThan30 = Yup.object().shape({
  id: Yup.string().required("Задолжително поле"),
  date: Yup.date()
    .nullable()
    .required("Задолжително поле")
    .min(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      "Акцијата не може да биде пред денешен ден"
    ),
  timeFrom: Yup.string().required("Задолжително поле"),
  timeTo: Yup.string().required("Задолжително поле"),
});

export const ApproveAction: React.FC<ApproveActionProps> = ({
  onApprove,
  onReject,
  isMoreThan30Minutes,
}) => {
  const keyboard = useRef(null);
  const [isVisible, toggleVisible] = useKeyboard(false);
  const { state, dispatch } = useTicket();
  return (
    <>
      {isMoreThan30Minutes ? (
        <Formik
          initialValues={{
            id: "",
            date: moment(state.date),
            start_hour: state.timeFrom.hour(),
            start_minute: state.timeFrom.minute(),
            end_hour: state.timeTo.hour(),
            end_minute: state.timeTo.minute(),
          }}
          onSubmit={(values, actions) => {}}
          validationSchema={ApproveActionSchemaMoreThan30}
        >
          {(props) => (
            <Form
              className="mt-5 w-full"
              onSubmit={(e) => e.preventDefault()}
              action="return false;"
            >
              <div className="grid justify-center">
                <div className="text-center text-white">Изберете датум</div>
                <DatePickerField
                  className="h-16 w-72 rounded-md border"
                  name="date"
                  autoComplete="off"
                  closeCalendar={true}
                  openCalendarOnFocus={false}
                  startDate={new Date()}
                />
                <div className="mb-5 mt-2 h-2 text-center">
                  <ErrorMessage name={"date"}>
                    {(errorMessage) => (
                      <span className="font-regular text-xl text-red-600">
                        {errorMessage}
                      </span>
                    )}
                  </ErrorMessage>
                </div>
              </div>
              <div className="mt-2 grid justify-center">
                <div className="mx-auto text-white">
                  Изберете време за почеток на акција
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                  <TextInput
                    type="text"
                    name="start_hour"
                    id="start_hour"
                    autoFocus={true}
                    placeholder="Почеток (час)"
                    fieldAttributes={{ maxLength: 2 }}
                    onClick={toggleVisible}
                  />
                  <TextInput
                    type="text"
                    name="start_minute"
                    id="start_minute"
                    autoFocus={true}
                    placeholder="Почеток (минути)"
                    fieldAttributes={{ maxLength: 2 }}
                    onClick={toggleVisible}
                  />
                </div>
              </div>

              <div className="mt-2 grid justify-center">
                <div className="mx-auto text-white">
                  Изберете време за завршена акција
                </div>
                <div className="grid grid-cols-2 gap-x-4">
                  <TextInput
                    type="text"
                    name="end_hour"
                    id="end_hour"
                    autoFocus={true}
                    placeholder="Крај (час)"
                    fieldAttributes={{ maxLength: 2 }}
                    onClick={toggleVisible}
                  />
                  <TextInput
                    type="text"
                    name="end_minute"
                    id="end_minute"
                    autoFocus={true}
                    placeholder="Крај (минути)"
                    fieldAttributes={{ maxLength: 2 }}
                    onClick={toggleVisible}
                  />
                </div>
              </div>
              <label
                htmlFor="name"
                className="block text-center font-medium text-white"
              >
                Одговорен на смена
              </label>

              <div className="flex justify-center">
                <TextInput
                  type="text"
                  name="id"
                  id="id"
                  autoFocus={true}
                  placeholder="id"
                  onClick={toggleVisible}
                ></TextInput>
              </div>
              <div className="grid grid-cols-2 ">
                <button
                  className={
                    "mt-3 ml-2 mb-2 rounded-md bg-aptiv-orange py-4 px-4 text-3xl "
                  }
                  type="button"
                  disabled={false}
                  onClick={() => {
                    var date = moment(props.values.date);
                    var dateTimeFrom = moment()
                      .hour(parseInt(props.values.start_hour))
                      .minute(parseInt(props.values.start_minute))
                      .date(date.date())
                      .month(date.month())
                      .year(date.year())
                      .second(0);
                    var dateTimeTo = moment()
                      .hour(parseInt(props.values.end_hour))
                      .minute(parseInt(props.values.end_minute))
                      .date(date.date())
                      .month(date.month())
                      .year(date.year())
                      .second(0);

                    dispatch({
                      type: "SET_DATE",
                      payload: props.values.date,
                    });
                    dispatch({
                      type: "SET_TIME_FROM",
                      payload: dateTimeFrom,
                    });
                    dispatch({
                      type: "SET_TIME_TO",
                      payload: dateTimeTo,
                    });

                    onApprove(
                      props.values.id,
                      moment(dateTimeFrom).format("YYYY-MM-DD[T]HH:mm:ss"),
                      moment(dateTimeTo).format("YYYY-MM-DD[T]HH:mm:ss")
                    );
                  }}
                >
                  Одобри барање
                </button>
                <button
                  disabled={false}
                  className={
                    "m-2 mt-3 mb-2 rounded-md bg-aptiv-orange py-4 px-4 text-3xl "
                  }
                  type="button"
                  onClick={() => {
                    onReject(props.values.id);
                  }}
                >
                  Одбиј барање
                </button>
              </div>
              {isVisible && (
                <KeyboardWrapper
                  keyboardRef={keyboard}
                  onChange={(input: string) => {
                    props.handleChange(input);
                    props.setFieldValue("id", input);
                  }}
                />
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          initialValues={{ id: "" }}
          onSubmit={(values, actions) => {}}
          validationSchema={ApproveActionSchemaLessThan30}
        >
          {(props) => (
            <Form
              className="mt-5 w-full"
              autoComplete="off"
              onSubmit={(e) => e.preventDefault()}
              action="return false;"
            >
              <label
                htmlFor="name"
                className="ml-px block pb-3 pl-4 text-center text-2xl font-medium text-white"
              >
                Одговорен на смена
              </label>

              <div className="mt-3 flex justify-center">
                <TextInput
                  type="text"
                  name="id"
                  id="id"
                  autoFocus={true}
                  placeholder="id"
                  onClick={toggleVisible}
                ></TextInput>
              </div>
              <div className="grid grid-cols-2 ">
                <TestButton
                  className={
                    "mt-3 ml-2 mb-2 rounded-md bg-aptiv-orange py-4 px-4 text-3xl "
                  }
                  type="button"
                  onClick={() =>
                    onApprove(
                      props.values.id,
                      moment(state.timeFrom).format("YYYY-MM-DD[T]HH:mm:ss"),
                      moment(state.timeTo).format("YYYY-MM-DD[T]HH:mm:ss")
                    )
                  }
                >
                  Одобри барање
                </TestButton>
                <TestButton
                  className={
                    "m-2 mt-3 mb-2 rounded-md bg-aptiv-orange py-4 px-4 text-3xl "
                  }
                  type="button"
                  onClick={() => onReject(props.values.id)}
                >
                  Одбиј барање
                </TestButton>
              </div>
              <div className="">
                {isVisible && (
                  <KeyboardWrapper
                    keyboardRef={keyboard}
                    onChange={(input: string) => {
                      props.handleChange(input);
                      props.setFieldValue("id", input);
                    }}
                  />
                )}
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
