import { createContext, useContext, useState } from "react";

type Language = "en" | "mk";

export interface LanguageContextState {
  language: Language;
  setLanguage: (language: Language) => void;
}
type LanguageContextHook = () => {
  language: Language;
  setLanguage: (language: Language) => void;
};
export const LanguageContext = createContext<LanguageContextState>({
  language: "mk",
  setLanguage: (language: Language) => {
    throw new Error("");
  },
});

interface LanguageContextPropTypes {
  children?: React.ReactNode;
}

export const LanguageContextProvider: React.FC<LanguageContextPropTypes> = ({
  children,
}) => {
  const [language, setLanguage] = useState<Language>("mk");

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage: LanguageContextHook = () => {
  const { language, setLanguage } = useContext(LanguageContext);
  return { language, setLanguage };
};
