import { useSessionStorage } from "../../hooks/useSessionStorage";

type Props = {
  activateLine: () => void;
};

export const InactiveLine: React.FC<Props> = ({ activateLine }) => {
  const [user] = useSessionStorage("user");

  return (
    <div className="grid h-full bg-blue-500">
      <div className="pt-3 text-center text-7xl font-bold my-auto">
        Линијата {user.name} е неактивна
      </div>
      <div className="mx-auto animate-pulse mt-auto pb-12">
        <button
          className={
            " rounded-md bg-aptiv-orange px-36 py-14 text-center text-6xl font-normal text-white"
          }
          onClick={() => {
            activateLine();
          }}
        >
          Активирај
        </button>
      </div>
    </div>
  );
};
