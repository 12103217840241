import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";
import React from "react";

type PaginationPropType = {
  current: number;
  last: number;
  onNext: () => any;
  onPrev: () => any;
  onSelect: (page: number) => any;
};

export const Pagination: React.FC<PaginationPropType> = ({
  current,
  last,
  onNext,
  onPrev,
  onSelect,
}) => {
  return (
    <nav className="flex items-center justify-between mx-8 px-4 border-gray-200 sm:px-0">
      <div className="flex flex-1 -mt-px w-0">
        <button
          onClick={onPrev}
          className="inline-flex items-center pb-4 pr-1 text-white text-xl font-medium border-transparent"
        >
          <ArrowNarrowLeftIcon
            className="mr-3 w-5 h-5 text-gray-400"
            aria-hidden="true"
          />
          Претходно
        </button>
      </div>
      <div className="flex flex-1 justify-end -mt-px w-0">
        <button
          onClick={onNext}
          className="inline-flex items-center pb-4 pl-1 text-white text-xl font-medium border-transparent"
        >
          Следно
          <ArrowNarrowRightIcon
            className="ml-3 w-5 h-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  );
};
