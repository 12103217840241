import {
  CloseTicketRequest,
  CreateTicketRequest,
  StatusEnum,
} from "../../types";
import * as Yup from "yup";
import { useTicket } from "../../TicketContext";
import { useRef } from "react";
import { useKeyboard } from "../../hooks/useKeyboard";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { Form, Formik } from "formik";
import { TextInput } from "../TextInput/TextInput";
import { TestButton } from "../TestButton/TestButton";
import { KeyboardWrapper } from "../Keyboard/KeyboardWrapper";
interface Props {
  onSubmit: (card_id: string, ticket: CreateTicketRequest) => void;
  onClose: (
    card_id: string,
    isTechnician: boolean,
    ticket_id: number,
    ticket: CloseTicketRequest
  ) => void;
}

export const MaintenanceProblem: React.FC<Props> = ({ onSubmit, onClose }) => {
  const MaintenanceProblemSchema = Yup.object().shape({
    id: Yup.string().required("Задолжително поле"),
  });
  const ticketContext = useTicket();
  const ticketState = ticketContext.state;

  const keyboard = useRef(null);
  const [isVisible, toggleVisible] = useKeyboard(false);

  const [user] = useSessionStorage("user");
  return (
    <Formik
      initialValues={{ id: "" }}
      onSubmit={(values, actions) => {
        const ticket: CreateTicketRequest = {
          priority: ticketState.priority,
          ticket_type: ticketState.ticketType,
          created_by_card_id: values.id,
          line_id: user.id_,
          status: StatusEnum.OPEN,
        };
        if (
          !ticketState.preventiveMaintenanceButtons[0].isClicked &&
          ticketState.preventiveMaintenanceButtons[1].disabled
        ) {
          onSubmit(values.id, ticket);
        } else if (ticketState.preventiveMaintenanceButtons[0].isClicked) {
          onClose(values.id, false, ticketContext.state.ticket_id, {
            card_id: values.id,
          });
        }
        actions.resetForm();
      }}
      validationSchema={MaintenanceProblemSchema}
    >
      {(props) => (
        <Form
          className="mt-5 w-full"
          autoComplete="off"
          onSubmit={(e) => e.preventDefault()}
          action="return false;"
        >
          <label
            htmlFor="name"
            className="ml-px mt-2 block pb-3 pl-4 text-center text-2xl font-medium text-white"
          >
            Одговорен техничар
          </label>
          <div className="mt-3 flex justify-center">
            <TextInput
              type="text"
              name="id"
              id="id"
              autoFocus={true}
              placeholder="id"
              onClick={toggleVisible}
            ></TextInput>
          </div>
          <div className="mx-2 mt-3 grid grid-cols-2 gap-x-2">
            <TestButton
              className={
                "mt-3 mb-2 rounded-md py-4 px-4 text-3xl " +
                ticketState.preventiveMaintenanceButtons[0].color
              }
              type="button"
              disabled={ticketState.preventiveMaintenanceButtons[0].disabled}
              onClick={() => {
                props.handleSubmit();
              }}
            >
              Започни превентивно одржување
            </TestButton>
            <TestButton
              className={
                "mt-3 mb-2 rounded-md py-4 px-4 text-3xl " +
                ticketState.preventiveMaintenanceButtons[1].color
              }
              type="button"
              disabled={ticketState.preventiveMaintenanceButtons[1].disabled}
              onClick={() => {
                props.handleSubmit();
              }}
            >
              Заврши превентивно одржување
            </TestButton>
          </div>
          {isVisible && (
            <div className="absolute right-0 bottom-16 w-2/3">
              <KeyboardWrapper
                keyboardRef={keyboard}
                onChange={(input: string) => {
                  props.handleChange(input);
                  props.setFieldValue("id", input);
                }}
              />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};
