import { AxiosResponse } from "axios";
import { ReasonApi } from "../api/reason";
import { CreateReasonRequest, Page, Reason, Result } from "../types";
import { Handler } from "./result";

export interface IReasonService {
  fetchAll(page: number, page_size: number): Promise<Result<Page<Reason>>>;
  fetchById(reason_id: number): Promise<Result<Reason>>;
  createReason(reason: CreateReasonRequest): Promise<Result<Reason>>;
  delete(reason_id: number): Promise<Result<string>>;
}

export const ReasonService: IReasonService = {
  async fetchAll(
    page: number,
    page_size: number
  ): Promise<Result<Page<Reason>>> {
    return await ReasonApi.fetchAll(page, page_size)
      .then((resp: AxiosResponse<Page<Reason>>) =>
        Handler.SuccessResult(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async fetchById(reason_id: number): Promise<Result<Reason>> {
    return await ReasonApi.fetchById(reason_id)
      .then((resp: AxiosResponse<Reason>) => Handler.SuccessResult(resp.data))
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async createReason(reason: CreateReasonRequest): Promise<Result<Reason>> {
    return await ReasonApi.createReason(reason)
      .then((resp: AxiosResponse<Reason>) => Handler.SuccessResult(resp.data))
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async delete(reason_id: number): Promise<Result<string>> {
    return await ReasonApi.delete(reason_id)
      .then((resp: AxiosResponse<string>) => Handler.SuccessResult(resp.data))
      .catch((reason) => Handler.ErrorResult(reason));
  },
};
