import React, { useRef } from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../TextInput/TextInput";
import { TestButton } from "../TestButton/TestButton";
import { useTicket } from "../../TicketContext";
import { useNavigate } from "react-router-dom";
import { KeyboardWrapper } from "../Keyboard/KeyboardWrapper";
import { useKeyboard } from "../../hooks/useKeyboard";
import {
  AcceptTicketRequest,
  CloseTicketRequest,
  StatusEnum,
} from "../../types";
type MaintenanceTechnicianProp = {
  onAccept: (
    card_id: string,
    isTechnician: boolean,
    ticket_id: number,
    ticket: AcceptTicketRequest
  ) => void;
  onClose: (
    card_id: string,
    isTechnician: boolean,
    ticket_id: number,
    tikcet: CloseTicketRequest
  ) => void;
  onPause: (ticket_id: number, card_id: string) => void;
  onPrioritize: (ticket_id: number, card_id: string) => void;
  setOpenSelectModal : (input : boolean) => void;
  setForwardFrom : (input : string) => void;
};
const MaintenanceTechnicianSchema = Yup.object().shape({
  id: Yup.string().required("Задолжително поле"),
});

export const MaintenanceTechnician: React.FC<MaintenanceTechnicianProp> = ({
  onAccept,
  onPause,
  onPrioritize,
  setOpenSelectModal,
  setForwardFrom
}) => {
  const navigate = useNavigate();
  const ticketContext = useTicket();
  const dispatchTicket = ticketContext.dispatch;
  const state = ticketContext.state;
  const keyboard = useRef(null);
  const [isVisible, toggleVisible] = useKeyboard(false);
  
  return (
    <>
      <Formik
        initialValues={{ id: "" }}
        onSubmit={(values, actions) => {
          dispatchTicket({
            type: "SET_MAINTENANCE_TECHNICIAN",
            payload: values.id,
          });
          const acceptTicket: AcceptTicketRequest = {
            card_id: values.id,
          };
          if (
            !state.maintenanceTehnicianButtons[0].disabled &&
            state.maintenanceTehnicianButtons[2].disabled
          ) {
            onAccept(
              values.id,
              true,
              ticketContext.state.ticket_id,
              acceptTicket
            );
            actions.resetForm();
            actions.validateForm();
          }

          if (state.maintenanceTehnicianButtons[0].isClicked) {
            setForwardFrom(values.id);
            setOpenSelectModal(true);
            actions.resetForm();
            actions.validateForm();
           
          }
        }}
        validationSchema={MaintenanceTechnicianSchema}
      >
        {(props) => (
          <form
            className="mt-5 w-full"
            onSubmit={(e) => e.preventDefault()}
            action="return false;"
          >
            <label
              htmlFor="name"
              className="mb-3 ml-px block pl-4 text-center text-2xl font-medium text-white"
            >
              Техничар
            </label>
            <div className="mt-3 flex justify-center">
              <TextInput
                type="text"
                name="id"
                id="id"
                autoFocus={true}
                placeholder="id"
                onClick={toggleVisible}
              ></TextInput>
            </div>
            <div className="mx-1 mt-3 grid grid-cols-3">
              <TestButton
                type="button"
                className={
                  "mr-2  ml-2 mb-4  rounded-md py-3 text-3xl " +
                  state.maintenanceTehnicianButtons[0].color
                }
                disabled={state.maintenanceTehnicianButtons[0].disabled}
                onClick={() => {
                  props.handleSubmit();
                }}
              >
                Прими тикет
              </TestButton>
              <TestButton
                type="button"
                className={
                  "mr-2   mb-4 rounded-md py-3 text-3xl " +
                  state.maintenanceTehnicianButtons[1].color
                }
                disabled={state.maintenanceTehnicianButtons[1].disabled}
                onClick={() => {
                  dispatchTicket({
                    type: "SET_CLOSED_BY",
                    payload: props.values.id,
                  });
                  navigate("/ticket-problem-description");
                }}
              >
                Затвори тикет
              </TestButton>
              <TestButton
                type="button"
                className={
                  "mr-2   mb-4 rounded-md py-3 text-3xl " +
                  state.maintenanceTehnicianButtons[2].color
                }
                disabled={state.maintenanceTehnicianButtons[2].disabled}
                onClick={() => {
                  props.handleSubmit();
                  // navigate("/ticket-problem-description")
                }}
              >
                Препрати тикет
              </TestButton>
            </div>
            {state.status !== StatusEnum.ON_HOLD && (
              <div className="mt-3 flex justify-between space-x-2">
                <button
                  className="my-auto flex w-1/2 justify-center rounded-md bg-aptiv-orange py-8 align-middle text-xl font-bold text-white"
                  type="button"
                  onClick={() => onPause(state.ticket_id, props.values.id)}
                >
                  Паузирај
                </button>
                {state.status !== StatusEnum.PRIORITY && (
                  <button
                    type="button"
                    className="my-auto flex w-1/2 justify-center rounded-md bg-aptiv-orange py-8 align-middle text-xl font-bold text-white"
                    onClick={() =>
                      onPrioritize(state.ticket_id, props.values.id)
                    }
                  >
                    Приоритет
                  </button>
                )}
              </div>
            )}
            {isVisible && (
              <KeyboardWrapper
                keyboardRef={keyboard}
                onChange={(input: string) => {
                  props.handleChange(input);
                  props.setFieldValue("id", input);
                }}
              />
            )}
          </form>
        )}
      </Formik>
    </>
  );
};
