import { User } from "../../types";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { onCallEngineer } from "../../const";

type Props = {
  open: boolean;
  setOpen: (input: boolean) => void;
  users: Array<User>;
  handleForward: (toCardId: string) => void;
  handleForwardToOnCallEngineer: () => void;
};

export const SelectUsers: React.FC<Props> = ({
  open,
  setOpen,
  users,
  handleForward,
  handleForwardToOnCallEngineer,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10 bg-black" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative transform overflow-hidden rounded-lg bg-black px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-white"
                    >
                      Одберете корисник за препраќање
                    </Dialog.Title>
                    <div className="mt-4 grid grid-cols-2 gap-x-2 gap-y-2">
                      {users.concat([onCallEngineer]).map((u) => {
                        return (
                          <div
                            className="cursor-pointer border-2 border-gray-100 py-3 text-lg font-bold text-gray-50 hover:bg-gray-700"
                            onClick={() => {
                              //window.location.reload();
                              //setOpen(false);
                              if (u.id_ === -1) {
                                handleForwardToOnCallEngineer();
                              } else {
                                handleForward(u.card_id);
                              }
                              //TODO dispatch the user
                            }}
                          >
                            <div>
                              {u.first_name} {u.last_name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Излез
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
