import { AxiosResponse } from "axios";
import { ProductApi } from "../api/products";
import { Page, Product, Result } from "../types";
import { Handler } from "./result";

export interface IProductService {
  fetchAll(
    line_id: number,
    page: number,
    page_size: number
  ): Promise<Result<Page<Product>>>;
  fetchById(product_id: number): Promise<Result<Product>>;
}

export const ProductService: IProductService = {
  async fetchAll(
    line_id: number,
    page: number,
    page_size: number
  ): Promise<Result<Page<Product>>> {
    return await ProductApi.fetchAll(line_id, page, page_size)
      .then((resp: AxiosResponse<Page<Product>>) =>
        Handler.SuccessResult<Page<Product>>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async fetchById(product_id: number): Promise<Result<Product>> {
    return await ProductApi.fetchById(product_id)
      .then((resp: AxiosResponse<Product>) =>
        Handler.SuccessResult<Product>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
};
