import { TicketApi } from "../api/tickets";
import {
  Ticket,
  Result,
  CreateTicketRequest,
  Page,
  AcceptTicketRequest,
  CloseTicketRequest,
  TicketOrderBy,
  UpdateTicket,
  MachineProblemTypeEnum,
  StatusEnum,
  ApprovePlannedAction,
  CSVResponse,
  OrderDirection,
} from "../types";
import { AxiosResponse } from "axios";
import { Handler } from "./result";

export interface ITicketService {
  fetchAllTickets(
    page: number,
    page_size: number,
    order_by?: Array<TicketOrderBy>,
    status?: Array<StatusEnum>,
    line_id?: number
  ): Promise<Result<Page<Ticket>>>;
  fetchAllByLineId(
    line_id: number,
    page: number,
    page_size: number,
    status?: Array<StatusEnum>,
    type?: Array<MachineProblemTypeEnum>,
    reason_id?: number,
    order_by?: Array<TicketOrderBy>,
    order?: OrderDirection,
    start_date?: string,
    end_date?: string
  ): Promise<Result<Page<Ticket>>>;
  fetchById(ticket_id: number): Promise<Result<Ticket>>;
  createTicket(ticket: CreateTicketRequest): Promise<Result<Ticket>>;
  updateTicket(
    ticket_id: number,
    ticket: UpdateTicket
  ): Promise<Result<Ticket>>;
  acceptTicket(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<Result<Ticket>>;
  closeTicket(
    ticket_id: number,
    tikcet: CloseTicketRequest
  ): Promise<Result<Ticket>>;
  forwardTicket(
    ticket_id: number,
    from_card_id: string,
    to_card_id: string
  ): Promise<Result<Ticket>>;
  forwardToOnCallEngineer(
    ticket_id: number,
    from_card_id: string
  ): Promise<Result<Ticket>>;
  fetchAllByMachineId(
    machine_id: number,
    page_number: number,
    page_size: number,
    status: Array<StatusEnum>
  ): Promise<Result<Page<Ticket>>>;
  prioritizeTicket(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<Result<Ticket>>;
  pauseTicket(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<Result<Ticket>>;
  approvePlannedAction(
    ticket_id: number,
    ticket: ApprovePlannedAction
  ): Promise<Result<Ticket>>;
  materialNotAvailable(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<Result<Ticket>>;
  rejectPlannedAction(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<Result<Ticket>>;
  fetchAllByUserId(
    user_id: number,
    page: number,
    page_size: number
  ): Promise<Result<Page<Ticket>>>;
  exportCSV(user_id: number): Promise<Result<CSVResponse>>;
}

export const TicketService: ITicketService = {
  async fetchAllByUserId(
    user_id: number,
    page: number,
    page_size: number
  ): Promise<Result<Page<Ticket>>> {
    return TicketApi.fetchAllByUserId(user_id, page, page_size)
      .then((resp: AxiosResponse<Page<Ticket>>) =>
        Handler.SuccessResult<Page<Ticket>>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async fetchAllTickets(
    page: number,
    page_size: number,
    order_by?: Array<TicketOrderBy>,
    status?: Array<StatusEnum>,
    line_id?: number
  ): Promise<Result<Page<Ticket>>> {
    return TicketApi.fetchAllTickets(page, page_size, order_by, status, line_id)
      .then((resp: AxiosResponse<Page<Ticket>>) =>
        Handler.SuccessResult<Page<Ticket>>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },

  async fetchAllByLineId(
    line_id: number,
    page: number,
    page_size: number,
    status?: Array<StatusEnum>,
    type?: Array<MachineProblemTypeEnum>,
    reason_id?: number,
    order_by?: Array<TicketOrderBy>,
    order?: OrderDirection,
    start_date?: string,
    end_date?: string
  ): Promise<Result<Page<Ticket>>> {
    if (!type) {
      return await TicketApi.fetchAllByLineId(
        line_id,
        page,
        page_size,
        status,
        [],
        reason_id,
        order_by,
        order,
        start_date,
        end_date
      )
        .then((resp: AxiosResponse<Page<Ticket>>) =>
          Handler.SuccessResult<Page<Ticket>>(resp.data)
        )
        .catch((reason) => Handler.ErrorResult(reason));
    }
    return await TicketApi.fetchAllByLineId(
      line_id,
      page,
      page_size,
      status,
      type,
      reason_id,
      order_by,
      order,
      start_date,
      end_date
    )
      .then((resp: AxiosResponse<Page<Ticket>>) =>
        Handler.SuccessResult<Page<Ticket>>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async fetchById(ticket_id: number): Promise<Result<Ticket>> {
    return await TicketApi.fetchById(ticket_id)
      .then((resp: AxiosResponse<Ticket>) =>
        Handler.SuccessResult<Ticket>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async createTicket(ticket: CreateTicketRequest): Promise<Result<Ticket>> {
    return await TicketApi.createTicket(ticket)
      .then((resp: AxiosResponse<Ticket>) =>
        Handler.SuccessResult<Ticket>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async updateTicket(
    ticket_id: number,
    ticket: UpdateTicket
  ): Promise<Result<Ticket>> {
    return await TicketApi.updateTicket(ticket_id, ticket)
      .then((resp: AxiosResponse<Ticket>) =>
        Handler.SuccessResult<Ticket>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async acceptTicket(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<Result<Ticket>> {
    return await TicketApi.acceptTicket(ticket_id, ticket)
      .then((resp: AxiosResponse<Ticket>) =>
        Handler.SuccessResult<Ticket>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async closeTicket(
    ticket_id: number,
    ticket: CloseTicketRequest
  ): Promise<Result<Ticket>> {
    return await TicketApi.closeTicket(ticket_id, ticket)
      .then((resp: AxiosResponse<Ticket>) =>
        Handler.SuccessResult<Ticket>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async forwardTicket(
    ticket_id: number,
    from_card_id: string,
    to_card_id: string
  ): Promise<Result<Ticket>> {
    return await TicketApi.forwardTicket(ticket_id, from_card_id, to_card_id)
      .then((resp: AxiosResponse<Ticket>) =>
        Handler.SuccessResult<Ticket>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async forwardToOnCallEngineer(
    ticket_id: number,
    from_card_id: string
  ): Promise<Result<Ticket>> {
    return await TicketApi.forwardToOnCallEngineer(ticket_id, from_card_id)
      .then((resp: AxiosResponse<Ticket>) =>
        Handler.SuccessResult<Ticket>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async fetchAllByMachineId(
    machine_id: number,
    page_number: number,
    page_size: number,
    status: Array<StatusEnum>
  ): Promise<Result<Page<Ticket>>> {
    return await TicketApi.fetchAllByMachineId(
      machine_id,
      page_number,
      page_size,
      status
    )
      .then((resp: AxiosResponse<Page<Ticket>>) =>
        Handler.SuccessResult<Page<Ticket>>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async prioritizeTicket(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<Result<Ticket>> {
    return await TicketApi.prioritizeTicket(ticket_id, ticket)
      .then((resp: AxiosResponse<Ticket>) =>
        Handler.SuccessResult<Ticket>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async pauseTicket(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<Result<Ticket>> {
    return await TicketApi.pauseTicket(ticket_id, ticket)
      .then((resp: AxiosResponse<Ticket>) =>
        Handler.SuccessResult<Ticket>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async approvePlannedAction(
    ticket_id: number,
    ticket: ApprovePlannedAction
  ): Promise<Result<Ticket>> {
    return await TicketApi.approvePlannedAction(ticket_id, ticket)
      .then((resp: AxiosResponse<Ticket>) =>
        Handler.SuccessResult<Ticket>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async materialNotAvailable(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<Result<Ticket>> {
    return await TicketApi.materialNotAvailable(ticket_id, ticket)
      .then((resp: AxiosResponse<Ticket>) =>
        Handler.SuccessResult<Ticket>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async rejectPlannedAction(
    ticket_id: number,
    ticket: AcceptTicketRequest
  ): Promise<Result<Ticket>> {
    return await TicketApi.rejectPlannedAction(ticket_id, ticket)
      .then((resp: AxiosResponse<Ticket>) =>
        Handler.SuccessResult<Ticket>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async exportCSV(user_id: number): Promise<Result<CSVResponse>> {
    return await TicketApi.exportCSV(user_id)
      .then((resp) => Handler.SuccessResult<CSVResponse>(resp.data))
      .catch((reason) => Handler.ErrorResult(reason));
  },
};
