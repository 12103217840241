import * as Yup from "yup";
import { Form, Formik } from "formik";
import { TextInput } from "../components/TextInput/TextInput";
import { KeyboardWrapper } from "../components/Keyboard/KeyboardWrapper";
import { useKeyboard } from "../hooks/useKeyboard";
import { TestButton } from "../components/TestButton/TestButton";
import { TableDetail } from "../components/Table/TableDetail";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { TicketService } from "../service/tickets";
import {
  AcceptTicketRequest,
  ModalType,
  Result,
  Status,
  Ticket,
  User,
} from "../types";
import { useTicket } from "../TicketContext";
import { AccountService } from "../service/accounts";
import { Modal } from "../components/Modal/Modal";
import { StatusEnum } from "../types";

const PendingMaterialTicketSchema = Yup.object().shape({
  id: Yup.string().required("Задолжително поле"),
});

export const PendingMaterialTicket: React.FC = () => {
  const keyboard = useRef(null);
  const [isVisible, toggleVisible] = useKeyboard(false);
  const { dispatch, state } = useTicket();
  const { id } = useParams<"id">();
  const [open, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);
  const [isAccepted, setIsAccepted] = useState(false);
  const navigate = useNavigate();
  const changeTicketContext = useCallback(
    (ticket: Ticket) => {
      if (ticket.handled_by.length === 1) {
        setIsAccepted(false);
      } else {
        setIsAccepted(true);
      }
      dispatch({
        type: "UPDATE_STATE",
        payload: {
          ticketType: ticket.ticket_type,
          machines: ticket.machines,
          priority: ticket.priority,
          reason: ticket.reason,
          operatorId: ticket.created_by,
          componentNumber: ticket.component_id,
          openedAt: ticket.created_at,
          product: ticket.product,
          status: ticket.status,
          ticket_id: ticket.id_,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    TicketService.fetchById(parseInt(id!)).then((resp: Result<Ticket>) => {
      if (resp.status === Status.SUCCESS) {
        changeTicketContext(resp.value);
      }
    });
  }, [id, changeTicketContext]);

  const handleMaterialNotAvailable = async (card_id: string) => {
    const user: Result<User> = await AccountService.fetchByCardId(card_id);
    if (user.status === Status.ERROR) {
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }
    TicketService.materialNotAvailable(parseInt(id!), { card_id }).then(
      (resp: Result<Ticket>) => {
        if (resp.status === Status.SUCCESS) {
          window.location.reload();
        } else if (resp.status === Status.ERROR) {
          setModalProps({
            title: "Настана грешка",
            subtitle: `${resp.message}`,
          });
          setIsOpen(true);
        }
      }
    );
  };

  const handleClose = async (card_id: string) => {
    const user: Result<User> = await AccountService.fetchByCardId(card_id);
    if (user.status === Status.ERROR) {
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }
    TicketService.closeTicket(parseInt(id!), { card_id }).then(
      (resp: Result<Ticket>) => {
        if (resp.status === Status.SUCCESS) {
          navigate(`/tickets/${resp.value.id_}`);
        } else if (resp.status === Status.ERROR) {
          setIsOpen(true);
          setModalProps({
            title: "Настана грешка",
            subtitle: `${resp.message}`,
          });
        }
      }
    );
  };

  const handleAcceptTicket = async (card_id: string) => {
    const user: Result<User> = await AccountService.fetchByCardId(card_id);
    if (user.status === Status.ERROR) {
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }
    const acceptTicket: AcceptTicketRequest = {
      card_id,
    };
    const result: Result<Ticket> = await TicketService.acceptTicket(
      parseInt(id!),
      acceptTicket
    );
    if (result.status === Status.SUCCESS) {
      window.location.reload();
    } else if (result.status === Status.ERROR) {
      setModalProps({
        title: "Настана грешка",
        subtitle: `${result.message}`,
      });
    }
  };

  return (
    <div>
      <div className="mt-4 flex">
        <div className="ml-4 mt-2 h-full w-1/3 rounded-lg border">
          {isAccepted ? (
            <Formik
              initialValues={{ id: "" }}
              onSubmit={(values, actions) => {}}
              validationSchema={PendingMaterialTicketSchema}
            >
              {(props) => (
                <Form
                  className="mt-5 w-full"
                  autoComplete="off"
                  onSubmit={(e) => e.preventDefault()}
                  action="return false;"
                >
                  <label
                    htmlFor="name"
                    className="ml-px block pb-3 pl-4 text-center text-2xl font-medium text-white"
                  >
                    Магационер ID
                  </label>

                  <div className="mt-3 flex justify-center">
                    <TextInput
                      type="text"
                      name="id"
                      id="id"
                      autoFocus={true}
                      placeholder="id"
                      onClick={toggleVisible}
                    ></TextInput>
                  </div>
                  <div className="mx-1 mt-3 grid grid-cols-2">
                    <TestButton
                      className={`mt-3 mr-2 mb-2 rounded-md py-4 px-4 text-3xl ${
                        state.status === StatusEnum.MATERIAL_NOT_AVAILABLE
                          ? "bg-aptiv-dark-gray"
                          : "bg-aptiv-orange"
                      }`}
                      type="button"
                      onClick={() =>
                        handleMaterialNotAvailable(props.values.id)
                      }
                      disabled={
                        state.status === StatusEnum.MATERIAL_NOT_AVAILABLE
                      }
                    >
                      Компонентата не е достапна
                    </TestButton>
                    <TestButton
                      className={
                        "mt-3 mr-2 mb-2 rounded-md bg-aptiv-orange py-4 px-4 text-3xl "
                      }
                      type="button"
                      onClick={() => handleClose(props.values.id)}
                    >
                      Затвори
                    </TestButton>
                  </div>
                  <div className="">
                    {isVisible && (
                      <KeyboardWrapper
                        keyboardRef={keyboard}
                        onChange={(input: string) => {
                          props.handleChange(input);
                          props.setFieldValue("id", input);
                        }}
                      />
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={{ id: "" }}
              onSubmit={(values, actions) => {}}
              validationSchema={PendingMaterialTicketSchema}
            >
              {(props) => (
                <Form
                  className="mt-5 w-full"
                  autoComplete="off"
                  onSubmit={(e) => e.preventDefault()}
                  action="return false;"
                >
                  <label
                    htmlFor="name"
                    className="ml-px block pb-3 pl-4 text-center text-2xl font-medium text-white"
                  >
                    Магационер ID
                  </label>

                  <div className="mt-3 flex justify-center">
                    <TextInput
                      type="text"
                      name="id"
                      id="id"
                      autoFocus={true}
                      placeholder="id"
                      onClick={toggleVisible}
                    ></TextInput>
                  </div>
                  <div className="mx-auto flex justify-center">
                    <TestButton
                      className={
                        "mt-3 mr-2 mb-2 rounded-md bg-aptiv-orange py-4 px-4 text-3xl "
                      }
                      type="button"
                      onClick={() => handleAcceptTicket(props.values.id)}
                    >
                      Прими тикет
                    </TestButton>
                  </div>
                  <div className="">
                    {isVisible && (
                      <KeyboardWrapper
                        keyboardRef={keyboard}
                        onChange={(input: string) => {
                          props.handleChange(input);
                          props.setFieldValue("id", input);
                        }}
                      />
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
        <div className="ml-4 w-2/3">
          <TableDetail title={"Информации за акција"} />
        </div>
      </div>
      <Modal open={open} setOpen={setIsOpen} modalProps={modalProps} />
    </div>
  );
};
