import { AxiosResponse } from "axios";
import { instance } from "./axios";
import { Product, Page } from "../types";
export interface IProductApi {
  fetchAll(
    line_id: number,
    page: number,
    page_size: number
  ): Promise<AxiosResponse<Page<Product>>>;
  fetchById(product_id: number): Promise<AxiosResponse<Product>>;
}

export const ProductApi: IProductApi = {
  async fetchAll(
    line_id: number,
    page_number: number,
    page_size: number
  ): Promise<AxiosResponse<Page<Product>>> {
    const route = `lines/${line_id}/products`;
    return await instance.get<Page<Product>>(route, {
      params: { page:page_number, page_size },
    });
  },
  async fetchById(product_id: number): Promise<AxiosResponse<Product>> {
    const route = `products/${product_id}`;
    return await instance.get<Product>(route);
  },
};
