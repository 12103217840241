import { ButtonComponent } from "../components/ButtonComponent/ButtonComponent";
import { IssueTypeHeader } from "../components/IssueTypeHeader/IssueTypeHeader";
import { useNavigate } from "react-router-dom";
import { useTicket } from "../TicketContext";
import { useEffect, useState } from "react";
import { ModalType, Page, Reason, Result, Status } from "../types";
import { ReasonService } from "../service/reason";
import { Loader } from "../components/Loader/Loader";
import { Modal } from "../components/Modal/Modal";
export const MaterialIssue: React.FC = () => {
  const navigate = useNavigate();
  const { dispatch } = useTicket();
  const [reasons, setReasons] = useState<Array<Reason>>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [open, setIsOpen] = useState(false);

  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);
  useEffect(() => {
    setIsLoading(true);
    ReasonService.fetchAll(0, 100).then((resp: Result<Page<Reason>>) => {
      if (resp.status === Status.SUCCESS) {
        setReasons(resp.value.content);
      } else if (resp.status === Status.ERROR) {
        setIsOpen(true);
        setModalProps({
          title: "Настана грешка",
          subtitle: `${resp.message}`,
        });
      }
      setIsLoading(false);
    });
    setIsLoading(false);
  }, []);
  return (
    <>
      <div className="grid h-full">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <IssueTypeHeader issueType="Проблем со материјали" />
            <div className="mr-4 flex justify-end">
              <div className="my-auto text-xl font-bold text-red-700">
                Повлечи за повеќе причини
              </div>
              <img src="/pointer.svg" alt="pointer" className="align-center" />
            </div>
            <div className="no-scrollbar grid grid-flow-col grid-rows-2 gap-5 overflow-x-auto">
              {reasons.map((reason: Reason) => {
                return (
                  <button
                    onClick={() => {
                      dispatch({
                        type: "SET_REASON",
                        payload: reason,
                      });
                      navigate("/material-issue/new");
                    }}
                    className="h-36 w-[32rem] rounded-md bg-aptiv-orange text-center text-4xl font-bold text-white"
                  >
                    {reason.reason}
                  </button>
                );
              })}
            </div>
            <div className="mx-8 mb-4 mt-auto flex justify-between">
              <ButtonComponent
                onClick={() => navigate("/")}
                classes="px-8  rounded-md pb-4 text-6xl"
                buttonName="Почетна"
              />
              {/* <button
          onClick={() => navigate("/add-new-reason")}
          className="flex pb-4 px-8 text-center text-white text-6xl font-normal bg-aptiv-orange rounded-md"
        >
          <div className="">Додади проблем</div>
        </button> */}
              <ButtonComponent
                onClick={() => navigate(-1)}
                classes="px-8  rounded-md pb-4 text-6xl"
                buttonName="Назад"
              />
            </div>
          </>
        )}
      </div>
      <Modal open={open} setOpen={setIsOpen} modalProps={modalProps} />
    </>
  );
};
