export enum MachineProblemTypeEnum {
  MACHINE_PROBLEM = 0,
  QUALITY_PROBLEM = 1,
  MAINTENANCE_PROBLEM = 2,
  MATERIALS_PROBLEM = 3,
  PLANNED_ACTION = 4,
}

export enum PriorityTypeEnum {
  TOTAL_HALT = 0,
  EFFICIENCY_REDUCTION = 1,
  POTENTIAL_HALT = 2,
  NON_CRITICAL = 3,
  INACTIVE = 4,
  PLANNED_ACTION = 5,
  PREVENTIVE_MAINTENANCE = 6,
}

export enum LineStatus {
  TOTAL_HALT = 0,
  EFFICIENCY_REDUCTION = 1,
  POTENTIAL_HALT = 2,
  NON_CRITICAL = 3,
  INACTIVE = 4,
  PLANNED_ACTION = 5,
  PREVENTIVE_MAINTENANCE = 6,
  RUNNING = 7,
  NOT_RUNNING = 8,
}

export enum MachineOrderEnum {
  NAME = "name",
  DESCRIPTION = "description",
  SKU = "sku",
  MACHINE_TYPE = "machine_type_id",
  ORDER = "order",
  LINE = "line_id",
}
export enum StatusEnum {
  OPEN = 0,

  CLOSED = 1,

  PENDING = 2,

  FORWARDED = 3,

  ON_HOLD = 4,

  WAITING_FOR_APPROVAL = 5,

  APPROVED = 6,

  IN_PROGRESS = 7,

  REJECTED = 8,

  PRIORITY = 9,

  MATERIAL_NOT_AVAILABLE = 10,
}

export enum TeamEnum {
  A,
  B,
  C,
  N,
}

export type CreateTicketRequest = {
  ticket_type: MachineProblemTypeEnum;
  priority?: PriorityTypeEnum;
  machine_ids?: Array<string>;
  reason_id?: number;
  component_id?: string;
  corrective_action?: string;
  issue_description?: string;
  created_by_card_id: string;
  product_id?: number;
  line_id: number;
  status?: StatusEnum;
  datetime_from?: string;
  datetime_to?: string;
};

// export type CreatePlannedActionRequest = {
//   machine_ids: Array<string>;
//   date: Date;
//   time_from: string;
//   time_to: string;
//   card_id: string;
// };

export type TimeSeries = {
  line_id: number;
  timestamp: Date;
  status: LineStatus;
};

export type AcceptTicketRequest = {
  card_id: string;
};
export type CloseTicketRequest = {
  card_id: string;
  corrective_action?: string;
  issue_description?: string;
};
export type ForwardTicketRequest = {
  card_id: string;
};

export type UserRole = {
  id_: number;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  deleted?: boolean;
  name: string;
};

export type User = {
  id_: number;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  deleted?: boolean;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  is_admin: boolean;
  phone_number: string;
  card_id: string;
  roles: Array<UserRole>;
  team?: TeamEnum;
};

export type MachineType = {
  id_: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  deleted: boolean;
  name: string;
};
export type Escalation = {
  role_id: number;
  seconds_delay: number;
};

export type Daily = {
  role_id: number;
  hour: number;
  minute: number;
};

export type BasicProblem = {
  initial_escalation: Escalation;
  escalation_1: Escalation;
  escalation_2: Escalation;
  escalation_3: Escalation;
  escalation_4: Escalation;
  escalation_5: Escalation;
  forwarded_escalation: Escalation;
  closed_escalation: Escalation;
  daily: Daily;
};

export type MaterialsProblem = {
  initial_escalation: Escalation;
  escalation_1: Escalation;
  escalation_2: Escalation;
  escalation_3: Escalation;
  escalation_4: Escalation;
  escalation_5: Escalation;
  confirmation_escalation: Escalation;
  material_unavailable_escalation: Escalation;
  daily: Daily;
};

export type PlannedAction = {
  short_action_role_id: number;
  long_action_role_id: number;
  short_action_length_seconds: number;
};

export type EventsConfig = {
  MACHINE_PROBLEM: BasicProblem;
  QUALITY_PROBLEM: BasicProblem;
  MAINTENANCE_PROBLEM: BasicProblem;
  MATERIALS_PROBLEM: MaterialsProblem;
  PLANNED_ACTION: PlannedAction;
};
export type Line = {
  id_: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  deleted: boolean;
  name: string;
  password: string;
  event_config: EventsConfig;
};
export type Machine = {
  id_: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  deleted: boolean;
  name: string;
  description: string;
  sku: string;
  machine_type: MachineType;
  line: Line;
  image?: string;
};

export type HandleBy = {
  user_id: number;
  accepted_on: Date | null;
  forwarded_on: Date | null;
  user: User;
};

export enum TicketOrderBy {
  REASON = "reason",
  STATUS = "status",
  CORRECTIVE_ACTION = "corrective_action",
  ISSUE_DESCRIPTION = "issue_description",
  CREATED_BY = "created_by",
  PRIORITY = "priority",
  TICKET_TYPE = "ticket_type",
  MACHINES = "machines",
  COMPONENT_ID = "component_id",
  PRODUCT_ID = "product_id",
  PRODUCT = "product",
  HANDLED_BY = "handled_by",
  DATETIME_FROM = "datetime_from",
  DATETIME_TO = "datetime_to",
  APPROVED_BY = "approved_by",
  CREATED_AT = "created_at",
  LINE = "line_id",
  ID = "id_"
}

export enum OrderDirection {
  ASCENDING = 1,
  DESCENDING = -1,
}

export type ForwardedTo = {
  from_user_id : number;
  to_user_id : number;
  forwarded_on : number;
  from_user : User;
  to_user : User;
}

export type Ticket = {
  id_: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  deleted: boolean;

  reason: Reason;
  status: StatusEnum;

  closed_at?: Date;
  priority?: PriorityTypeEnum;
  ticket_type: MachineProblemTypeEnum;
  issue_description: string;
  corrective_action: string;
  product_id: number;
  machines: Array<Machine>;
  handled_by: Array<HandleBy>;
  created_by: User;
  product: Product;
  // operatorId?: string;
  component_id?: string;
  datetime_from: string;
  datetime_to: string;
  line?: Line;
  forwarded_to: Array<ForwardedTo>;
  closed_by?: User;
};

export type UpdateTicket = {
  reason?: string;
  status?: StatusEnum;
  corrective_action?: string;
  issue_description?: string;
  priority?: PriorityTypeEnum;
  ticket_type?: MachineProblemTypeEnum;
  component_id?: string;
  product_id?: number;
  datetime_from?: Date;
  datetime_to?: Date;
  approved_by?: number;
  card_id?:string;
};

export type ApprovePlannedAction = {
  card_id: string;
  datetime_from: string;
  datetime_to: string;
};

export type Product = {
  id_: number;
  lines: Array<Line>;

  name: string;
};

export type AverageTime = {
  average: number;
};

export type UserReport = {
  accepted_unclosed_tickets: number;
  forwarded_tickets: number;
  closed_tickets: number;
};

export type Report = {
  id_: number;
  first_name: string;
  last_name: string;
  avg_time_to_handle_ticket: number;
  avg_reaction_time: number;
  accepted_unclosed_tickets: number;
  forwarded_tickets: number;
  closed_tickets: number;
};

export type LineReport = {
  line_id: number;
  name: string;
  opened_tickets_count: number;
  active_tickets_count: number;
  forwarded_tickets_count: number;
  closed_tickets_count: number;
  avg_time_from_last_ticket: number | null;
  total_time_since_last_incident: number | null;
  total_time_for_solving_problem: number | null;
  mean_time_for_solving_problem: number | null;
  average_reaction_time: number | null;
  total_reaction_time: number | null;
};

export type CreateComponentRequest = {
  component_number: string;
  description: string;
};

export type Component = CreateComponentRequest & {
  id_: number;
};

export type ModalType = {
  title: string;
  subtitle: string;
};

export type MachineReport = {
  machine_id: number;
  machine_name: string;
  line_id: number;
  opened_tickets_count: number;
  active_tickets_count: number;
  forwarded_tickets_count: number;
  closed_tickets_count: number;
  line_name: string;
};

export type CreateReasonRequest = {
  reason: string;
};
export type Reason = CreateReasonRequest & {
  id_: number;
};
export type CSVResponse = {
  status: number;
  message: string;
};

export type CreateLoginRequest = {
  username: string;
  password: string;
};

export type LoginResponse = {
  access_token: string;
  token_type: string;
};

export type ChangePasswordRequest = {
  old_password: string;
  new_password: string;
  repeat_password: string;
  username: string;
};

export type Page<T> = {
  content: Array<T>;
  total: number;
};

export enum Status {
  SUCCESS,
  ERROR,
  LOADING,
}

export type Result<T> =
  | {
      value: T;
      message: null;
      status: Status.SUCCESS;
    }
  | {
      value: null;
      message: string;
      status: Status.ERROR;
    }
  | {
      value: null;
      message: null;
      status: Status.LOADING;
    };
