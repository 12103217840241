import { useEffect, useMemo, useState } from "react";
import { Report, Result, Status } from "../types";
import { usePagination, useSortBy, useTable } from "react-table";
import { ReportService } from "../service/reports";
import { useNavigate } from "react-router-dom";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";

export const UserReports: React.FC = () => {
  const columns: any = useMemo(
    () => [
      {
        Header: "Име",
        accessor: (row: Report) => {
          return `${row.first_name} ${row.last_name}`;
        },
      },
      {
        Header: "Број на примени тикети",
        accessor: (row: Report) => {
          return row.accepted_unclosed_tickets ?? "0";
        },
      },
      {
        Header: "Број на препратени тикети",
        accessor: (row: Report) => {
          return row.forwarded_tickets ?? "0";
        },
      },
      {
        Header: "Број на затворени тикети",
        accessor: (row: Report) => {
          return row.closed_tickets ?? "0";
        },
      },
      {
        Header: "Просечно време на реакција (минути)",
        accessor: (row: Report) => {
          return row.avg_reaction_time
            ? (row.avg_reaction_time / 60).toFixed(1)
            : "/";
        },
      },
      {
        Header: "Просечно време на решавање на тикет (минути)",
        accessor: (row: Report) => {
          return row.avg_time_to_handle_ticket
            ? (row.avg_time_to_handle_ticket / 60).toFixed(1)
            : "/";
        },
      },
    ],
    []
  );

  const navigate = useNavigate();
  const [content, setContent] = useState<Array<Report>>([]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data: content,
      initialState: { pageIndex: 0, pageSize: 12 },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    ReportService.getReports().then((result: Result<Array<Report>>) => {
      if (result.status === Status.SUCCESS) {
        setContent(result.value);
      }
    });
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <nav className="flex items-center  justify-between px-4 my-4 border-gray-200 sm:px-0">
        <div className="flex flex-1 w-0">
          {canPreviousPage && (
            <button
              onClick={() => previousPage()}
              className="inline-flex items-center py-4 text-white text-xl font-medium border-transparent bg-slate-500 px-3 rounded-md"
            >
              <ArrowNarrowLeftIcon
                className="mr-3 w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
              Претходно
            </button>
          )}
        </div>
        <div className="flex justify-center">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center py-4 text-white text-xl font-medium border-transparent bg-aptiv-orange px-8 rounded-md"
          >
            Назад
          </button>
        </div>
        <div className="flex flex-1 justify-end -mt-px w-0">
          {canNextPage && (
            <button
              onClick={() => nextPage()}
              className="inline-flex items-center py-4 text-white text-xl font-medium border-transparent bg-slate-500 px-3 rounded-md"
            >
              Следно
              <ArrowNarrowRightIcon
                className="ml-3 w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          )}
        </div>
      </nav>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-300"
              >
                <thead className="bg-gray-800">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6"
                        >
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-gray-200 bg-black"
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6"
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                        <td
                          onClick={() =>
                            navigate(`/reports/${row.original.id_}`)
                          }
                          className="whitespace-nowrap py-4 my-1 pl-4 pr-3 mr-8 cursor-pointer text-sm font-bold sm:pl-6 bg-red-500 rounded-md"
                        >
                          <span className="my-4 text-gray-100 mx-16">
                            Статистика
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
