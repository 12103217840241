import { AxiosResponse } from "axios";
import { instance } from "./axios";
import { Line, Page, TimeSeries, User } from "../types";
export interface ILineApi {
  fetchAll(page: number, page_size: number): Promise<AxiosResponse<Page<Line>>>;

  fetchById(line_id: number): Promise<AxiosResponse<Line>>;

  activateLine(line_id: number): Promise<AxiosResponse<TimeSeries>>;

  inactivateLine(line_id: number): Promise<AxiosResponse<TimeSeries>>;

  getUsersByLineAndRole(
    line_id: number,
    role_id: number
  ): Promise<AxiosResponse<Array<User>>>;
}

export const LineApi: ILineApi = {
  async fetchAll(
    page: number,
    page_size: number
  ): Promise<AxiosResponse<Page<Line>>> {
    const route = "lines/";
    return await instance.get<Page<Line>>(route, {
      params: { page, page_size },
    });
  },
  async fetchById(line_id: number): Promise<AxiosResponse<Line>> {
    const route = `lines/${line_id}`;
    return await instance.get<Line>(route);
  },
  async activateLine(line_id: number): Promise<AxiosResponse<TimeSeries>> {
    const route = `lines/${line_id}/activate`;
    return await instance.post<TimeSeries>(route);
  },
  async inactivateLine(line_id: number): Promise<AxiosResponse<TimeSeries>> {
    const route = `lines/${line_id}/inactivate`;
    return await instance.post<TimeSeries>(route);
  },
  async getUsersByLineAndRole(
    line_id: number,
    role_id: number
  ): Promise<AxiosResponse<Array<User>>> {
    const route = `lines/${line_id}/users/${role_id}`;
    return await instance.get<Array<User>>(route);
  },
};
