import { Formik } from "formik";
import { useTicket } from "../TicketContext";
import { TextInput } from "../components/TextInput/TextInput";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { TestButton } from "../components/TestButton/TestButton";
import Keyboard from "react-simple-keyboard";
import { layout } from "./TicketProblemDescription";
import { useKeyboard } from "../hooks/useKeyboard";
import { ReasonService } from "../service/reason";
import { CreateReasonRequest, Reason, Result, Status } from "../types";

export const AddNewReason: React.FC = () => {
  const navigate = useNavigate();
  const { dispatch } = useTicket();
  const [isVisible, toggleVisible] = useKeyboard(false);
  const keyboard = useRef(null);
  const [inputs, setInputs] = useState({});
  console.log(inputs);
  const [inputName, setInputName] = useState("default");
  const [layoutName, setLayoutName] = useState("default");

  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  const onChangeAll = (inputs: any) => {
    setInputs({ ...inputs });
  };

  const handleKeyboardStatusChange = () => {
    toggleVisible();
  };

  const onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleSubmit = (reason: string) => {
    const createReason: CreateReasonRequest = {
      reason,
    };
    ReasonService.createReason(createReason).then((resp: Result<Reason>) => {
      if (resp.status === Status.SUCCESS) {
        dispatch({
          type: "SET_REASON",
          payload: resp.value,
        });

        navigate("/material-issue/new");
      }
    });
  };

  return (
    <Formik
      initialValues={{ reason: "" }}
      onSubmit={(values, actions) => {
        handleSubmit(values.reason);
      }}
    >
      {(props) => (
        <form
          className="justify-center mt-5 w-full"
          onSubmit={(e) => e.preventDefault()}
          action="return false;"
        >
          <div className="flex justify-center">
            <div className="mr-5 px-12 py-12 border rounded-lg">
              <label
                htmlFor="id"
                className="block ml-px pb-3 pl-4 text-center text-white text-2xl font-medium"
              >
                Опис на проблемот
              </label>
              <div className="flex justify-center mt-3 text-center">
                <TextInput
                  type="text"
                  name={"reason"}
                  id={"reason"}
                  autoFocus={true}
                  placeholder={"reason"}
                  onClick={handleKeyboardStatusChange}
                  setInputName={setInputName}
                ></TextInput>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-3">
            <TestButton
              type="button"
              className={
                "py-4 mt-3   px-10 rounded-md mr-2 mb-2 text-3xl bg-aptiv-light-gray"
              }
              onClick={() => {
                props.handleSubmit();
              }}
            >
              Зачувај
            </TestButton>
          </div>
          {isVisible && (
            <Keyboard
              keyboardRef={(r) => (keyboard.current = r)}
              inputName={inputName}
              onChangeAll={(inputs: any) => {
                props.setFieldValue("reason", inputs.reason);

                onChangeAll(inputs);
              }}
              layout={layout}
              theme="hg-theme-default myTheme2"
              onKeyPress={onKeyPress}
              layoutName={layoutName}
            />
          )}
        </form>
      )}
    </Formik>
  );
};
