import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ReportService } from "../service/reports";
import {
  MachineProblemTypeEnum,
  Status,
  StatusEnum,
  Ticket,
  User,
  UserReport,
} from "../types";
import { PieChart } from "react-minimal-pie-chart";
import { AccountService } from "../service/accounts";
import { useNavigate } from "react-router";
import { TicketService } from "../service/tickets";
import { getPriorityType, getStatus } from "../utils";
import { usePagination, useSortBy, useTable } from "react-table";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";
export const ReportDetails: React.FC = () => {
  const columns: any = useMemo(
    () => [
      {
        Header: "ИД",
        accessor: (ticket: Ticket) => {
          return ticket.id_;
        },
      },
      {
        Header: "Статус",
        accessor: (ticket: Ticket) => {
          return `${getStatus(ticket.status)}`;
        },
      },
      {
        Header: "Приоритет",
        accessor: (ticket: Ticket) => {
          return ticket.priority ? `${getPriorityType(ticket.priority)}` : "/";
        },
      },
    ],
    []
  );

  const { id } = useParams<"id">();
  const navigate = useNavigate();
  const [averageReactionTime, setAverageReactionTime] = useState<number>();
  const [averageTimeToHandleTicket, setAverageTimeToHandleTicket] =
    useState<number>();
  const [userReport, setUserReport] = useState<UserReport>({} as UserReport);
  const [user, setUser] = useState<User>({} as User);
  const [tickets, setTickets] = useState<Array<Ticket>>([]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data: tickets,
      initialState: { pageIndex: 0, pageSize: 8 },
    },
    useSortBy,
    usePagination
  );
  useEffect(() => {
    Promise.all([
      ReportService.getAverageReactionTime(parseInt(id!)),
      ReportService.getAverageTimeToHandleTicket(parseInt(id!)),
      ReportService.getUserReport(parseInt(id!)),
      AccountService.fetchById(parseInt(id!)),
      TicketService.fetchAllByUserId(parseInt(id!), 0, 100),
    ]).then(
      ([
        averageReactionTime,
        averageTimeToHandleTicket,
        userReport,
        user,
        tickets,
      ]) => {
        if (averageReactionTime.status === Status.SUCCESS) {
          setAverageReactionTime(averageReactionTime.value.average / 60);
        }
        if (averageTimeToHandleTicket.status === Status.SUCCESS) {
          setAverageTimeToHandleTicket(
            averageTimeToHandleTicket.value.average / 60
          );
        }
        if (userReport.status === Status.SUCCESS) {
          setUserReport(userReport.value);
        }
        if (user.status === Status.SUCCESS) {
          setUser(user.value);
        }
        if (tickets.status === Status.SUCCESS) {
          setTickets(tickets.value.content);
        }
      }
    );
  }, [id]);

  const navigateTo = (ticket: Ticket) => {
    if (
      ticket.status === StatusEnum.CLOSED ||
      ticket.status === StatusEnum.REJECTED
    ) {
      return `/tickets/${ticket.id_}`;
    } else if (ticket.ticket_type === MachineProblemTypeEnum.PLANNED_ACTION) {
      return `/pending-action/${ticket.id_}`;
    } else if (
      ticket.ticket_type === MachineProblemTypeEnum.MATERIALS_PROBLEM
    ) {
      return `/pending-material-ticket/${ticket.id_}`;
    }
    return `/open-new-ticket/${ticket.id_}`;
  };

  return (
    <div>
      <div className="flex justify-between text-white text-3xl font-bold mt-3">
        <button
          className="bg-aptiv-orange ml-3 rounded-md p-3"
          onClick={() => navigate(-1)}
        >
          Назад
        </button>

        <div>{`${user.first_name} ${user.last_name}`}</div>

        <button
          className="bg-aptiv-orange mr-3 rounded-md p-3"
          onClick={() => navigate("/user")}
        >
          Дома
        </button>
      </div>
      <div className="grid grid-cols-2 mx-10 mt-10">
        <div className="mt-auto">
          <PieChart
            className="mx-auto mb-8 w-1/2 h-[100%]"
            data={[
              {
                color: "#F84018",
                title: "ПРИФАТЕНИ",
                value: userReport.accepted_unclosed_tickets,
              },
              {
                color: "#1E90FF",
                title: "ПРЕПРАТЕНИ",
                value: userReport.forwarded_tickets,
              },
              {
                color: "#171d26",
                title: "ЗАТВОРЕНИ",
                value: userReport.closed_tickets,
              },
            ]}
            labelPosition={55}
            //paddingAngle={0}
            radius={50}
            //startAngle={0}
            label={({ dataEntry, x, y, dx, dy }) => (
              <>
                {dataEntry.value > 0 && (
                  <>
                    <text
                      x={x}
                      y={y}
                      dx={dx}
                      dy={dy}
                      dominant-baseline="central"
                      text-anchor="middle"
                      style={{ fontSize: "6px", fill: "white" }}
                    >
                      {`${Math.round(dataEntry.percentage)} %`}
                    </text>
                    <text
                      x={x - 1}
                      y={y + 5}
                      dx={dx}
                      dy={dy}
                      dominant-baseline="central"
                      text-anchor="middle"
                      style={{ fontSize: "6px", fill: "white" }}
                    >
                      {`${dataEntry.title}`}
                    </text>
                    <text
                      x={x}
                      y={y + 12}
                      dx={dx}
                      dy={dy}
                      dominant-baseline="central"
                      text-anchor="middle"
                      style={{ fontSize: "6px", fill: "white" }}
                    >
                      {`${dataEntry.value}`}
                    </text>
                  </>
                )}
              </>
            )}
            labelStyle={{
              fontSize: "4px",
              fontWeight: "500",
              fill: "white",
              color: "white",
            }}
          />
          <div className="my-auto mx-auto flex gap-x-5">
            <div className="text-black bg-gray-500 text-2xl p-3 rounded-md ">
              <div className="text-center">
                Просечно време на реакција (минути)
              </div>
              <div className="text-center">
                {averageReactionTime?.toFixed(1)}
              </div>
            </div>
            <div className="text-black bg-gray-500 text-2xl p-3 rounded-md">
              <div className="text-center">
                Просечно време за решавање на тикет (минути)
              </div>
              <div className="text-center">
                {averageTimeToHandleTicket?.toFixed(1)}
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 sm:px-6 lg:px-8">
          <nav className="flex items-center  justify-between px-4 my-4 border-gray-200 sm:px-0">
            <div className="flex flex-1 w-0">
              {canPreviousPage && (
                <button
                  onClick={() => previousPage()}
                  className="inline-flex items-center py-4 text-white text-xl font-medium border-transparent bg-slate-500 px-3 rounded-md"
                >
                  <ArrowNarrowLeftIcon
                    className="mr-3 w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Претходно
                </button>
              )}
            </div>
            <div className="flex flex-1 justify-end -mt-px w-0">
              {canNextPage && (
                <button
                  onClick={() => nextPage()}
                  className="inline-flex items-center py-4 text-white text-xl font-medium border-transparent bg-slate-500 px-3 rounded-md"
                >
                  Следно
                  <ArrowNarrowRightIcon
                    className="ml-3 w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              )}
            </div>
          </nav>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table
                    {...getTableProps()}
                    className="min-w-full divide-y divide-gray-300"
                  >
                    <thead className="bg-gray-800">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6"
                            >
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      {...getTableBodyProps()}
                      className="divide-y divide-gray-200 bg-black"
                    >
                      {page.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            onClick={() => navigate(navigateTo(row.original))}
                            className="cursor-pointer"
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6"
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
