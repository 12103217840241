import { useEffect, useState } from "react";
import { Handler } from "../service/result";
import {
  MachineProblemTypeEnum,
  OrderDirection,
  Page,
  Result,
  Status,
  StatusEnum,
  TicketOrderBy,
} from "../types";
import { useSessionStorage } from "./useSessionStorage";

const PAGE_SIZE = 8;

export const usePagination = <T>(
  changeHandler: (
    line_id: number,
    page: number,
    size: number,
    status?: Array<StatusEnum>,
    type?: Array<MachineProblemTypeEnum>,
    reason_id?: number,
    order_by?: Array<TicketOrderBy>,
    order_direction?: OrderDirection,
    start_date?: string,
    end_date?: string,
  ) => Promise<Result<Page<T>>>,
  page_size?: number,
  status?: Array<StatusEnum>,
  type?: Array<MachineProblemTypeEnum>,
  order_by?: Array<TicketOrderBy>,
  order_direction?: OrderDirection,
  start_date?: string,
  end_date?: string,
) => {
  const [content, setContent] = useState<Result<Page<T>>>(
    Handler.LoadingResult()
  );
  const [user] = useSessionStorage("user");
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(PAGE_SIZE);
  const [max, setMax] = useState<number>(0);
  const [canNext, setCanNext] = useState<boolean>(false);
  const [canPrev, setCanPrev] = useState<boolean>(false);

  useEffect(() => {
    setContent(Handler.LoadingResult());
    changeHandler(
      user.id_,
      page,
      page_size || size,
      status,
      type,
      undefined,
      order_by,
      order_direction,
      start_date,
      end_date
    ).then((result: Result<Page<T>>) => {
      if (result.status === Status.SUCCESS) {
        const resp: Page<T> = result.value;
        setCanNext((_) => (page + 1) * size < resp.total);
        setCanPrev((_) => page >= 1);
        setMax((_) => Math.ceil(resp.total / size));
      }
      setContent(result);
    });
    // eslint-disable-next-line
  }, [
    changeHandler,
    user.id_,
    page,
    size,
    page_size,
    start_date,
    end_date,
    // eslint-disable-next-line
    JSON.stringify(order_by),
    order_direction,
    // eslint-disable-next-line
    JSON.stringify(status),
    // eslint-disable-next-line
    JSON.stringify(type),
  ]);

  const handleNextPage = () => {
    setPage((prev) => (canNext ? prev + 1 : prev));
  };

  const handlePrevPage = () => {
    setPage((prev) => (canPrev ? prev - 1 : prev));
  };

  const handleGotoPage = (page: number) => {
    setPage((prev) => (page >= 0 && page < max ? page : prev));
  };

  const handlePageSizeChange = (size: number) => {
    setSize(() => size);
  };

  const handleChangeContent = (content: Result<Page<T>>) => {
    setContent(content);
  };

  return {
    max,
    page,
    size,
    content,
    canNext,
    canPrev,
    handleNextPage,
    handlePrevPage,
    handleGotoPage,
    handlePageSizeChange,
    handleChangeContent,
  };
};
