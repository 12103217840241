export const TRANSLATIONS_EN = {
  loginPage: {
    loginLine: "Line log in",
    loginUser: "User log in",
    line: "Line",
    password: "Password",
    login: "Log in",
    selectLineError: "Select Line",
    requiredField: "Required Field",
    wrongPassword: "Wrong password",
  },
  homePage: {
    createNewTicket: "Create new ticket",
    openedTickets: "Opened tickets",
    closedTickets: "Closed tickets",
    deactivate: "Deactivate",
  },
  createTicket:{
    reasonForStoppage : "Reason for stoppage",
    machineInterruption : "Machine/Process interruption",
    materialIssue : "Material issue"
  },
  userHomePage : {
    reports : "Reports",
    input : "Input"
  },
  reportsPage : {
    userReport : "User report",
    lineReport : "Line report",
    ticketReport : "Ticket report",
    machineReport : "Machine report"
  },
  common: {
    input : "Input",
    error: "Error",
    logOut: "Log out",
    home : "Home",
    back : "Back"
  },
};
