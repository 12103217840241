import { useNavigate } from "react-router-dom";
import { useTicket } from "../TicketContext";
import { useKeyboard } from "../hooks/useKeyboard";
import { useRef, useState } from "react";
import { layout } from "./TicketProblemDescription";
import { IssueTypeHeader } from "../components/IssueTypeHeader/IssueTypeHeader";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../components/TextInput/TextInput";
import { TestButton } from "../components/TestButton/TestButton";
import Keyboard from "react-simple-keyboard";
import { ButtonComponent } from "../components/ButtonComponent/ButtonComponent";
import {
  Component,
  CreateComponentRequest,
  ModalType,
  Result,
  Status,
} from "../types";
import { ComponentService } from "../service/component";
import { Modal } from "../components/Modal/Modal";

const ComponentSchema = Yup.object().shape({
  name: Yup.string().required("Задолжително поле"),
  materialPartNumber: Yup.string().required("Задолжително поле"),
});

export const AddNewComponent: React.FC = () => {
  const navigate = useNavigate();
  const ticketContext = useTicket();

  const dispatchTicket = ticketContext.dispatch;

  const [isVisible, toggleVisible] = useKeyboard(false);
  const keyboard = useRef(null);
  const [inputs, setInputs] = useState({});
  console.log(inputs);
  const [inputName, setInputName] = useState("default");
  const [layoutName, setLayoutName] = useState("default");

  const [open, setIsOpen] = useState(false);

  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);

  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  const onChangeAll = (inputs: any) => {
    setInputs({ ...inputs });
  };

  const handleKeyboardStatusChange = () => {
    toggleVisible();
  };

  const onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const onSubmit = async (component_number: string, description: string) => {
    const component: CreateComponentRequest = {
      component_number,
      description,
    };
    ComponentService.createComponent(component).then(
      (resp: Result<Component>) => {
        if (resp.status === Status.SUCCESS) {
          dispatchTicket({
            type: "SET_COMPONENT_NUMBER",
            payload: component_number,
          });
          navigate("/material-issue/new");
        } else if (resp.status === Status.ERROR) {
          setIsOpen(true);
          setModalProps({
            title: "Настана грешка",
            subtitle: `${resp.message}`,
          });
        }
      }
    );
  };

  return (
    <div className="grid h-full">
      {!isVisible && <IssueTypeHeader issueType="Додади нова компонента" />}
      <Formik
        initialValues={{
          name: "",
          materialPartNumber: "",
        }}
        onSubmit={(values) => {
          // post this to backend

          onSubmit(values.materialPartNumber, values.name);
        }}
        validationSchema={ComponentSchema}
      >
        {(props) => (
          <form
            className="mt-10"
            onSubmit={(e) => e.preventDefault()}
            action="return false;"
          >
            <div className="flex justify-evenly space-x-4">
              <div className="">
                <div className="w-80 rounded-md bg-aptiv-dark-slate-blue py-4 text-center text-2xl">
                  Име на компонента
                </div>
                <TextInput
                  id={"name"}
                  name={"name"}
                  placeholder={"Име на компонента"}
                  type={"text"}
                  onClick={handleKeyboardStatusChange}
                  setInputName={setInputName}
                />
              </div>
              <div className="relative">
                <div className="w-80 rounded-md bg-aptiv-dark-slate-blue py-4 text-center text-2xl">
                  Број на компонента
                </div>

                <TextInput
                  id={"materialPartNumber"}
                  name={"materialPartNumber"}
                  placeholder={"Material part number"}
                  type={"text"}
                  onClick={handleKeyboardStatusChange}
                  setInputName={setInputName}
                />
              </div>
            </div>
            <div className="mt-3 flex justify-center">
              <TestButton
                className="rounded-lg bg-aptiv-sun px-24 py-6 text-4xl"
                type="button"
                onClick={() => {
                  props.handleSubmit();
                }}
              >
                Додади нова компонента
              </TestButton>
            </div>
            {isVisible && (
              <Keyboard
                keyboardRef={(r) => (keyboard.current = r)}
                inputName={inputName}
                onChangeAll={(inputs: any) => {
                  props.setFieldValue("name", inputs.name);
                  props.setFieldValue(
                    "materialPartNumber",
                    inputs.materialPartNumber
                  );
                  onChangeAll(inputs);
                }}
                layout={layout}
                theme="hg-theme-default myTheme2"
                onKeyPress={onKeyPress}
                layoutName={layoutName}
              />
            )}
          </form>
        )}
      </Formik>
      {!isVisible && (
        <div className="mx-8 mb-4 mt-auto flex justify-between">
          <ButtonComponent
            onClick={() => navigate("/")}
            classes="bg-aptiv-dark-gray px-8  rounded-md pb-4 text-6xl"
            buttonName="Почетна"
          />

          <ButtonComponent
            onClick={() => navigate(-1)}
            classes="bg-aptiv-dark-gray px-8  rounded-md pb-4 text-6xl"
            buttonName="Назад"
          />
        </div>
      )}
      <Modal open={open} setOpen={setIsOpen} modalProps={modalProps} />
    </div>
  );
};
