import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const axiosConfig: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  // headers: {
  //   "Access-Control-Allow-Origin": "*",
  // },
};

export const instance: AxiosInstance = axios.create(axiosConfig);
