import React from "react";

type TableRowProps = {
  title: string;
  value: string | number;
};

export const TableRow: React.FC<TableRowProps> = ({ title, value }) => {
  return (
    <div className="px-4 py-3 bg-black border sm:grid sm:gap-4 sm:grid-cols-3 sm:px-6">
      <dt className="text-white text-2xl font-medium">{title}</dt>
      <dd className="mt-1 text-white text-2xl sm:col-span-2 sm:mt-0">
        {value}
      </dd>
    </div>
  );
};
