import { OpenRequest } from "../components/RequestAction/OpenRequest";

import { TableDetail } from "../components/Table/TableDetail";
import { AccountService } from "../service/accounts";
import { TicketService } from "../service/tickets";
import {
  CreateTicketRequest,
  ModalType,
  Result,
  Status,
  Ticket,
} from "../types";
import { useNavigate } from "react-router";
import { useTicket } from "../TicketContext";
import { Modal } from "../components/Modal/Modal";
import { useState } from "react";
export const RequestAction: React.FC = () => {
  const navigate = useNavigate();
  const { dispatch } = useTicket();

  const [open, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);
  const handleSubmit = async (action: CreateTicketRequest) => {
    const card_id = action.created_by_card_id;
    const user = await AccountService.fetchByCardId(card_id);
    if (user.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }
    const resp: Result<Ticket> = await TicketService.createTicket(action);
    if (resp.status === Status.SUCCESS) {
      navigate(`/pending-action/${resp.value.id_}`);
      dispatch({
        type: "REQUEST_PLANNED_ACTION",
        payload: user.value,
      });
    } else if (resp.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${resp.message}`,
      });
    }
  };

  return (
    <div className="mt-4 flex">
      <div className="ml-4 w-1/3">
        <OpenRequest onSubmit={handleSubmit} />
      </div>
      <div className="ml-4 w-2/3">
        <TableDetail title={"Информации за акција"} />
      </div>
      <Modal open={open} setOpen={setIsOpen} modalProps={modalProps} />
    </div>
  );
};
