import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "../components/ButtonComponent/ButtonComponent";

export const ChooseReport: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="grid h-full">
      <div className="mx-4 mt-auto grid grid-rows-2 grid-cols-4 justify-items-center gap-3">
        {/* <ButtonComponent  classes="bg-aptiv-dark-gray rounded-md text-6xl  py-12 w-full" disabled={true} buttonName="Продукција" /> */}
        <a
          href={`${process.env.REACT_APP_REPORTS_USERS}`}
          className="w-full rounded-md bg-aptiv-orange py-12 text-center text-6xl font-normal  text-white "
        >
          {t("reportsPage.userReport")}
        </a>
        <a
          href={`${process.env.REACT_APP_REPORTS_LINES}`}
          className="w-full rounded-md bg-aptiv-orange py-12 text-center text-6xl font-normal  text-white "
        >
          {t("reportsPage.lineReport")}
        </a>
        <a
          href={`${process.env.REACT_APP_REPORTS_TICKETS}`}
          className="w-full rounded-md bg-aptiv-orange py-12 text-center text-6xl font-normal  text-white "
        >
          {t("reportsPage.ticketReport")}
        </a>
        <a
          href={`${process.env.REACT_APP_REPORTS_MACHINES}`}
          className="w-full rounded-md bg-aptiv-orange py-12 text-center text-6xl font-normal  text-white "
        >
          {t("reportsPage.machineReport")}
        </a>
        <a
          href={`${process.env.REACT_APP_REPORTS_SINGLE_LINE}`}
          className="w-full rounded-md bg-aptiv-orange py-12 text-center text-6xl font-normal  text-white "
        >
          Извештај за една линија
        </a>
        <a
          href={`${process.env.REACT_APP_REPORTS_SINGLE_USERS}`}
          className="w-full rounded-md bg-aptiv-orange py-12 text-center text-6xl font-normal  text-white "
        >
          Извештај за еден корисник
        </a>
        <a
          href={`${process.env.REACT_APP_REPORTS_SINGLE_MACHINE}`}
          className="w-full rounded-md bg-aptiv-orange py-12 text-center text-6xl font-normal  text-white "
        >
          Извештај за една машина
        </a>
      </div>
      <div className="mx-8 mb-4 mt-auto flex justify-between">
        <ButtonComponent
          onClick={() => navigate("/user")}
          classes="bg-aptiv-dark-gray px-8 rounded-md pb-4 text-6xl"
          buttonName={t("common.home")}
        />
        <a
          href={`${process.env.REACT_APP_ADMIN_URL}`}
          className="rounded-md bg-aptiv-orange px-8 pb-4 text-center text-6xl font-normal text-white"
        >
          {t("common.input")}
        </a>
      </div>
    </div>
  );
};
