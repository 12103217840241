import { AxiosResponse } from "axios";
import { Page, User } from "../types";
import { instance } from "./axios";

export interface IAccountApi {
  fetchAll(page: number, page_size: number): Promise<AxiosResponse<Page<User>>>;
  fetchById(user_id: number): Promise<AxiosResponse<User>>;
  fetchByCardId(card_id: string): Promise<AxiosResponse<User>>;
  fetchByRole(role_id: number): Promise<AxiosResponse<Array<User>>>;
  fetchByUsername(username: string): Promise<AxiosResponse<User>>;
}

export const AccountApi: IAccountApi = {
  async fetchAll(
    page: number,
    page_size: number
  ): Promise<AxiosResponse<Page<User>>> {
    const route = "users/";
    return await instance.get<Page<User>>(route, {
      params: { page, page_size },
    });
  },
  async fetchById(user_id: number): Promise<AxiosResponse<User>> {
    const route = `users/${user_id}`;
    return await instance.get<User>(route);
  },
  async fetchByCardId(card_id: string): Promise<AxiosResponse<User>> {
    const route = `users/c/${card_id}`;
    return await instance.get<User>(route);
  },
  async fetchByRole(role_id: number): Promise<AxiosResponse<Array<User>>> {
    const route = `users/r/${role_id}`;
    return await instance.get<Array<User>>(route);
  },
  async fetchByUsername(username: string): Promise<AxiosResponse<User>> {
    const route = `users/u/${username}`;
    return await instance.get<User>(route);
  },
};
