import React from "react";
import { getStatusColor } from "./GridList";
import { useNavigate } from "react-router";
import { Ticket, TicketOrderBy } from "../../types";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { getMachineProblemType, getStatus } from "../../utils";
import moment from "moment";
import { DISPLAY_OPEN_DATE_FORMAT } from "../../const";

type Props = {
  tickets : Array<Ticket>;
  requestSort : (key : TicketOrderBy) => void;
  icons : (key : TicketOrderBy) => JSX.Element | undefined;
}

export const TableList: React.FC<Props> = ({
  tickets,
  requestSort,
  icons
}) => {
  const [user] = useSessionStorage("user");
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <div className="-my-2">
        <div className="inline-block align-middle py-1 w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden">
            <table className="w-full min-w-full border-2 table-fixed">
              <thead className="bg-gray-300">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-bold tracking-wider cursor-pointer uppercase"
                    onClick={() => requestSort(TicketOrderBy.ID)}
                  >
                    <div className="flex h-5">
                      Тикет ИД
                      <div className="w-1"> {icons(TicketOrderBy.ID)}</div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-bold tracking-wider cursor-pointer uppercase"
                    onClick={() => requestSort(TicketOrderBy.LINE)}
                  >
                    <div className="flex h-5">
                      Линија
                      <div className="w-1"> {icons(TicketOrderBy.LINE)} </div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-bold tracking-wider cursor-pointer uppercase"
                    onClick={() => requestSort(TicketOrderBy.MACHINES)}
                  >
                    <div className="flex h-5">
                      Машина
                      <div className="w-1">{icons(TicketOrderBy.MACHINES)}</div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-bold tracking-wider cursor-pointer uppercase"
                    onClick={() => requestSort(TicketOrderBy.STATUS)}
                  >
                    <div className="flex h-5">
                      Статус
                      <div className="w-1"> {icons(TicketOrderBy.STATUS)}</div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-bold tracking-wider cursor-pointer uppercase"
                    onClick={() => requestSort(TicketOrderBy.TICKET_TYPE)}
                  >
                    <div className="flex h-5">
                      Причина за проблем
                      <div className="w-1">{icons(TicketOrderBy.TICKET_TYPE)}</div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-bold tracking-wider cursor-pointer uppercase"
                    onClick={() => requestSort(TicketOrderBy.CREATED_AT)}
                  >
                    <div className="flex h-5">
                      Отворен на
                      <div className="w-1">{icons(TicketOrderBy.CREATED_AT)}</div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-bold tracking-wider cursor-pointer uppercase"
                    onClick={() => requestSort(TicketOrderBy.CREATED_BY)}
                  >
                    <div className="flex h-5">
                      Отворен од
                      <div className="w-1">{icons(TicketOrderBy.CREATED_BY)}</div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-bold tracking-wider cursor-pointer uppercase"
                    onClick={() => requestSort(TicketOrderBy.HANDLED_BY)}
                  >
                    <div className="flex h-5">
                      Примен од
                      <div className="w-1">
                        {icons(TicketOrderBy.HANDLED_BY)}
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-white divide-y">
                {tickets.map((ticket, id) => (
                  <tr
                    key={id}
                    onClick={() => navigate(`/tickets/${ticket.id_}`)}
                    className="cursor-pointer"
                  >
                    <td className="px-6 py-4 text-base font-semibold">
                      {ticket.id_}
                    </td>
                    <td className="px-1 py-4 text-sm">{user.name}</td>
                    <td className="px-1 py-4 text-sm">
                      {ticket.machines.map((m) => m.name).join(",")}
                    </td>
                    <td className={"px-6 text-sm "}>
                      <span className={getStatusColor(ticket.status)}>
                        {getStatus(ticket.status)}
                      </span>
                    </td>

                    <td className="px-6 py-4 text-sm">
                      {getMachineProblemType(ticket.ticket_type)}
                    </td>
                    <td className="px-6 py-4 text-sm">
                      {moment.utc(
                        ticket.created_at,
                        "YYYY-MM-DD[T]HH:mm:ss"
                      ).local().format(DISPLAY_OPEN_DATE_FORMAT)}
                    </td>
                    <td className="px-6 py-4 text-sm">
                      {ticket.created_by.first_name +
                        " " +
                        ticket.created_by.last_name}
                    </td>
                    <td className="px-6 py-4 text-sm">
                      {ticket.handled_by.length > 0
                        ? ticket.handled_by?.[1].user.first_name +
                          " " +
                          ticket.handled_by?.[1].user.last_name
                        : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
