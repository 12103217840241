import { ErrorMessage, Field, FieldAttributes, FormikContext } from "formik";
import React, { ChangeEvent, useContext } from "react";
import { inputInvalidClass, inputValidClass } from "../../base";

interface TextInputProps extends React.HTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  label?: string;
  grid?: string;
  placeholder?: string;
  type: "text" | "password";
  autoFocus?: boolean;
  onClick?: () => void;
  setInputName?: (input: string) => void;
  fieldAttributes?: FieldAttributes<any>;
  keyboard?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({
  id,
  name,
  label,
  grid,
  type,
  placeholder,
  autoFocus,
  onClick,
  setInputName,
  fieldAttributes,
  keyboard = true,
}) => {
  const context = useContext(FormikContext);

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value;
    context.handleChange(event);
    context.setFieldValue(name, input);
  };
  return (
    <div>
      <label
        htmlFor={id}
        className="block text-center text-xl font-medium text-white sm:mt-px sm:pb-2"
      >
        {label}
      </label>
      <div className="relative mt-1 flex items-center">
        <Field
          name={name}
          id={id}
          type={type}
          value={context.values[name]}
          placeholder={placeholder}
          onChange={onChangeInput}
          className={
            context.errors.name
              ? inputInvalidClass
              : inputValidClass + " px-12 pb-5 pt-5 text-center"
          }
          autoFocus={autoFocus}
          autoComplete="off"
          onFocus={() => setInputName?.(name)}
          {...fieldAttributes}
        />
        {keyboard && (
          <div
            className="border-rad absolute inset-y-0 right-0 flex cursor-pointer border-l-2 py-1.5 pr-1.5"
            onClick={onClick}
          >
            <img src="/keyboard.svg" alt="keyboard-icon" className="px-2" />
          </div>
        )}
      </div>
      <div className="mb-5 mt-2 h-2 text-center">
        <ErrorMessage name={name}>
          {(errorMessage) => (
            <span className="font-regular text-xl text-red-600">
              {errorMessage}
            </span>
          )}
        </ErrorMessage>
      </div>
    </div>
  );
};
