import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { ChangePasswordRequest, ModalType, Status, User } from "../../types";
import { UpdatePasswordForm } from "../PasswordForm/PasswordForm";
import { AuthService } from "../../service/auth";
import { Modal } from "./Modal";

type ModalProps = {
  open: boolean;
  setOpen: (input: boolean) => void;
  user?: User;
};

export const UpdatePasswordModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  user,
}) => {
  const handleSubmit = (req: ChangePasswordRequest) => {
    AuthService.change_password(req).then((resp) => {
      if (resp.status === Status.SUCCESS) {
        setOpen(false);
      } else if (resp.status === Status.ERROR) {
        setOpen(false);
        setModalProps({
          title: "Настана грешка",
          subtitle: `${resp.message}`,
        });
        setIsModalOpen(true);
      }
    });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform overflow-hidden rounded-lg bg-black px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  ></button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-white"
                    >
                      Променете ја лозинката на {user?.first_name}{" "}
                      {user?.last_name}
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-5 ml-14 sm:mt-4 sm:text-left">
                  <UpdatePasswordForm
                    onClick={handleSubmit}
                    cancel={() => setOpen(false)}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Modal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        modalProps={modalProps}
      />
    </>
  );
};
