import { AxiosResponse } from "axios";
import { ReportApi } from "../api/reports";
import {
  AverageTime,
  LineReport,
  MachineReport,
  Report,
  Result,
  TimeSeries,
  UserReport,
} from "../types";
import { Handler } from "./result";

export interface IReportService {
  getLineReports(): Promise<Result<Array<LineReport>>>;
  getReports(): Promise<Result<Array<Report>>>;
  getAverageReactionTime(user_id: number): Promise<Result<AverageTime>>;
  getAverageTimeToHandleTicket(user_id: number): Promise<Result<AverageTime>>;
  getUserReport(user_id: number): Promise<Result<UserReport>>;
  getMachineReports(): Promise<Result<Array<MachineReport>>>;
  getTimeSeriesByLine(line_id: number): Promise<Result<Array<TimeSeries>>>;
}

export const ReportService: IReportService = {
  async getLineReports(): Promise<Result<Array<LineReport>>> {
    return await ReportApi.getLineReports()
      .then((resp: AxiosResponse<Array<LineReport>>) =>
        Handler.SuccessResult(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async getReports(): Promise<Result<Array<Report>>> {
    return await ReportApi.getReports()
      .then((resp: AxiosResponse<Array<Report>>) =>
        Handler.SuccessResult(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async getAverageReactionTime(user_id: number): Promise<Result<AverageTime>> {
    return await ReportApi.getAverageReactionTime(user_id)
      .then((resp: AxiosResponse<AverageTime>) =>
        Handler.SuccessResult<AverageTime>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async getAverageTimeToHandleTicket(
    user_id: number
  ): Promise<Result<AverageTime>> {
    return await ReportApi.getAverageTimeToHandleTicket(user_id)
      .then((resp: AxiosResponse<AverageTime>) =>
        Handler.SuccessResult<AverageTime>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async getUserReport(user_id: number): Promise<Result<UserReport>> {
    return await ReportApi.getUserReport(user_id)
      .then((resp: AxiosResponse<UserReport>) =>
        Handler.SuccessResult<UserReport>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async getTimeSeriesByLine(
    line_id: number
  ): Promise<Result<Array<TimeSeries>>> {
    return await ReportApi.getTimeSeriesByLine(line_id)
      .then((resp) => Handler.SuccessResult(resp.data))
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async getMachineReports(): Promise<Result<Array<MachineReport>>> {
    return await ReportApi.getMachineReports()
      .then((resp) => Handler.SuccessResult(resp.data))
      .catch((reason) => Handler.ErrorResult(reason));
  },
};
