import React, { useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../TextInput/TextInput";
import { TestButton } from "../TestButton/TestButton";
import { KeyboardWrapper } from "../Keyboard/KeyboardWrapper";
import { useKeyboard } from "../../hooks/useKeyboard";
type Props = {
  onSubmit: (card_id: string) => void;
};

const Schema = Yup.object().shape({
  id: Yup.string().required("Задолжително поле"),
});

export const UnpauseTicket: React.FC<Props> = ({ onSubmit }) => {
  const keyboard = useRef(null);
  const [isVisible, toggleVisible] = useKeyboard(false);

  return (
    <Formik
      initialValues={{ id: "" }}
      onSubmit={(values, actions) => {
        onSubmit(values.id);
      }}
      validationSchema={Schema}
    >
      {(props) => (
        <form
          className="mt-5 w-full"
          autoComplete="off"
          onSubmit={(e) => e.preventDefault()}
          action="return false;"
        >
          <div className="grid justify-center mt-3">
            <label
              htmlFor="name"
              className="block ml-px mt-2 pb-3 pl-4 text-center text-white text-2xl font-medium"
            >
              Одговорен на линија
            </label>
            <TextInput
              type="text"
              name="id"
              id="id"
              autoFocus={true}
              placeholder="id"
              onClick={toggleVisible}
            ></TextInput>
          </div>
          <div className="flex justify-center mt-3">
            <TestButton
              className={
                "py-4 px-4 mt-3 rounded-md mr-2 mb-2 text-3xl bg-aptiv-orange "
              }
              type="button"
              onClick={() => {
                props.handleSubmit();
              }}
            >
              Отпаузирај тикет
            </TestButton>
          </div>
          {isVisible && (
            <KeyboardWrapper
              keyboardRef={keyboard}
              onChange={(input: string) => {
                props.handleChange(input);
                props.setFieldValue("id", input);
              }}
            />
          )}
        </form>
      )}
    </Formik>
  );
};
