import React from "react";
type IssueProps = {
  issueType: string;
};

export const IssueTypeHeader: React.FC<IssueProps> = ({ issueType }) => {
  return (
    <div className="mx-auto my-auto px-3 py-4 text-center text-white text-6xl border-2 rounded-lg">
      {issueType}
    </div>
  );
};
