import { MachineApi } from "../api/machines";
import { Machine, Page, Result } from "../types";
import { AxiosResponse } from "axios";
import { Handler } from "./result";

export interface IMachineService {
  fetchAll(
    line_id: number,
    page: number,
    page_size: number
  ): Promise<Result<Page<Machine>>>;
  fetchById(machine_id: number): Promise<Result<Machine>>;
}

export const MachineService: IMachineService = {
  async fetchAll(
    line_id: number,
    page: number,
    page_size: number
  ): Promise<Result<Page<Machine>>> {
    return await MachineApi.fetchAll(line_id, page, page_size)
      .then((resp: AxiosResponse<Page<Machine>>) =>
        Handler.SuccessResult<Page<Machine>>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async fetchById(machine_id: number): Promise<Result<Machine>> {
    return await MachineApi.fetchById(machine_id)
      .then((resp: AxiosResponse<Machine>) =>
        Handler.SuccessResult<Machine>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
};
