import { LineLogin } from "./pages/LoginPage";
import { User } from "./types";

export const DISPLAY_TIME_FORMAT = "HH:mm";
export const DISPLAY_DATE_FORMAT = "DD-MM-YYYY";
export const DISPLAY_OPEN_DATE_FORMAT = "DD-MM HH:mm";
export const options: Array<LineLogin> = [
  {
    name: "SMT 1",
    id: 11,
  },
  {
    name: "SMT 2",
    id: 12,
  },
  {
    name: "SMT 3",
    id: 1,
  },
  {
    name: "SMT 4",
    id: 2,
  },
  {
    name: "SMT 5",
    id: 3,
  },
  {
    name: "SMT 6",
    id: 13,
  },
  {
    name: "SMT 7",
    id: 4,
  },
  {
    name: "CELL 1",
    id: 14,
  },
  {
    name: "CELL 2",
    id: 15,
  },
  {
    name: "CELL 3",
    id: 5,
  },
  {
    name: "CELL 4",
    id: 6,
  },
  {
    name: "CELL 5",
    id: 7,
  },
  {
    name: "CELL 6",
    id: 8,
  },
  {
    name: "CELL 7",
    id: 16,
  },
  {
    name: "CELL 8",
    id: 17,
  },
  {
    name: "CELL 9",
    id: 18,
  },
  {
    name: "CELL 10",
    id: 19,
  },
  {
    name: "CELL 12",
    id: 20,
  },
  {
    name: "CELL 13",
    id: 26,
  },
  {
    name: "CELL 14",
    id: 21,
  },
  {
    name: "CELL 15",
    id: 22,
  },
  {
    name: "CELL 16",
    id: 9,
  },
  {
    name: "CELL 17",
    id: 23,
  },
  {
    name: "CELL 18",
    id: 10,
  },
  {
    name: "Sounders Shared",
    id: 24,
  },
  {
    name: "Offline",
    id: 25,
  },
];

export const onCallEngineer: User = {
  id_: -1,
  first_name: "On Call",
  last_name: "Engineer",
  email: "",
  username: "",
  is_admin: false,
  phone_number: "",
  card_id: "",
  roles: [],
};
//1822569284
//3744224836
//1709966030
//0088399726
//0088394046
//jjmyrn
// component : 16247252
