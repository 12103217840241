import React, { MutableRefObject } from "react";
import Keyboard, {
  KeyboardButtonTheme,
  KeyboardLayoutObject,
  KeyboardReactInterface,
} from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

type KeyboardProps = {
  //@ts-ignore
  keyboardRef: MutableRefObject<KeyboardReactInterface | null>;
  onChange: (input: string) => void;
  onKeyPress?: (button: string) => void;
  theme?: string;
  layout?: KeyboardLayoutObject;
  layoutName?: string;
  buttonTheme?: KeyboardButtonTheme[];
};
export const KeyboardWrapper: React.FC<KeyboardProps> = ({
  keyboardRef,
  onChange,
  theme,
  layout,
  layoutName,
  buttonTheme,
}) => {
  const options = {
    layout: {
      default: ["1 2 3", "4 5 6", "7 8 9", "{clr} 0 {bksp}"],
    },
    mergeDisplay: true,
    display: {
      "{bksp}": "избриши",
      "{clr}": "бриши сѐ",
    },
    buttonTheme: [
      {
        class: "bg-red-500",
        buttons: "1",
      },
    ],
    theme: "hg-theme-default myTheme1",
  };

  const onKeyPress = (button: string) => {
    if (button === "{enter}") {
      //@ts-ignore
      alert(keyboardRef.current?.getInput());
      keyboardRef.current?.clearInput();
      onChange?.("");
    }
  };
  return (
    <div className="mt-3">
      <Keyboard
        {...options}
        keyboardRef={(r) => (keyboardRef.current = r)}
        //theme={theme}
        onKeyPress={onKeyPress}
        // layout={layout}
        layoutName={layoutName}
        onChange={onChange}
        buttonTheme={buttonTheme}
      />
    </div>
  );
};
