import React from "react";
import { Header } from "../Header/Header";
import { useLocation } from "react-router-dom";

export const Layout: React.FC = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;

 

  return (
    <>
      {pathname === "/login" ||
      pathname === "/login/user" ||
      pathname === "/reports/lines" ? (
        <></>
      ) : (
        <Header />
      )}
      <div
        className={`${
          pathname === "/reports/lines"
            ? "bg-black h-screen"
            : `pt-16 w-full h-screen relative ${
                pathname.startsWith("/login")
                  ? "object-cover bg-cover"
                  : pathname.startsWith("/open-new-ticket") ||
                    pathname.startsWith("/tickets")
                  ? "bg-black"
                  : "object-cover bg-cover bg-opacity-20"
              }`
        } `}
        style={{
          backgroundImage: pathname.startsWith("/login")
            ? "url(/loginImage.jpg)"
            : pathname.startsWith("/open-new-ticket") ||
              pathname === "/reports/lines" ||
              pathname.startsWith("/tickets/")
            ? ""
            : "url(/loginImage.jpg)",
          backgroundPosition: "right center",
        }}
        id="main"
      >
        <div
          className={`relative w-full ${
            pathname.startsWith("/open-new-ticket") ||
            pathname.startsWith("/tickets")
              ? ""
              : "h-full"
          }`}
        >
          <div
            className={`${
              !pathname.startsWith("/login") &&
              !pathname.startsWith("/open-new-ticket") &&
              !pathname.startsWith("/tickets/")
                ? "absolute inset-0 w-full h-full bg-black bg-opacity-80"
                : pathname.startsWith("/open-new-ticket") ||
                  pathname.startsWith("/tickets")
                ? "w-full bg-black"
                : ""
            }`}
          >
            {children}
          </div>
        </div>
      </div>
      

    </>
  );
};
