import { AxiosResponse } from "axios";
import { ChangePasswordRequest, CreateLoginRequest, CSVResponse, LoginResponse } from "../types";
import { instance } from "./axios";

export interface IAuthApi{
    login(login_req : CreateLoginRequest) : Promise<AxiosResponse<LoginResponse>>;
    change_password(change_password_req : ChangePasswordRequest) : Promise<AxiosResponse<CSVResponse>>; 
}

export const AuthApi : IAuthApi = {
  async login(login_req : CreateLoginRequest) : Promise<AxiosResponse<LoginResponse>>  {
      const route = "/auth/login";
      const formData = new FormData();
      formData.append("username",login_req.username.toLowerCase());
      formData.append("password",login_req.password);

      return await instance.post<LoginResponse>(route,formData);
  },
   async change_password(change_password_req : ChangePasswordRequest) : Promise<AxiosResponse<CSVResponse>>{
        const route = "/auth/change-password";

        return await instance.post<CSVResponse>(route,change_password_req);
    }
    
}