import React from "react";
import { ButtonComponent } from "../components/ButtonComponent/ButtonComponent";
import { IssueTypeHeader } from "../components/IssueTypeHeader/IssueTypeHeader";
import { useNavigate } from "react-router-dom";
import { MachineProblemTypeEnum, PriorityTypeEnum } from "../types";
import { useTicket } from "../TicketContext";
export const MachineInterruption: React.FC = () => {
  const navigate = useNavigate();

  const { dispatch } = useTicket();
  return (
    <div className="grid h-full">
      <IssueTypeHeader issueType="Тип на проблем" />
      <div className="mx-4 my-auto grid grid-cols-4">
        <ButtonComponent
          onClick={() => {
            dispatch({
              type: "SET_TICKET_TYPE",
              payload: MachineProblemTypeEnum.MACHINE_PROBLEM,
            });
            navigate("/machine-on-the-line");
          }}
          classes="py-8 rounded-md text-5xl mr-3"
          buttonName="Проблем со машина"
        />

        <ButtonComponent
          onClick={() => {
            dispatch({
              type: "SET_TICKET_TYPE",
              payload: MachineProblemTypeEnum.QUALITY_PROBLEM,
            });
            navigate("/machine-on-the-line");
          }}
          classes="py-8 rounded-md text-5xl mr-3"
          buttonName="Проблем со квалитет"
        />
        <ButtonComponent
          onClick={() => {
            dispatch({
              type: "SET_TICKET_TYPE",
              payload: MachineProblemTypeEnum.MAINTENANCE_PROBLEM,
            });
            dispatch({
              type: "SET_PRIORITY",
              payload: PriorityTypeEnum.PREVENTIVE_MAINTENANCE,
            });
            navigate("/open-new-ticket");
          }}
          classes="py-8 rounded-md text-5xl mr-3"
          buttonName="Превентивно одржување"
        />
        <ButtonComponent
          onClick={() => {
            navigate("/planned-action");
            dispatch({
              type: "SET_TICKET_TYPE",
              payload: MachineProblemTypeEnum.PLANNED_ACTION,
            });
          }}
          classes="py-8 rounded-md text-5xl "
          buttonName="Планирана акција"
        />
      </div>
      <div className="mx-8 mb-4 mt-auto flex justify-between">
        <ButtonComponent
          onClick={() => navigate("/")}
          classes="px-8  rounded-md pb-4 text-6xl"
          buttonName="Почетна"
        />
        <ButtonComponent
          onClick={() => navigate(-1)}
          classes="px-8 rounded-md pb-4 text-6xl"
          buttonName="Назад"
        />
      </div>
    </div>
  );
};
