import { Result, Page, Line, TimeSeries, User } from "../types";
import { LineApi } from "../api/lines";
import { Handler } from "./result";
import { AxiosResponse } from "axios";
export interface ILineService {
  fetchAll(page: number, page_size: number): Promise<Result<Page<Line>>>;
  fetchById(line_id: number): Promise<Result<Line>>;
  activateLine(line_id: number): Promise<Result<TimeSeries>>;
  inactivateLine(line_id: number): Promise<Result<TimeSeries>>;
  getUsersByLineAndRole(
    line_id: number,
    role_id: number
  ): Promise<Result<Array<User>>>;
}
export const LineService: ILineService = {
  async fetchAll(page: number, page_size: number): Promise<Result<Page<Line>>> {
    return await LineApi.fetchAll(page, page_size)
      .then((resp: AxiosResponse<Page<Line>>) =>
        Handler.SuccessResult<Page<Line>>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },

  async fetchById(line_id: number): Promise<Result<Line>> {
    return await LineApi.fetchById(line_id)
      .then((resp: AxiosResponse<Line>) =>
        Handler.SuccessResult<Line>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async activateLine(line_id: number): Promise<Result<TimeSeries>> {
    return await LineApi.activateLine(line_id)
      .then((resp) => Handler.SuccessResult(resp.data))
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async inactivateLine(line_id: number): Promise<Result<TimeSeries>> {
    return await LineApi.inactivateLine(line_id)
      .then((resp) => Handler.SuccessResult(resp.data))
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async getUsersByLineAndRole(
    line_id: number,
    role_id: number
  ): Promise<Result<Array<User>>> {
    return await LineApi.getUsersByLineAndRole(line_id, role_id)
      .then((resp) => Handler.SuccessResult(resp.data))
      .catch((err) => Handler.ErrorResult(err));
  },
};
