import React, { useEffect, useState } from "react";
import { MaintenanceTechnician } from "../components/NewTicket/MaintenanceTechnician";
import { ProductionLeader } from "../components/NewTicket/ProductionLeader";

import { MaintenanceSupervisor } from "../components/NewTicket/MaintenanceSupervisor";
import {
  AcceptTicketRequest,
  CloseTicketRequest,
  CreateTicketRequest,
  Machine,
  MachineProblemTypeEnum,
  ModalType,
  Page,
  Product,
  Result,
  Status,
  StatusEnum,
  Ticket,
  UpdateTicket,
  User,
} from "../types";
import { TicketService } from "../service/tickets";
import { TableDetail } from "../components/Table/TableDetail";
import { useTicket } from "../TicketContext";
import { AccountService } from "../service/accounts";
import { useSessionStorage } from "../hooks/useSessionStorage";
import { ProductService } from "../service/products";
import { useNavigate } from "react-router";
import moment from "moment";
import { UnpauseTicket } from "../components/NewTicket/UnpauseTicket";
import { Loader } from "../components/Loader/Loader";
import { MaintenanceProblem } from "../components/MaintenanceProblem/MaintenanceProblem";
import { Modal } from "../components/Modal/Modal";
import { LineService } from "../service/lines";
import { getEscalationRoleByTicketType } from "../utils";
import { SelectUsers } from "../components/Modal/SelectUsers";

export const OpenNewTicket: React.FC = () => {
  const ticketContext = useTicket();
  const dispatchTicket = ticketContext.dispatch;
  const state = ticketContext.state;
  const [user] = useSessionStorage("user");
  const navigate = useNavigate();
  const [products, setProducts] = useState<Array<Product>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [onCallEngineer, setOnCallEngineer] = useState<User>({} as User);
  const [open, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);
  const [usersToForward, setUsersToForward] = useState<Array<User>>([]);
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [forwardFrom, setForwardFrom] = useState<string>("");
  useEffect(() => {
    setIsLoading(true);
    AccountService.fetchByRole(23).then((resp: Result<Array<User>>) => {
      if (resp.status === Status.SUCCESS) {
        if (resp.value.length > 0) {
          setOnCallEngineer(resp.value[0]);
        }
        setIsLoading(false);
      }
    });
    LineService.getUsersByLineAndRole(
      parseInt(user.id_),
      getEscalationRoleByTicketType(user.event_config, state.ticketType)
    ).then((resp) => {
      if (resp.status === Status.SUCCESS) {
        setUsersToForward(resp.value);
      }
      setIsLoading(false);
    });
  }, [state.ticketType, user.event_config, user.id_]);

  const checkDezurenRender = () => {
    const now = moment();
    const startTime = moment().set({ hour: 16, minute: 30 });
    const endTime = moment().set({ hour: 8, minute: 30 });

    return now.isAfter(startTime) || now.isBefore(endTime);
  };

  useEffect(() => {
    setIsLoading(true);
    ProductService.fetchAll(user.id_, 0, 10).then(
      (result: Result<Page<Product>>) => {
        if (result.status === Status.SUCCESS) {
          if (result.value.content.length === 0) {
            setIsOpen(true);
            setModalProps({
              title: "Настана грешка",
              subtitle: "Не можете да отворите нов тикет без продукти",
            });
          } else {
            setProducts(result.value.content);
            setIsLoading(false);
          }
        }
      }
    );
  }, [user.id_]);

  const handlePrioritize = async (id: number, card_id: string) => {
    const user: Result<User> = await AccountService.fetchByCardId(card_id);
    if (user.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }

    const priorityTicket: AcceptTicketRequest = {
      card_id,
    };

    const resp: Result<Ticket> = await TicketService.prioritizeTicket(
      id,
      priorityTicket
    );
    if (resp.status === Status.SUCCESS) {
      window.location.reload();
    } else if (resp.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${resp.message}`,
      });
    }
  };
  const handlePause = async (id: number, card_id: string) => {
    const user: Result<User> = await AccountService.fetchByCardId(card_id);
    if (user.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }

    const pauseTicket: AcceptTicketRequest = {
      card_id,
    };

    const resp: Result<Ticket> = await TicketService.pauseTicket(
      id,
      pauseTicket
    );
    if (resp.status === Status.SUCCESS) {
      window.location.reload();
    }
  };
  const handleSubmit = async (
    ticket: CreateTicketRequest,
    id: string,
    product: string
  ) => {
    const user: Result<User> = await AccountService.fetchByCardId(id);
    if (user.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }

    const result: Result<Ticket> = await TicketService.createTicket(ticket);

    if (result.status === Status.SUCCESS) {
      dispatchTicket({
        type: "SET_OPERATOR_ID",
        payload: user.value,
      });
      dispatchTicket({
        type: "OPEN_BUTTON_PRODUCTION_LEADER",
      });
      dispatchTicket({
        type: "SET_OPENED_AT",
        payload: moment(Date.now()).format("YYYY-MM-DD[T]HH:mm:ss"),
      });
      dispatchTicket({
        type: "SET_PRODUCT",
        payload: products.find((p: Product) => product === String(p.id_)),
      });
      dispatchTicket({
        type: "SET_STATUS",
        payload: StatusEnum.OPEN,
      });
      dispatchTicket({
        type: "SET_TICKET_ID",
        payload: result.value.id_,
      });
      navigate(`/open-new-ticket/${result.value.id_}`);
    } else if (result.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${result.message}`,
      });
    }
  };

  const handleAccept = async (
    card_id: string,
    isTechnician: boolean,
    ticket_id: number,
    ticket: AcceptTicketRequest
  ) => {
    const user: Result<User> = await AccountService.fetchByCardId(card_id);
    if (user.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }

    const result: Result<Ticket> = await TicketService.acceptTicket(
      ticket_id,
      ticket
    );
    if (result.status === Status.SUCCESS) {
      if (isTechnician === true) {
        dispatchTicket({
          type: "SET_MAINTENANCE_TECHNICIAN",
          payload: user.value,
        });
        dispatchTicket({
          type: "RECEIVE_BUTTON_MAIN_TECH",
        });
        dispatchTicket({
          type: "SET_L1_RECEIVED_AT",
          payload: moment(Date.now()).format("YYYY-MM-DD[T]HH:mm:ss"),
        });
        dispatchTicket({
          type: "SET_STATUS",
          payload: StatusEnum.PENDING,
        });
      } else {
        dispatchTicket({
          type: "SET_MAINTENANCE_SUPERVISOR",
          payload: user.value,
        });
        dispatchTicket({
          type: "RECEIVE_BUTTON_MAIN_SUPERVISOR",
        });
        dispatchTicket({
          type: "SET_L2_RECEIVED_AT",
          payload: moment(Date.now()).format("YYYY-MM-DD[T]HH:mm:ss"),
        });
        dispatchTicket({
          type: "SET_STATUS",
          payload: StatusEnum.PENDING,
        });
      }
      window.location.reload();
    } else if (result.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${result.message}`,
      });
      return;
    }
  };

  const handleClose = async (
    card_id: string,
    isTechnician: boolean,
    ticket_id: number,
    ticket: CloseTicketRequest
  ) => {
    setIsLoading(true);
    const user: Result<User> = await AccountService.fetchByCardId(card_id);
    if (user.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }
    const result: Result<Ticket> = await TicketService.closeTicket(
      ticket_id,
      ticket
    );
    if (result.status === Status.SUCCESS) {
      if (isTechnician === true) {
        dispatchTicket({
          type: "SET_CLOSED_AT",
          payload: moment(Date.now()).format("YYYY-MM-DD[T]HH:mm:ss"),
        });
        dispatchTicket({
          type: "CLOSE_BUTTON_MAIN_TECH",
        });
        dispatchTicket({
          type: "SET_STATUS",
          payload: StatusEnum.CLOSED,
        });
      } else {
        dispatchTicket({
          type: "SET_CLOSED_BY",
          payload: user.value,
        });
        dispatchTicket({
          type: "CLOSE_BUTTON_MAIN_SUPERVISOR",
        });
        dispatchTicket({
          type: "SET_CLOSED_AT",
          payload: moment(Date.now()).format("YYYY-MM-DD[T]HH:mm:ss"),
        });
        dispatchTicket({
          type: "SET_STATUS",
          payload: StatusEnum.CLOSED,
        });
      }
      setIsLoading(false);
      navigate(`/tickets/${ticket_id}`);
    } else if (result.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${result.message}`,
      });
      setIsLoading(false);
    }
  };

  const handleForwardToOnCallEngineeer = async () => {
    const ticketId = ticketContext.state.ticket_id;
    const fromUser = await AccountService.fetchByCardId(forwardFrom);
    if (fromUser.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${fromUser.message}`,
      });
      return;
    }
    const res = await TicketService.forwardToOnCallEngineer(
      ticketId,
      forwardFrom
    );
    if (res.status === Status.SUCCESS) {
      dispatchTicket({
        type: "SET_MAINTENANCE_TECHNICIAN",
        payload: fromUser.value,
      });
      dispatchTicket({
        type: "FORWARD_BUTTON_MAIN_TECH",
      });
      dispatchTicket({
        type: "SET_L1_FORWARDED_AT",
        payload: moment(Date.now()).format("YYYY-MM-DD[T]HH:mm:ss"),
      });
      dispatchTicket({
        type: "SET_STATUS",
        payload: StatusEnum.FORWARDED,
      });
      // window.location.reload();
      setOpenSelectModal(false);
    }
  };

  const handleForward = async (toCardId: string) => {
    const ticketId = ticketContext.state.ticket_id;
    const fromUser = await AccountService.fetchByCardId(forwardFrom);
    if (fromUser.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${fromUser.message}`,
      });
      return;
    }
    console.log(fromUser);
    const toUser = await AccountService.fetchByCardId(toCardId);
    if (toUser.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${toUser.message}`,
      });
      return;
    }
    console.log(toUser);
    const result: Result<Ticket> = await TicketService.forwardTicket(
      ticketId,
      forwardFrom,
      toCardId
    );
    if (result.status === Status.SUCCESS) {
      dispatchTicket({
        type: "SET_MAINTENANCE_TECHNICIAN",
        payload: fromUser.value,
      });
      dispatchTicket({
        type: "FORWARD_BUTTON_MAIN_TECH",
      });
      dispatchTicket({
        type: "SET_L1_FORWARDED_AT",
        payload: moment(Date.now()).format("YYYY-MM-DD[T]HH:mm:ss"),
      });
      dispatchTicket({
        type: "SET_STATUS",
        payload: StatusEnum.FORWARDED,
      });
      // window.location.reload();
      setOpenSelectModal(false);
    } else if (result.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${result.message}`,
      });
    }
  };

  const handleUnpause = async (card_id: string) => {
    const resp: Result<User> = await AccountService.fetchByCardId(card_id);
    if (resp.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${resp.message}`,
      });
      return;
    }

    let status: StatusEnum = StatusEnum.ON_HOLD;

    if (!state.l1ReceivedAt) {
      status = StatusEnum.OPEN;
    }
    if (state.l1ReceivedAt && !state.l1ForwardedAt) {
      status = StatusEnum.PENDING;
    }
    if (state.l1ReceivedAt && state.l1ForwardedAt && !state.l2ReceivedAt) {
      status = StatusEnum.FORWARDED;
    }
    if (state.l1ReceivedAt && state.l2ReceivedAt) {
      status = StatusEnum.PENDING;
    }
    const updateTicket: UpdateTicket = {
      status,
    };
    TicketService.updateTicket(parseInt(state.ticket_id), updateTicket).then(
      (resp: Result<Ticket>) => {
        if (resp.status === Status.SUCCESS) {
          window.location.reload();
        } else if (resp.status === Status.ERROR) {
          setIsOpen(true);
          setModalProps({
            title: "Настана грешка",
            subtitle: `${resp.message}`,
          });
        }
      }
    );
  };

  const handleOpenAndAcceptTicket = async (
    card_id: string,
    ticket: CreateTicketRequest
  ) => {
    const user: Result<User> = await AccountService.fetchByCardId(card_id);
    if (user.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }
    const createTicketResult: Result<Ticket> = await TicketService.createTicket(
      ticket
    );
    if (createTicketResult.status === Status.SUCCESS) {
      const acceptTicketResult = await TicketService.acceptTicket(
        createTicketResult.value.id_,
        { card_id }
      );
      if (acceptTicketResult.status === Status.SUCCESS) {
        dispatchTicket({
          type: "SET_OPERATOR_ID",
          payload: user.value,
        });
        dispatchTicket({
          type: "OPEN_BUTTON_PRODUCTION_LEADER",
        });
        dispatchTicket({
          type: "SET_OPENED_AT",
          payload: moment(Date.now()).format("YYYY-MM-DD[T]HH:mm:ss"),
        });
        dispatchTicket({
          type: "SET_STATUS",
          payload: StatusEnum.PENDING,
        });
        dispatchTicket({
          type: "SET_TICKET_ID",
          payload: createTicketResult.value.id_,
        });
        navigate(`/open-new-ticket/${createTicketResult.value.id_}`);
      } else if (acceptTicketResult.status === Status.ERROR) {
        setIsOpen(true);
        setModalProps({
          title: "Настана грешка",
          subtitle: `${acceptTicketResult.message}`,
        });
      }
    } else if (createTicketResult.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${createTicketResult.message}`,
      });
    }
  };

  const { dispatch } = useTicket();
  const [isLine] = useSessionStorage("isLine");
  const handleNavigate = () => {
    return isLine ? "/" : "/user";
  };
  const handleMachineUrl = () => {
    return state.machines.find((m: Machine) => m.image);
  };

  return (
    <>
      <div className="grid">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <button
              className="mx-auto mt-4 rounded bg-aptiv-orange-500 py-2 px-4 font-bold uppercase text-white hover:bg-aptiv-orange-700"
              onClick={() => {
                dispatch({
                  type: "REFRESH_STATE",
                });
                navigate(handleNavigate());
              }}
            >
              Почетна
            </button>
            <div className="mt-4 flex">
              <div className="ml-4 w-1/3">
                <div className="flex justify-center">
                  {handleMachineUrl() !== undefined ? (
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_BASE_URL}${
                        handleMachineUrl().image
                      }`}
                      width={250}
                      height={250}
                      alt="placeholder"
                    />
                  ) : (
                    <img
                      src="https://images.unsplash.com/photo-1563968743333-044cef800494?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1058&q=80"
                      width={250}
                      height={250}
                      alt="placeholder"
                    />
                  )}
                </div>
                {state.ticketType ===
                  MachineProblemTypeEnum.MAINTENANCE_PROBLEM && (
                  <div className="mt-2 rounded-lg border">
                    <MaintenanceProblem
                      onSubmit={handleOpenAndAcceptTicket}
                      onClose={handleClose}
                    />
                  </div>
                )}
                {!state.productionLeaderButton.isClicked &&
                  state.status !== StatusEnum.ON_HOLD &&
                  state.ticketType !==
                    MachineProblemTypeEnum.MAINTENANCE_PROBLEM && (
                    <div className="mt-2 rounded-lg border">
                      <ProductionLeader
                        onSubmit={handleSubmit}
                        products={products}
                      />
                    </div>
                  )}
                {state.productionLeaderButton.isClicked &&
                  !state.maintenanceTehnicianButtons[2].isClicked &&
                  state.status !== StatusEnum.ON_HOLD &&
                  state.ticketType !==
                    MachineProblemTypeEnum.MAINTENANCE_PROBLEM && (
                    <div className="mt-2 rounded-lg border">
                      <MaintenanceTechnician
                        onAccept={handleAccept}
                        onClose={handleClose}
                        onPause={handlePause}
                        onPrioritize={handlePrioritize}
                        setOpenSelectModal={setOpenSelectModal}
                        setForwardFrom={setForwardFrom}
                      />
                    </div>
                  )}

                {state.maintenanceTehnicianButtons[2].isClicked &&
                  state.status !== StatusEnum.ON_HOLD &&
                  state.ticketType !==
                    MachineProblemTypeEnum.MAINTENANCE_PROBLEM && (
                    <div className="mt-2 rounded-lg border">
                      <MaintenanceSupervisor
                        onAccept={handleAccept}
                        onClose={handleClose}
                        onPause={handlePause}
                        onPrioritize={handlePrioritize}
                      />
                    </div>
                  )}
                {state.status === StatusEnum.ON_HOLD && (
                  <div className="mt-2 rounded-lg border">
                    <UnpauseTicket onSubmit={handleUnpause} />
                  </div>
                )}
                {onCallEngineer && checkDezurenRender() && (
                  <div className="mx-auto mt-4 flex justify-center rounded-lg border-2 bg-black p-5 text-xl font-bold text-white">
                    ДЕЖУРЕН ИНЖЕНЕР: {onCallEngineer.first_name}{" "}
                    {onCallEngineer.last_name}
                  </div>
                )}
              </div>
              <div className="ml-4 w-2/3">
                <TableDetail title={"Информации за тикетот"} />
              </div>
            </div>
          </>
        )}
      </div>

      <Modal open={open} setOpen={setIsOpen} modalProps={modalProps} />
      <SelectUsers
        open={openSelectModal}
        setOpen={setOpenSelectModal}
        users={usersToForward}
        handleForward={handleForward}
        handleForwardToOnCallEngineer={handleForwardToOnCallEngineeer}
      />
    </>
  );
};
