export const TRANSLATIONS_MKD = {
  loginPage: {
    loginLine: "Најава линија",
    loginUser: "Најава корисник",
    line: "Линија",
    password: "Лозинка",
    login: "Најави се",
    selectLineError: "Селектирајте линија",
    requiredField: "Задолжително поле",
    wrongPassword: "Погрешна лозинка",
  },
  homePage: {
    createNewTicket: "Креирај нов тикет",
    openedTickets: "Отворени тикети",
    closedTickets: "Затворени тикети",
    deactivate: "Деактивирај",
  },
  createTicket: {
    reasonForStoppage: "Причина за прекин",
    machineInterruption: "Прекин со машината/процесот",
    materialIssue: "Проблем со материјали",
  },
  userHomePage: {
    reports: "Извештаи",
    input: "Влез",
  },
  reportsPage: {
    userReport: "Извештај за корисници",
    lineReport: "Извештај за линии",
    ticketReport: "Извештај за тикети",
    machineReport: "Извештај за машини",
  },
  common: {
    input: "Влез",
    error: "Настана грешка",
    logOut: "Одјави се",
    home: "Почетна",
    back: "Назад",
  },
};
