import { Field } from "formik";
import React from "react";

type Props = {
  machineName: string;
  checkedValues: Array<string>;
  machineId: number;
};

export const IssueCheckbox: React.FC<Props> = ({
  machineName,
  checkedValues,
  machineId,
}) => {
  const getBackgroundColor = () => {
    if (checkedValues.includes(String(machineId))) {
      return "bg-aptiv-light-gray";
    }
    return "bg-aptiv-dark-gray-800 ";
  };
  return (
    <label
      className={
        "block h-32 w-[30rem]  m-3 text-center py-3 " + getBackgroundColor()
      }
      key={machineId}
    >
      <div className="text-3xl">{machineName}</div>
      <Field
        type="checkbox"
        name="checked"
        value={String(machineId)}
        className="mt-4 w-6 h-6"
      />
    </label>
  );
};
