import { Page, Result, User } from "../types";
import { AccountApi } from "../api/accounts";
import { AxiosResponse } from "axios";
import { Handler } from "./result";

export interface IAccountService {
  fetchAll(page: number, page_size: number): Promise<Result<Page<User>>>;
  fetchById(user_id: number): Promise<Result<User>>;
  fetchByCardId(card_id: string): Promise<Result<User>>;
  fetchByRole(role_id: number): Promise<Result<Array<User>>>;
  fetchByUsername(username: string): Promise<Result<User>>;
}

export const AccountService: IAccountService = {
  async fetchAll(page: number, page_size: number): Promise<Result<Page<User>>> {
    return await AccountApi.fetchAll(page, page_size)
      .then((resp: AxiosResponse<Page<User>>) =>
        Handler.SuccessResult(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async fetchById(user_id: number): Promise<Result<User>> {
    return await AccountApi.fetchById(user_id)
      .then((resp: AxiosResponse<User>) => Handler.SuccessResult(resp.data))
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async fetchByCardId(card_id: string): Promise<Result<User>> {
    return await AccountApi.fetchByCardId(card_id)
      .then((resp: AxiosResponse<User>) => Handler.SuccessResult(resp.data))
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async fetchByRole(role_id: number): Promise<Result<Array<User>>> {
    return await AccountApi.fetchByRole(role_id)
      .then((resp: AxiosResponse<Array<User>>) =>
        Handler.SuccessResult(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async fetchByUsername(username: string): Promise<Result<User>> {
    return await AccountApi.fetchByUsername(username)
      .then((resp: AxiosResponse<User>) => Handler.SuccessResult(resp.data))
      .catch((reason) => Handler.ErrorResult(reason));
  },
};
