import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { TicketService } from "../service/tickets";
import {
  Page,
  PriorityTypeEnum,
  Result,
  Status,
  StatusEnum,
  Ticket,
  TicketOrderBy,
} from "../types";
import {
  getMachineProblemType,
  getPriorityTypeWithoutNumbers,
  getStatus,
} from "../utils";
import { DISPLAY_OPEN_DATE_FORMAT } from "../const";

const getStatusColor = (priority: PriorityTypeEnum) => {
  switch (priority) {
    case PriorityTypeEnum.EFFICIENCY_REDUCTION:
      return "bg-pink-500";
    case PriorityTypeEnum.INACTIVE:
      return "bg-blue-500";
    case PriorityTypeEnum.NON_CRITICAL:
      return "bg-yellow-400";
    case PriorityTypeEnum.POTENTIAL_HALT:
      return "bg-yellow-600";
    case PriorityTypeEnum.TOTAL_HALT:
      return "bg-aptiv-orange";
    case PriorityTypeEnum.PLANNED_ACTION:
      return "bg-aptiv-kiwi-500";
    case PriorityTypeEnum.PREVENTIVE_MAINTENANCE:
      return `bg-emerald-500`;
    default:
      return "bg-black";
  }
};

const PAGE_NUMBER = 8;

export const LineReports: React.FC = () => {
  const [tickets, setTickets] = useState<Array<Ticket>>([]);
  const [page, setPage] = useState(0);
  // const [lineId, setLineId] = useState<number | undefined>(undefined);
  // const [lines, setLines] = useState<Array<Line>>([]);

  const fetchData = useCallback(() => {
    TicketService.fetchAllTickets(
      page,
      PAGE_NUMBER,
      [TicketOrderBy.PRIORITY, TicketOrderBy.STATUS],
      [
        StatusEnum.APPROVED,
        StatusEnum.FORWARDED,
        StatusEnum.IN_PROGRESS,
        StatusEnum.OPEN,
        StatusEnum.PENDING,
        StatusEnum.PRIORITY,
        StatusEnum.MATERIAL_NOT_AVAILABLE,
        StatusEnum.WAITING_FOR_APPROVAL,
        StatusEnum.ON_HOLD,
      ]
      //lineId
    ).then((resp: Result<Page<Ticket>>) => {
      if (resp.status === Status.SUCCESS) {
        setTickets(resp.value.content);
        if ((page + 1) * PAGE_NUMBER > resp.value.total) {
          setPage(0);
        } else {
          setPage((_prev) => _prev + 1);
        }
      }
    });
  }, [page]);
  useEffect(() => {
    if (tickets.length === 0) {
      TicketService.fetchAllTickets(
        0,
        PAGE_NUMBER,
        [TicketOrderBy.PRIORITY, TicketOrderBy.STATUS],
        [
          StatusEnum.APPROVED,
          StatusEnum.FORWARDED,
          StatusEnum.IN_PROGRESS,
          StatusEnum.OPEN,
          StatusEnum.PENDING,
          StatusEnum.PRIORITY,
          StatusEnum.MATERIAL_NOT_AVAILABLE,
          StatusEnum.WAITING_FOR_APPROVAL,
          StatusEnum.ON_HOLD,
        ]
        //lineId
      ).then((resp: Result<Page<Ticket>>) => {
        if (resp.status === Status.SUCCESS) {
          if (resp.value.content.length === 0) {
            setTimeout(() => {
              setTickets([]);
            }, 1000 * 10);
          } else {
            setTickets(resp.value.content);
            if ((page + 1) * PAGE_NUMBER > resp.value.total) {
              setPage(0);
            } else {
              setPage((_prev) => _prev + 1);
            }
          }
        }
      });
    } else {
      const interval = setInterval(fetchData, 1000 * 10);
      return () => clearInterval(interval);
    }
  }, [page, fetchData, tickets]);

  // const lineOptions = () => {
  //   return lines.map(({ id_, name }, key) => (
  //     <option value={id_} key={key} className="text-5xl">
  //       {name}
  //     </option>
  //   ));
  // };
  return (
    <div className="flex h-screen w-full flex-col overflow-x-hidden">
      <div className="overflow-x-hidden sm:-mx-6">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 ">
          {/* <select
              name="lineId"
              id="lineId"
              value={lineId}
              className="flex p-5 mx-auto mb-3 text-white border-2 rounded-lg bg-black text-5xl"
              onChange={(e) => setLineId(parseInt(e.target.value))}
            >
              <option value={undefined} className="text-5xl">Селектирајте тип на тикет</option>
              {lineOptions()}
            </select> */}
          <div className="overflow-hidden shadow">
            <table className="w-screen min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3 text-left text-4xl font-bold uppercase  text-black"
                  >
                    Линија
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-4xl font-bold uppercase  text-black"
                  >
                    Машини
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-4xl font-bold uppercase text-black"
                  >
                    Тип на тикет
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-4xl font-bold uppercase text-black"
                  >
                    Време на отварање
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-4xl font-bold uppercase text-black"
                  >
                    Приоритет
                  </th>
                  <th
                    scope="col"
                    className="py-3 text-left text-4xl font-bold uppercase tracking-wider text-black"
                  >
                    Статус
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {tickets.map((ticket) => (
                  <tr
                    key={ticket.id_}
                    className={`${getStatusColor(
                      ticket.priority!
                    )} h-[8rem] border-2 border-black`}
                  >
                    <td className=" max-w-sm truncate whitespace-nowrap py-4 text-4xl font-bold text-black">
                      {ticket.line?.name}
                    </td>
                    <td className=" max-w-sm truncate whitespace-nowrap py-4 text-4xl font-bold text-black">
                      {ticket.machines.length > 0
                        ? ticket.machines[0].name.slice(0, 11)
                        : "Нема машини"}
                    </td>
                    <td className=" max-w-sm py-4 pl-4 text-4xl font-bold text-black">
                      {getMachineProblemType(ticket.ticket_type)}
                    </td>
                    <td className=" max-w-sm truncate whitespace-nowrap py-4 text-4xl font-bold text-black">
                      {moment
                        .utc(ticket.created_at, "YYYY-MM-DD[T]HH:mm:ss")
                        .local()
                        .format(DISPLAY_OPEN_DATE_FORMAT)}
                    </td>
                    <td className=" max-w-sm py-4 text-4xl font-bold text-black">
                      {getPriorityTypeWithoutNumbers(ticket.priority!)}
                    </td>
                    <td className=" max-w-sm truncate whitespace-nowrap py-4 text-4xl font-bold text-black">
                      {getStatus(ticket.status)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
