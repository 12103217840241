import React, { useCallback, useEffect, useRef, useState } from "react";
import { MachineService } from "../service/machines";
import { Machine, ModalType, Page, Result, Status } from "../types";
import { MachineList } from "../components/Machine/Machines";
import { useSessionStorage } from "../hooks/useSessionStorage";
import { Modal } from "../components/Modal/Modal";

const options = {
  root: null,
  rootMargin: "0px",
  threshold: 1.0,
};

export const MachineOnTheLine: React.FC = () => {
  const [machines, setMachines] = useState<Array<Machine>>([]);
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const loader = useRef(null);
  const [user] = useSessionStorage("user");
  const [open, setIsOpen] = useState(false);

  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);
  const handleObserver = useCallback((entries: any) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((p) => p + 1);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
    //return () => observer.disconnect();
  }, [handleObserver]);

  useEffect(() => {
    setIsFetching(true);
    MachineService.fetchAll(user.id_, page, 8).then(
      (resp: Result<Page<Machine>>) => {
        if (resp.status === Status.SUCCESS) {
          setMachines((old_state: Array<Machine>) => [
            ...old_state,
            ...resp.value.content,
          ]);
        } else if (resp.status === Status.ERROR) {
          // If we already have machines, we don't want to show the error modal
          // since that means we have reached the end of the list
          if (machines.length === 0) {
            setIsOpen(true);
            setModalProps({
              title: "Настана грешка",
              subtitle: `${resp.message}`,
            });
          }
        }
        setIsFetching(false);
      }
    );
  }, [page, user.id_]);

  return (
    <>
      <MachineList loader={loader} machines={machines} />
      <Modal open={open} setOpen={setIsOpen} modalProps={modalProps} />
    </>
  );
};
