import React, { useRef } from "react";

import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../TextInput/TextInput";
import { TestButton } from "../TestButton/TestButton";
import { useTicket } from "../../TicketContext";
import { useNavigate } from "react-router-dom";
import { KeyboardWrapper } from "../Keyboard/KeyboardWrapper";
import { useKeyboard } from "../../hooks/useKeyboard";
import {
  AcceptTicketRequest,
  CloseTicketRequest,
  StatusEnum,
} from "../../types";
type MaintenanceSupervisorProp = {
  onAccept: (
    card_id: string,
    isTechnician: boolean,
    ticket_id: number,
    ticket: AcceptTicketRequest
  ) => void;
  onClose: (
    card_id: string,
    isTechnician: boolean,
    ticket_id: number,
    ticket: CloseTicketRequest
  ) => void;
  onPause: (ticket_id: number, card_id: string) => void;
  onPrioritize: (ticket_id: number, card_id: string) => void;
};
const MaintenanceSupervisorSchema = Yup.object().shape({
  id: Yup.string().required("Задолжително поле"),
});
export const MaintenanceSupervisor: React.FC<MaintenanceSupervisorProp> = ({
  onAccept,
  onPause,
  onPrioritize,
}) => {
  const navigate = useNavigate();
  const ticketContext = useTicket();
  const dispatchTicket = ticketContext.dispatch;
  const state = ticketContext.state;
  const keyboard = useRef(null);
  const [isVisible, toggleVisible] = useKeyboard(false);
  return (
    <Formik
      initialValues={{ id: "" }}
      onSubmit={(values, actions) => {
        dispatchTicket({
          type: "SET_MAINTENANCE_SUPERVISOR",
          payload: values.id,
        });

        const acceptTicket: AcceptTicketRequest = {
          card_id: values.id,
        };

        if (
          !state.maintenanceSupervisorButtons[0].disabled &&
          state.maintenanceSupervisorButtons[1].disabled
        ) {
          onAccept(
            values.id,
            false,
            ticketContext.state.ticket_id,
            acceptTicket
          );
          actions.resetForm();
          actions.validateForm();
        }
      }}
      validationSchema={MaintenanceSupervisorSchema}
    >
      {(props) => (
        <Form
          className="mt-5 w-full"
          onSubmit={(e) => e.preventDefault()}
          action="return false;"
        >
          <label
            htmlFor="name"
            className="block ml-px pl-4 text-center text-white text-xl font-medium"
          >
            Супервизор
          </label>
          <div className="flex justify-center mt-1">
            <TextInput
              type="text"
              name="id"
              id="id"
              autoFocus={true}
              placeholder="id"
              onClick={toggleVisible}
            ></TextInput>
          </div>
          <div className="grid grid-cols-2">
            <TestButton
              type="button"
              className={
                "py-4  mt-3  rounded-md ml-2 mr-2 mb-2 text-3xl " +
                state.maintenanceSupervisorButtons[0].color
              }
              disabled={state.maintenanceSupervisorButtons[0].disabled}
              onClick={() => {
                props.handleSubmit();
              }}
            >
              Прими тикет
            </TestButton>
            <TestButton
              type="button"
              className={
                "py-4  mt-3  rounded-md ml-2 mr-2 mb-2 text-3xl " +
                state.maintenanceSupervisorButtons[1].color
              }
              onClick={() => {
                dispatchTicket({
                  type: "SET_CLOSED_BY",
                  payload: props.values.id,
                });
                navigate("/ticket-problem-description");
              }}
            >
              Затвори тикет
            </TestButton>
          </div>
          {state.status !== StatusEnum.ON_HOLD && (
            <div className="flex justify-between mt-3 space-x-2">
              <button
                className="flex justify-center align-middle my-auto py-8 w-1/2 text-white text-xl font-bold bg-aptiv-orange rounded-md"
                type="button"
                onClick={() => onPause(state.ticket_id, props.values.id)}
              >
                Паузирај
              </button>
              {state.status !== StatusEnum.PRIORITY && (
                <button
                  type="button"
                  className="flex justify-center align-middle my-auto py-8 w-1/2 text-white text-xl font-bold bg-aptiv-orange rounded-md"
                  onClick={() => onPrioritize(state.ticket_id, props.values.id)}
                >
                  Приоритет
                </button>
              )}
            </div>
          )}
          {isVisible && (
            <KeyboardWrapper
              keyboardRef={keyboard}
              onChange={(input: string) => {
                props.handleChange(input);
                props.setFieldValue("id", input);
              }}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};
