import { AxiosResponse } from "axios";
import { instance } from "./axios";
import { CreateReasonRequest, Page, Reason } from "../types";

export interface IReasonApi {
  fetchAll(
    page: number,
    page_size: number
  ): Promise<AxiosResponse<Page<Reason>>>;
  fetchById(reason_id: number): Promise<AxiosResponse<Reason>>;
  createReason(reason: CreateReasonRequest): Promise<AxiosResponse<Reason>>;
  delete(reason_id: number): Promise<AxiosResponse<string>>;
}

export const ReasonApi: IReasonApi = {
  async fetchAll(
    page: number,
    page_size: number
  ): Promise<AxiosResponse<Page<Reason>>> {
    const route = "/reasons/";
    return await instance.get<Page<Reason>>(route, {
      params: { page, page_size },
    });
  },
  async fetchById(reason_id: number): Promise<AxiosResponse<Reason>> {
    const route = `/components/${reason_id}`;
    return await instance.get<Reason>(route);
  },
  async createReason(
    reason: CreateReasonRequest
  ): Promise<AxiosResponse<Reason>> {
    const route = "/reasons/";
    return await instance.post<Reason>(route, reason);
  },
  async delete(reason_id: number): Promise<AxiosResponse<string>> {
    const route = `/reasons/${reason_id}`;
    return await instance.delete<string>(route);
  },
};
