import React from "react";

type LoginHeaderProp = {
  typeLogin: string;
};

export const LoginHeader: React.FC<LoginHeaderProp> = ({ typeLogin }) => {
  return (
    <div className="pb-5 pt-8 text-center text-5xl text-white">{typeLogin}</div>
  );
};
