import React, { useRef, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { TextInput } from "../TextInput/TextInput";
import * as Yup from "yup";
import { useKeyboard } from "../../hooks/useKeyboard";
import Keyboard from "react-simple-keyboard";
import { inputValidClass } from "../../base";
import { LineLogin } from "../../pages/LoginPage";
import { useTranslation } from "react-i18next";
type LoginFormProp = {
  placeHolderText: string;
  keyboardOpen: boolean;
  setKeyboardOpen: () => void;
  onSubmit: (id: string | undefined, password: string) => any;
  isLine: boolean;
  lines: Array<LineLogin>;
};

const layout = {
  default: [
    "1 2 3 4 5 6 7 8 9 0 - = {bksp}",
    "{tab} q w e r t y u i o p [ ] \\",
    "{lock} a s d f g h j k l ; ' {enter}",
    "{shift} z x c v b n m , . / {shift}",
  ],
  shift: [
    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
    "{tab} Q W E R T Y U I O P { } |",
    '{lock} A S D F G H J K L : " {enter}',
    "{shift} Z X C V B N M < > ? {shift}",
  ],
};

export const LoginForm: React.FC<LoginFormProp> = ({
  placeHolderText,
  keyboardOpen,
  setKeyboardOpen,
  onSubmit,
  isLine,
  lines,
}) => {
  const { t } = useTranslation();
  const validationErrorMessage = () => {
    return isLine
      ? t("loginPage.selectLineError")
      : t("loginPage.requiredField");
  };
  const LoginSchema = Yup.object().shape({
    id: Yup.string().required(`${validationErrorMessage()}`),
    password: Yup.string().required(t("loginPage.requiredField")),
  });
  const [isVisible, toggleVisible] = useKeyboard(keyboardOpen);
  const keyboard = useRef(null);
  const [inputName, setInputName] = useState("default");
  const [layoutName, setLayoutName] = useState("default");
  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  const handleKeyboardStatusChange = () => {
    setKeyboardOpen();
    toggleVisible();
  };

  const onKeyPress = (button: string) => {
    //console.log(inputs);
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  return (
    <Formik
      initialValues={{ id: "", password: "" }}
      onSubmit={(values) => {
        onSubmit(values.id, values.password);
      }}
      validationSchema={LoginSchema}
    >
      {(props) => (
        <Form
          className="mt-5 w-96"
          onSubmit={(e) => e.preventDefault()}
          action="return false;"
          autoComplete="off"
        >
          <div className="">
            <div>
              {isLine ? (
                <>
                  <label
                    htmlFor={"id"}
                    className="block text-center text-xl font-medium text-white sm:mt-px sm:pb-2"
                  >
                    {t("loginPage.line")}
                  </label>
                  <Field
                    as="select"
                    name="id"
                    autoFocus={true}
                    size="small"
                    className={inputValidClass + " px-12 pb-5 pt-5 text-center"}
                  >
                    <option value={""}>{t("loginPage.selectLineError")}</option>
                    {lines.map((l: LineLogin) => (
                      <option
                        value={String(l.id)}
                        key={l.id}
                        className="text-lg"
                      >
                        {l.name}
                      </option>
                    ))}
                  </Field>
                  <div className="mb-5 mt-2 h-2 text-center">
                    <ErrorMessage name="id">
                      {(errorMessage) => (
                        <span className="font-regular text-xl text-red-600">
                          {errorMessage}
                        </span>
                      )}
                    </ErrorMessage>
                  </div>

                  <TextInput
                    type="password"
                    id="password"
                    placeholder={t("loginPage.password")}
                    label={t("loginPage.password")}
                    name="password"
                    onClick={handleKeyboardStatusChange}
                    setInputName={setInputName}
                  />
                </>
              ) : (
                <>
                  <TextInput
                    id={"id"}
                    name={"id"}
                    type={"text"}
                    label={placeHolderText}
                    placeholder={placeHolderText}
                    onClick={handleKeyboardStatusChange}
                    setInputName={setInputName}
                  />
                  <TextInput
                    type="password"
                    id="password"
                    placeholder={t("loginPage.password")}
                    label={t("loginPage.password")}
                    name="password"
                    onClick={handleKeyboardStatusChange}
                    setInputName={setInputName}
                  />
                </>
              )}
              <button
                type="button"
                className="w-full justify-center rounded bg-aptiv-orange py-6 text-white"
                onClick={() => props.handleSubmit()}
              >
                {t("loginPage.login")}
              </button>
            </div>
            {isVisible && (
              <Keyboard
                keyboardRef={(r) => (keyboard.current = r)}
                inputName={inputName}
                onChangeAll={(inputs: any) => {
                  var id = "";

                  if (!inputs.id) {
                    if (!props.values.id) {
                      id = "";
                    } else {
                      id = props.values.id;
                    }
                  } else {
                    id = inputs.id;
                  }

                  props.setFieldValue("id", id);
                  props.setFieldValue("password", inputs.password);
                  //onChangeAll(inputs);
                }}
                layout={layout}
                theme="hg-theme-default myTheme2"
                onKeyPress={onKeyPress}
                layoutName={layoutName}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
