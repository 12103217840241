import React from "react";
import { StatusEnum, Ticket } from "../../types";
import { useNavigate } from "react-router";
import moment from "moment";
import { DISPLAY_OPEN_DATE_FORMAT } from "../../const";
import {
  getStatus,
  getMachineProblemType,
  navigateTicketTo,
} from "../../utils";
import { useSessionStorage } from "../../hooks/useSessionStorage";
type TicketsProps = {
  tickets: Array<Ticket>;
  loader: any;
  isLoading: boolean;
};

export const getStatusColor = (status: StatusEnum) => {
  const classes =
    "inline-flex items-center px-2.5 py-0.5 rounded-full text-sm uppercase font-bold";
  switch (status) {
    case StatusEnum.PENDING:
      return `bg-yellow-100 text-yellow-800 ${classes}`;
    case StatusEnum.FORWARDED:
      return `bg-blue-100 text-blue-800 ${classes}`;
    case StatusEnum.OPEN:
      return `bg-red-100 text-red-800 ${classes}`;
    case StatusEnum.CLOSED:
      return `bg-red-100 text-red-800 ${classes}`;
    case StatusEnum.ON_HOLD:
      return `bg-green-100 text-red-600 ${classes}`;
    case StatusEnum.MATERIAL_NOT_AVAILABLE:
      return `bg-aptiv-kiwi-100 text-aptiv-kiwi-900 ${classes}`;
    case StatusEnum.PRIORITY:
      return `bg-emerald-200 text-emerald-700 ${classes}`;
    case StatusEnum.REJECTED:
      return `bg-rose-300 text-rose-700 ${classes}`;
    case StatusEnum.WAITING_FOR_APPROVAL:
      return `bg-purple-200 text-purple-700 ${classes}`;
    case StatusEnum.APPROVED:
      return `bg-lime-200 text-lime-700 ${classes}`;
    default:
      return "text-white";
  }
};

export const GridList: React.FC<TicketsProps> = ({
  tickets,
  loader,
  isLoading,
}) => {
  const navigate = useNavigate();
  const [user] = useSessionStorage("user");

  return (
    <>
      <div
        className="no-scrollbar grid grid-flow-col grid-rows-2 gap-5 overflow-x-auto"
        id="slider"
      >
        {tickets
          .filter((v, i, a) => a.findIndex((v2) => v.id_ === v2.id_) === i)
          .map((ticket, idx) => (
            <div
              key={idx}
              className="col-span-2 w-[32rem] cursor-pointer rounded-xl border-2 border-white text-white"
              onClick={() => navigate(navigateTicketTo(ticket))}
            >
              <div className="mt-2 flex justify-center">
                <div className="mr-1">Статус: </div>
                <div>
                  <span className={getStatusColor(ticket.status)}>
                    {getStatus(ticket.status)}
                  </span>
                </div>
              </div>
              <div className="ml-3 justify-between text-xl">
                <h3 className="truncate font-medium">
                  <span className="font-semibold">Тикет ИД</span>: {ticket.id_}
                </h3>
                <h3 className="truncate font-medium">
                  <span className="font-semibold">Линија</span>: {user.name}
                </h3>
                <h3 className="truncate font-medium">
                  <span className="font-semibold">Машини :</span>{" "}
                  {ticket.machines.map((m) => m.name).join(",")}
                </h3>
                {ticket.reason && (
                  <h3 className="truncate font-medium">
                    <span className="font-semibold">Причина за проблем: </span>
                    {ticket.reason.reason}
                  </h3>
                )}
                <h3 className="truncate font-medium">
                  <span className="font-semibold">Тип на проблем: </span>
                  {getMachineProblemType(ticket.ticket_type)}
                </h3>

                <h3 className="truncate font-medium">
                  <span className="font-semibold">Отворен на: </span>
                  {moment
                    .utc(ticket.created_at, "YYYY-MM-DDhh:mm:ss")
                    .local()
                    .format(DISPLAY_OPEN_DATE_FORMAT)}
                </h3>
                <h3 className="truncate font-medium">
                  <span className="font-semibold">Отворен од</span>:{" "}
                  {ticket.created_by.first_name +
                    " " +
                    ticket.created_by.last_name}
                </h3>
                {ticket.handled_by.length > 1 && (
                  <h3 className="truncate font-medium">
                    <span className="font-semibold">Примен од :</span>{" "}
                    {ticket.handled_by[1].user.first_name +
                      " " +
                      ticket.handled_by[1].user.last_name}
                  </h3>
                )}
                {ticket.forwarded_to.length > 0 && (
                  <h3 className="font-medium">
                    <span className="font-semibold">Препратен од :</span>{" "}
                    {ticket.forwarded_to[0].from_user.first_name +
                      " " +
                      ticket.forwarded_to[0].from_user.last_name +
                      " "}
                    до{" "}
                    {ticket.forwarded_to[0].to_user.first_name +
                      " " +
                      ticket.forwarded_to[0].to_user.last_name}
                  </h3>
                )}
                {ticket.issue_description && (
                  <h3 className="truncate font-medium">
                    <span className="font-semibold">Опис на проблем</span>:{" "}
                    {ticket.issue_description}
                  </h3>
                )}
                {ticket.corrective_action && (
                  <h3 className="truncate font-medium">
                    <span className="font-semibold">Опис на решение</span>:{" "}
                    {ticket.corrective_action}
                  </h3>
                )}
              </div>
            </div>
          ))}
        <div ref={loader} className="block"></div>
      </div>
      <div className="mr-4 flex justify-end">
        <div className="my-auto text-xl font-bold text-red-700">
          Повлечи за повеќе тикети
        </div>
        <img src="/pointer.svg" alt="pointer" className="align-center" />
      </div>
    </>
  );
};
