import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { usePagination, useSortBy, useTable } from "react-table";
import { ReportService } from "../service/reports";
import { LineReport, ModalType, Result, Status } from "../types";
import Select from "react-select";
import { Modal } from "../components/Modal/Modal";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "@heroicons/react/outline";
import "react-datepicker/dist/react-datepicker.css";
const colourStyles = {
  control: (styles: any) => ({ ...styles, backgroundColor: "black" }),
  //@ts-ignore
  option: (styles: any, { data: any, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "black",
      color: "#FFF",
    };
  },
  //@ts-ignore
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#808080",
    };
  },
  //@ts-ignore
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
  }),
};
export const AndonLines: React.FC = () => {
  const columns: any = useMemo(
    () => [
      {
        Header: "Линија",
        accessor: (row: LineReport) => {
          return `${row.name}`;
        },
      },
      {
        Header: "Број на отворени тикети",
        accessor: (row: LineReport) => {
          return row.opened_tickets_count ?? "0";
        },
      },
      {
        Header: "Број на активни тикети",
        accessor: (row: LineReport) => {
          return row.active_tickets_count ?? "0";
        },
      },
      {
        Header: "Број на препратени тикети",
        accessor: (row: LineReport) => {
          return row.forwarded_tickets_count ?? "0";
        },
      },
      {
        Header: "Број на затворени тикети",
        accessor: (row: LineReport) => {
          return row.closed_tickets_count ?? "0";
        },
      },
      {
        Header: "Време од последен тикет (минути)",
        accessor: (row: LineReport) => {
          return row.total_time_since_last_incident !== -1
            ? (row.total_time_since_last_incident! / 60).toFixed(1)
            : "/";
        },
      },
      {
        Header: "Средно време од последен тикет (минути)",
        accessor: (row: LineReport) => {
          return row.avg_time_from_last_ticket !== -1
            ? (row.avg_time_from_last_ticket! / 60).toFixed(1)
            : "/";
        },
      },
      {
        Header: "Вкупно време на решавање на проблем (минути)",
        accessor: (row: LineReport) => {
          return row.total_time_for_solving_problem !== -1
            ? (row.total_time_for_solving_problem! / 60).toFixed(1)
            : "/";
        },
      },
      {
        Header: "Средно време на решавање на проблем (минути)",
        accessor: (row: LineReport) => {
          return row.mean_time_for_solving_problem !== -1 &&
            row.mean_time_for_solving_problem !== null
            ? (row.mean_time_for_solving_problem! / 60).toFixed(1)
            : "/";
        },
      },
      {
        Header: "Вкупно време на реакција (минути)",
        accessor: (row: LineReport) => {
          return row.total_reaction_time !== -1
            ? (row.total_reaction_time! / 60).toFixed(1)
            : "/";
        },
      },
      {
        Header: "Средно време на реакција (минути)",
        accessor: (row: LineReport) => {
          return row.average_reaction_time !== -1
            ? (row.average_reaction_time! / 60).toFixed(1)
            : "/";
        },
      },
    ],
    []
  );
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const navigate = useNavigate();
  const [content, setContent] = useState<Array<LineReport>>([]);
  const [allOptions, setAllOptions] = useState<Array<LineReport>>([]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data: content,
      initialState: { pageIndex: 0, pageSize: 12 },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    ReportService.getLineReports().then((resp: Result<Array<LineReport>>) => {
      if (resp.status === Status.SUCCESS) {
        setContent(resp.value);
        setAllOptions(resp.value);
      }
    });
  }, []);
  const [open, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);
  const exportCSV = () => {
    setIsOpen(true);
    setModalProps({
      title: "Успешно",
      subtitle: `Ќе ви пристигне меил накратко`,
    });
  };
  console.log([
    ...content.map((t) => {
      return {
        label: t.name,
        value: t.line_id,
      };
    }),
  ]);
  return (
    <>
      {content ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <nav className="my-4 grid grid-rows-2 items-center justify-between border-gray-200 px-4 sm:px-0">
            <div className="col-span-12 mx-4 flex justify-between">
              <div className="">
                {canPreviousPage && (
                  <button
                    onClick={() => previousPage()}
                    className="inline-flex items-center rounded-md border-transparent bg-slate-500 py-4 px-3 text-xl font-medium text-white"
                  >
                    <ArrowNarrowLeftIcon
                      className="mr-3 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    Претходно
                  </button>
                )}
              </div>
              <div className="">
                <div className="relative">
                  <div className="text-lg text-white">Време од</div>
                  <DatePicker
                    className="bg-gray-800 text-lg text-white"
                    selected={startDate}
                    onChange={(date: Date) => setStartDate(date)}
                  />
                  <CalendarIcon className="absolute top-8 right-0 h-4 w-4 bg-white" />
                </div>
              </div>
              <div className="">
                <div className="relative">
                  <div className="text-lg text-white">Време до</div>
                  <DatePicker
                    className="bg-gray-800 text-lg text-white"
                    selected={endDate}
                    onChange={(date: Date) => setEndDate(date)}
                  />
                  <CalendarIcon className="absolute top-8 right-0 h-4 w-4 bg-white" />
                </div>
              </div>
              <button
                onClick={() => {
                  exportCSV();
                }}
                className=" items-center rounded-md border-transparent bg-aptiv-orange py-4 px-4 text-xl font-medium text-white"
              >
                Преземи во CSV
              </button>
              <div className="">
                <button
                  onClick={() => navigate(-1)}
                  className="inline-flex items-center rounded-md border-transparent bg-aptiv-orange py-4 px-8 text-xl font-medium text-white"
                >
                  Назад
                </button>
              </div>
              <div className="">
                {canNextPage && (
                  <button
                    onClick={() => nextPage()}
                    className="inline-flex items-center rounded-md border-transparent bg-slate-500 py-4 px-3 text-xl font-medium text-white"
                  >
                    Следно
                    <ArrowNarrowRightIcon
                      className="ml-3 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </button>
                )}
              </div>
            </div>
            <div className="col-span-12 row-span-1">
              <div className="mx-3">
                <Select
                  name="lines-select"
                  styles={colourStyles}
                  isMulti={true}
                  options={allOptions.map((t) => {
                    return {
                      label: t.name,
                      value: t.line_id,
                    };
                  })}
                  value={content.map((t) => {
                    return {
                      label: t.name,
                      value: t.line_id,
                    };
                  })}
                  onChange={(e) => {
                    setContent(
                      allOptions.filter((c) =>
                        e.find((r) => r.value === c.line_id)
                      )
                    );
                  }}
                />
              </div>
            </div>
          </nav>
          <div className="flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table
                    {...getTableProps()}
                    className="min-w-full divide-y divide-gray-300"
                  >
                    <thead className="bg-gray-800">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6"
                            >
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      {...getTableBodyProps()}
                      className="divide-y divide-gray-200 bg-black"
                    >
                      {page.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/reports/andon-lines/${row.original.line_id}`
                              )
                            }
                          >
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6"
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Modal open={open} setOpen={setIsOpen} modalProps={modalProps} />
    </>
  );
};
