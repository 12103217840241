import React, { useCallback, useEffect, useState } from "react";
import { TableDetail } from "../components/Table/TableDetail";
import { useParams } from "react-router-dom";
import {
  AcceptTicketRequest,
  ApprovePlannedAction,
  CloseTicketRequest,
  ModalType,
  Result,
  Status,
  StatusEnum,
  Ticket,
  User,
} from "../types";
import { TicketService } from "../service/tickets";
import { useTicket } from "../TicketContext";
import moment from "moment";
import { AccountService } from "../service/accounts";
import { useNavigate } from "react-router";
import { ApproveAction } from "../components/NewPlannedAction/ApproveAction";
import { StartAction } from "../components/NewPlannedAction/StartAction";
import { FinishAction } from "../components/NewPlannedAction/FinishAction";
import { Modal } from "../components/Modal/Modal";

const checkTime = (
  timeFrom: moment.Moment,
  timeTo: moment.Moment,
  dateSelected: Date
) => {
  var date = moment(dateSelected);
  var dateTimeFrom = moment(timeFrom);
  var dateTimeTo = moment(timeTo);
  dateTimeFrom.date(date.date());
  dateTimeFrom.month(date.month());
  dateTimeFrom.year(date.year());
  dateTimeTo.date(date.date());
  dateTimeTo.month(date.month());
  dateTimeTo.year(date.year());
  var now = moment(Date.now()).format("YYYY-MM-DD[T]HH:mm:ss");

  if (moment(now).isBetween(moment(dateTimeFrom), moment(dateTimeTo))) {
    return true;
  }

  return false;
};

export const PendingAction: React.FC = () => {
  const { id } = useParams<"id">();
  const { dispatch, state } = useTicket();
  const navigate = useNavigate();
  const [isMoreThan30Minutes, setIsMoreThan30Minutes] = useState(false);

  const [open, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);
  const handleRejectAction = async (card_id: string) => {
    const user: Result<User> = await AccountService.fetchByCardId(card_id);

    if (user.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }

    const rejectedAction: AcceptTicketRequest = {
      card_id,
    };
    const resp: Result<Ticket> = await TicketService.rejectPlannedAction(
      parseInt(id!),
      rejectedAction
    );

    if (resp.status === Status.SUCCESS) {
      dispatch({
        type: "REJECT_PLANNED_ACTION",
        payload: user.value,
      });
      navigate(`/tickets/${id}`);
    } else if (resp.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${resp.message}`,
      });
    }
  };
  const handleFinishAction = async (card_id: string) => {
    const user: Result<User> = await AccountService.fetchByCardId(card_id);

    if (user.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }

    const closeAction: CloseTicketRequest = {
      card_id,
    };
    const resp: Result<Ticket> = await TicketService.closeTicket(
      parseInt(id!),
      closeAction
    );

    if (resp.status === Status.SUCCESS) {
      dispatch({
        type: "SET_CLOSED_AT",
        payload: moment(Date.now()).format("YYYY-MM-DD[T]HH:mm:ss"),
      });
      dispatch({
        type: "SET_CLOSED_BY",
        payload: user.value,
      });
      navigate(`/tickets/${id}`);
    } else if (resp.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${resp.message}`,
      });
    }
  };
  const handleApproveAction = async (
    card_id: string,
    datetime_from: string,
    datetime_to: string
  ) => {
    const user: Result<User> = await AccountService.fetchByCardId(card_id);

    if (user.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }

    const approveAction: ApprovePlannedAction = {
      card_id,
      datetime_from,
      datetime_to,
    };
    const resp: Result<Ticket> = await TicketService.approvePlannedAction(
      parseInt(id!),
      approveAction
    );

    if (resp.status === Status.SUCCESS) {
      dispatch({
        type: "APPROVE_PLANNED_ACTION",
        payload: user.value,
      });

      window.location.reload();
    } else if (resp.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${resp.message}`,
      });
    }
  };

  const handleStartAction = async (card_id: string) => {
    const user: Result<User> = await AccountService.fetchByCardId(card_id);

    if (user.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }

    const canWork = checkTime(state.timeFrom, state.timeTo, state.date);
    if (!canWork) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `Сеуште не можете да започнете со акцијата`,
      });
      return;
    }
    const acceptTicket: AcceptTicketRequest = {
      card_id,
    };
    const resp: Result<Ticket> = await TicketService.acceptTicket(
      parseInt(id!),
      acceptTicket
    );
    if (resp.status === Status.SUCCESS) {
      window.location.reload();
    } else if (resp.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${resp.message}`,
      });
    }
  };

  const changeTicketContext = useCallback(
    (ticket: Ticket) => {
      if (
        moment(ticket.datetime_to).diff(
          moment(ticket.datetime_from),
          "minutes"
        ) > 30
      ) {
        setIsMoreThan30Minutes(true);
      }
      dispatch({
        type: "UPDATE_STATE",
        payload: {
          ticketType: ticket.ticket_type,
          machines: ticket.machines,
          status: ticket.status,
          ticket_id: ticket.id_,
          date: ticket.datetime_from,
          timeFrom: moment(ticket.datetime_from),
          timeTo: moment(ticket.datetime_to),
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    TicketService.fetchById(parseInt(id!)).then((resp: Result<Ticket>) => {
      if (resp.status === Status.SUCCESS) {
        changeTicketContext(resp.value);
      } else if (resp.status === Status.ERROR) {
        setIsOpen(true);
        setModalProps({
          title: "Настана грешка",
          subtitle: `${resp.message}`,
        });
      }
    });
  }, [id, changeTicketContext]);

  return (
    <div>
      <div className="mt-4 flex">
        <div className="ml-4 w-1/3">
          {state.status === StatusEnum.WAITING_FOR_APPROVAL && (
            <div className="mt-2 rounded-lg border">
              <ApproveAction
                onApprove={handleApproveAction}
                onReject={handleRejectAction}
                isMoreThan30Minutes={isMoreThan30Minutes}
              />
            </div>
          )}
          {state.status === StatusEnum.APPROVED && (
            <div className="mt-2 rounded-lg border">
              <StartAction
                onReject={handleRejectAction}
                onStart={handleStartAction}
              />
            </div>
          )}
          {state.status === StatusEnum.PENDING && (
            <div className="mt-2 rounded-lg border">
              <FinishAction onFinish={handleFinishAction} />
            </div>
          )}
        </div>
        <div className="ml-4 w-2/3">
          <TableDetail title={"Информации за акција"} />
        </div>
      </div>
      <Modal open={open} setOpen={setIsOpen} modalProps={modalProps} />
    </div>
  );
};
