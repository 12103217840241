import React, { useState } from "react";
import { LoginHeader } from "../components/Login/LoginHeader";
import { LoginForm } from "../components/Login/LoginForm";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "../hooks/useSessionStorage";
import { Line, ModalType, Result, Status, User } from "../types";
import { LineService } from "../service/lines";
import { AccountService } from "../service/accounts";
import { options } from "../const";
import { Modal } from "../components/Modal/Modal";
import { AuthService } from "../service/auth";
import { useLanguage } from "../LanguageContext";
import i18n from "../translations/config";
import { useTranslation } from "react-i18next";
// import Background from '../images/background_image.png';
type LoginState = {
  loginType: string;
  placeHolder: string;
};
type LoginProp = {
  isUserAlreadyLoggedIn: boolean;
};

export interface LineLogin {
  name: string;
  id: number;
}

export const LoginPage: React.FC<LoginProp> = ({ isUserAlreadyLoggedIn }) => {
  const navigate = useNavigate();
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const { t } = useTranslation();
  const [loginTypeState, setLoginType] = useState<LoginState>({
    loginType: t("loginPage.loginLine"),
    placeHolder: "Линија id",
  });
  const [open, setIsOpen] = useState(false);

  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);
  const { setLanguage } = useLanguage();
  const [user, setUser] = useSessionStorage("user");
  const [isLine, setIsLine] = useSessionStorage("isLine");
  const changeLoginType = () => {
    if (
      loginTypeState.loginType === t("loginPage.loginLine") &&
      loginTypeState.placeHolder === "Линија id"
    ) {
      setLoginType({
        loginType: t("loginPage.loginUser"),
        placeHolder: "NetID",
      });
    } else
      setLoginType({
        loginType: t("loginPage.loginLine"),
        placeHolder: "Линија id",
      });
  };
  const handleSubmit = async (id: string | undefined, password: string) => {
    if (undefined) {
      console.log(user);
      console.log(isLine);
    }
    if (loginTypeState.loginType === t("loginPage.loginLine")) {
      LineService.fetchById(parseInt(id!)).then((resp: Result<Line>) => {
        if (resp.status === Status.SUCCESS) {
          if (resp.value.password !== password) {
            setIsOpen(true);
            setModalProps({
              title: t("common.error"),
              subtitle: t("loginPage.wrongPassword"),
            });
            return;
          }
          setUser(resp.value);
          setIsLine(true);
          navigate("/");
        } else if (resp.status === Status.ERROR) {
          setIsOpen(true);
          setModalProps({
            title: t("common.error"),
            subtitle: `${resp.message}`,
          });
        }
      });
    } else {
      AuthService.login({ username: id!, password: password }).then((resp) => {
        console.log(resp);
        if (resp.status === Status.ERROR) {
          console.log("errooor");
          setIsOpen(true);
          setModalProps({
            title: t("common.error"),
            subtitle: `${resp.message}`,
          });
        } else if (resp.status === Status.SUCCESS) {
          AccountService.fetchByUsername(id!).then((resp: Result<User>) => {
            if (resp.status === Status.SUCCESS) {
              setUser(resp.value);
              setIsLine(false);
              navigate("/user");
            } else if (resp.status === Status.ERROR) {
              setIsOpen(true);
              setModalProps({
                title: t("common.error"),
                subtitle: `${resp.message}`,
              });
            }
          });
        }
      });
    }
  };
  return (
    <>
      <div className="grid">
        <div
          className={
            keyboardOpen
              ? "mx-auto grid w-full max-w-6xl grid-cols-2 justify-items-center bg-black bg-opacity-70 py-12"
              : "-pt-12 mx-auto -mt-10 grid max-w-3xl justify-items-center bg-black bg-opacity-70 py-5 align-middle "
          }
          //style={{ backgroundImage: `url(/loginImage.jpg)` }}
        >
          <div className={keyboardOpen ? "my-auto" : ""}>
            <img src="/aptiv.svg" width="400" alt="aptiv logo" />
            <LoginHeader typeLogin={loginTypeState.loginType} />
          </div>
          {/* <span className="text-3xl font-bold text-red-500">
            {t("loginPage.line")}
          </span> */}
          <div
            className={
              keyboardOpen
                ? "-mt-16 flex justify-center"
                : "flex justify-center"
            }
          >
            <div className="ml-14">
              <LoginForm
                placeHolderText={loginTypeState.placeHolder}
                keyboardOpen={keyboardOpen}
                setKeyboardOpen={() => setKeyboardOpen((old) => !old)}
                onSubmit={handleSubmit}
                isLine={loginTypeState.loginType === t("loginPage.loginLine")}
                lines={options}
              />
            </div>
            {!isUserAlreadyLoggedIn && (
              <div
                className="ml-5 mt-36 h-10 w-10 cursor-pointer rounded-full bg-red-500 text-center"
                onClick={changeLoginType}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="m-auto mt-2 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                  />
                </svg>
              </div>
            )}
          </div>
          <div className="mt-6">
            <img src="/masac.svg" alt="masac logo" width="200" />
          </div>
          <div className="mt-4 text-center text-gray-500">
            <p>Copyright &copy; MASAC 2021</p>
            <p>
              Product Version: Monitoring System - ANDON | Release Version:{" "}
              {process.env.REACT_APP_VERSION}
            </p>

            <div className="mt-3 grid grid-cols-2 text-gray-200">
              <div
                className={`cursor-pointer underline ${
                  i18n.language === "en" ? "text-white" : "text-gray-400"
                }`}
                onClick={() => {
                  setLanguage("en");
                  i18n.changeLanguage("en");
                  window.location.reload();
                }}
              >
                EN
              </div>
              <div
                className={`cursor-pointer underline ${
                  i18n.language === "mk" ? "text-white" : "text-gray-400"
                }`}
                onClick={() => {
                  setLanguage("mk");
                  i18n.changeLanguage("mk");
                  window.location.reload();
                }}
              >
                MKD
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} setOpen={setIsOpen} modalProps={modalProps} />
    </>
  );
};
