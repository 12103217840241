import { MachineService } from "../service/machines";
import React, { useEffect, useState } from "react";
import { Machine, ModalType, Page, Result, Status } from "../types";
import { PreventiveMaintenanceList } from "../components/PreventiveMaintenance/PreventiveMaintenanceList";
import { useSessionStorage } from "../hooks/useSessionStorage";
import { Modal } from "../components/Modal/Modal";

export const PreventiveMaintenance: React.FC = () => {
  const [machines, setMachines] = useState<Array<Machine>>([]);
  const [user] = useSessionStorage("user");
  const [open, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);

  useEffect(() => {
    MachineService.fetchAll(user.id_, 0, 100).then(
      (resp: Result<Page<Machine>>) => {
        if (resp.status === Status.SUCCESS) {
          setMachines((old_state: Array<Machine>) => [
            ...old_state,
            ...resp.value.content,
          ]);
        } else if (resp.status === Status.ERROR) {
          setIsOpen(true);
          setModalProps({
            title: "Настана грешка",
            subtitle: `${resp.message}`,
          });
        }
      }
    );
  }, [user.id_]);

  return (
    <>
      <PreventiveMaintenanceList machines={machines} />
      <Modal open={open} setOpen={setIsOpen} modalProps={modalProps} />

    </>
  );
};
