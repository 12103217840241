import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSessionStorage } from "../../hooks/useSessionStorage";
import { useTicket } from "../../TicketContext";
import { UpdatePasswordModal } from "../Modal/UpdatePasswordModal";

const formatDate = (dateTime: string) => {
  const days = ["нед", "пон", "вто", "сре", "чет", "пет", "саб"];

  return (
    days[new Date().getDay()] +
    " " +
    moment(dateTime, "MM/DD/YYYY h:m:s A").format("DD-MM-YYYY HH:mm:ss")
  );
};

export const Header: React.FC = () => {
  const [date, setDate] = useState<string>(new Date().toLocaleString());
  const navigate = useNavigate();
  const { dispatch } = useTicket();
  useEffect(() => {
    const interval = setInterval(
      () => setDate(formatDate(new Date().toLocaleString())),
      1000
    );
    formatDate(date.toLocaleString());
    return () => {
      clearInterval(interval);
    };
  }, [date]);
  const [user] = useSessionStorage("user");
  const [isLine] = useSessionStorage("isLine");
  const handleNavigate = () => {
    return isLine ? "/" : "/user";
  };
  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
  return (
    <div className="absolute z-50 w-full">
      <div className="block">
        <div className="mx-auto bg-black px-2 sm:px-6">
          <div className="relative flex h-16 items-center justify-between">
            <button
              onClick={() => {
                dispatch({
                  type: "REFRESH_STATE",
                });
                navigate(handleNavigate());
              }}
              className="w-1/3"
            >
              <div className="flex">
                <img
                  src="/aptiv.svg"
                  alt="aptiv logo"
                  className="my-auto h-5"
                />
                <div className="my-auto ml-3 text-lg font-bold text-white">
                  by
                </div>
                <img
                  src="/masac.svg"
                  alt="masac logo"
                  className="h-auto w-20"
                />
              </div>
            </button>
            <div className="flex w-1/3 justify-center text-3xl font-bold text-white">
              <span
                className="text-aptiv-orange"
                onClick={() => !isLine && setPasswordModalOpen(true)}
              >
                {" "}
                {user != null
                  ? isLine
                    ? user.name
                    : user.first_name + " " + user.last_name
                  : ""}
              </span>
            </div>
            <div className="flex w-1/3 justify-end space-x-8 text-2xl text-white">
              <img
                src="/andon.png"
                alt="andon logo"
                className="my-auto h-5 w-1/2"
              />
              <div className="w-full">{date.toLocaleString()}</div>
            </div>
          </div>
        </div>
        <div className="h-1 bg-aptiv-slate-blue shadow-2xl" />
      </div>
      <UpdatePasswordModal
        open={isPasswordModalOpen}
        setOpen={setPasswordModalOpen}
        user={JSON.parse(sessionStorage.getItem("user") ?? "{}")}
      />
    </div>
  );
};
