import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ButtonComponent } from "../components/ButtonComponent/ButtonComponent";
import { Loader } from "../components/Loader/Loader";

import { useTicket } from "../TicketContext";

export const PlannedAction = () => {
  const navigate = useNavigate();
  const { dispatch } = useTicket();

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      <div className="grid h-full">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="mx-8 mt-auto grid grid-cols-3 justify-items-center">
              <ButtonComponent
                onClick={() => {
                  dispatch({
                    type: "SET_REQUEST_ACTION",
                    payload: true,
                  });
                  navigate("/preventive-maintenance");
                }}
                classes="py-4 mr-6 rounded-md text-7xl"
                buttonName="Побарај акција"
              />

              <ButtonComponent
                onClick={() => navigate("/pending-requests")}
                classes="py-4 rounded-md text-7xl relative "
                buttonName="Поднесени барања"
              />

              <ButtonComponent
                onClick={() => navigate("/approved-requests")}
                classes="py-3 ml-6 rounded-md text-7xl"
                buttonName="Одобрени барања"
              />
            </div>
            <div className="mx-8 mb-4 mt-auto flex justify-end">
              <ButtonComponent
                onClick={() => navigate("/login")}
                classes="px-8 rounded-md pb-4 text-6xl"
                buttonName="Одјави се"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
