import React from "react";
import { useNavigate } from "react-router-dom";
import { IssueTypeHeader } from "../components/IssueTypeHeader/IssueTypeHeader";
import { ButtonComponent } from "../components/ButtonComponent/ButtonComponent";
import { useTicket } from "../TicketContext";
import { PriorityTypeEnum } from "../types";
export const StatusOfTheLine: React.FC = () => {
  const navigate = useNavigate();
  const { dispatch } = useTicket();
  return (
    <div className="grid h-full">
      <IssueTypeHeader issueType="Статус на линијата" />
      <div className="grid grid-cols-1 justify-between mt-3 mx-4 my-auto">
        <button
          onClick={() => {
            dispatch({
              type: "SET_PRIORITY",
              payload: PriorityTypeEnum.TOTAL_HALT,
            });
            navigate("/open-new-ticket");
          }}
          className="mb-3 mx-44 py-4 text-center text-4xl font-normal bg-aptiv-orange rounded-md"
        >
          Приоритет 1 - Прекин на производството
        </button>
        <button
          onClick={() => {
            dispatch({
              type: "SET_PRIORITY",
              payload: PriorityTypeEnum.EFFICIENCY_REDUCTION,
            });
            navigate("/open-new-ticket");
          }}
          className="mb-3 mx-44 py-4 text-center text-4xl font-normal bg-pink-500 rounded-md"
        >
          Приоритет 2 - Намалување на OEE
        </button>
        <button
          onClick={() => {
            dispatch({
              type: "SET_PRIORITY",
              payload: PriorityTypeEnum.POTENTIAL_HALT,
            });
            navigate("/open-new-ticket");
          }}
          className="mb-3 mx-44 py-4 text-center text-4xl font-normal bg-yellow-600 rounded-md"
        >
          Приоритет 3 - Потенцијално застанување
        </button>
        <button
          onClick={() => {
            dispatch({
              type: "SET_PRIORITY",
              payload: PriorityTypeEnum.NON_CRITICAL,
            });
            navigate("/open-new-ticket");
          }}
          className="mb-3 mx-44 py-4 text-center text-4xl font-normal bg-yellow-400 rounded-md"
        >
          Приоритет 4 - Не е критично
        </button>
        <button
          onClick={() => {
            dispatch({
              type: "SET_PRIORITY",
              payload: PriorityTypeEnum.INACTIVE,
            });
            navigate("/open-new-ticket");
          }}
          className="mb-3 mx-44 py-4 text-center text-4xl font-normal bg-blue-500 rounded-md"
        >
          Приоритет 5 - Неактивно
        </button>
      </div>
      <div className="flex justify-between mb-4 mt-auto mx-8">
        <ButtonComponent
          onClick={() => navigate("/")}
          classes="px-8  rounded-md pb-4 text-6xl"
          buttonName="Почетна"
        />
        <ButtonComponent
          onClick={() => navigate(-1)}
          classes="px-8  rounded-md pb-4 text-6xl"
          buttonName="Назад"
        />
      </div>
    </div>
  );
};
