import React, { useRef } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../TextInput/TextInput";
import { TestButton } from "../TestButton/TestButton";
import { useTicket } from "../../TicketContext";
import { KeyboardWrapper } from "../Keyboard/KeyboardWrapper";
import { useKeyboard } from "../../hooks/useKeyboard";
import { CreateTicketRequest, Machine, Product, StatusEnum } from "../../types";
import { useSessionStorage } from "../../hooks/useSessionStorage";

type ProductionLeaderProp = {
  onSubmit: (ticket: CreateTicketRequest, id: string, product: string) => void;
  products: Array<Product>;
};

export const ProductionLeader: React.FC<ProductionLeaderProp> = ({
  onSubmit,
  products,
}) => {
  const ProductionLeaderSchema = Yup.object().shape({
    id: Yup.string().required("Задолжително поле"),
    product: Yup.string().required("Селектирајте продукт"),
  });
  const ticketContext = useTicket();
  const ticketState = ticketContext.state;

  const keyboard = useRef(null);
  const [isVisible, toggleVisible] = useKeyboard(false);

  const [user] = useSessionStorage("user");
  return (
    <>
      {products.length > 0 && (
        <Formik
          initialValues={{ id: "", product: products[0].id_.toString() }}
          onSubmit={(values, actions) => {
            const ticket: CreateTicketRequest = {
              priority: ticketState.priority,
              ticket_type: ticketState.ticketType,
              machine_ids: ticketState.machines.map((m: Machine) => m.id_),
              created_by_card_id: values.id,
              product_id: parseInt(values.product),
              line_id: user.id_,
              status: StatusEnum.OPEN,
            };

            onSubmit(ticket, values.id, values.product);
            actions.resetForm();
          }}
          validationSchema={ProductionLeaderSchema}
        >
          {(props) => (
            <Form
              className="mt-5 w-full"
              autoComplete="off"
              onSubmit={(e) => e.preventDefault()}
              action="return false;"
            >
              <label
                htmlFor="name"
                className="block ml-px pb-3 pl-4 text-center text-white text-2xl font-medium"
              >
                Избери продукт
              </label>

              <div className="grid justify-center mt-3">
                <Field
                  as="select"
                  name="product"
                  autoFocus={true}
                  className="py-4 rounded-md"
                >
                  {products.map((p: Product) => (
                    <option value={String(p.id_)} key={p.id_}>
                      {p.name}
                    </option>
                  ))}
                </Field>
                <div className="mb-5 mt-2 h-2 text-center">
                  <ErrorMessage name="product">
                    {(errorMessage) => (
                      <span className="font-regular text-red-600 text-xl">
                        {errorMessage}
                      </span>
                    )}
                  </ErrorMessage>
                </div>
                <label
                  htmlFor="name"
                  className="block ml-px mt-2 pb-3 pl-4 text-center text-white text-2xl font-medium"
                >
                  Одговорен на линија
                </label>
                <TextInput
                  type="text"
                  name="id"
                  id="id"
                  autoFocus={true}
                  placeholder="id"
                  onClick={toggleVisible}
                ></TextInput>
              </div>
              <div className="flex justify-center mt-3">
                <TestButton
                  className={
                    "py-4 px-4 mt-3 rounded-md mr-2 mb-2 text-3xl " +
                    ticketState.productionLeaderButton.color
                  }
                  type="button"
                  disabled={ticketState.productionLeaderButton.disabled}
                  onClick={() => {
                    props.handleSubmit();
                  }}
                >
                  Отвори тикет
                </TestButton>
              </div>
              {isVisible && (
                <div className="absolute right-0 bottom-16 w-2/3">
                  <KeyboardWrapper
                    keyboardRef={keyboard}
                    onChange={(input: string) => {
                      props.handleChange(input);
                      props.setFieldValue("id", input);
                    }}
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
