import { AuthApi } from "../api/auth";
import {
  ChangePasswordRequest,
  CreateLoginRequest,
  CSVResponse,
  LoginResponse,
  Result,
} from "../types";
import { Handler } from "./result";

export interface IAuthService {
  login(login_req: CreateLoginRequest): Promise<Result<LoginResponse>>;
  change_password(
    change_password_req: ChangePasswordRequest
  ): Promise<Result<CSVResponse>>;
}

export const AuthService: IAuthService = {
  async login(login_req: CreateLoginRequest): Promise<Result<LoginResponse>> {
    return await AuthApi.login(login_req)
      .then((resp) => Handler.SuccessResult(resp.data))
      .catch((reason) => Handler.ErrorResult(reason));
  },

  async change_password(
    change_password_req: ChangePasswordRequest
  ): Promise<Result<CSVResponse>> {
    return await AuthApi.change_password(change_password_req)
      .then((resp) => Handler.SuccessResult(resp.data))
      .catch((reason) => Handler.ErrorResult(reason));
  },
};
