import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { usePagination, useSortBy, useTable } from "react-table";
import { Modal } from "../components/Modal/Modal";
import { DISPLAY_OPEN_DATE_FORMAT } from "../const";
import { useSessionStorage } from "../hooks/useSessionStorage";
import { TicketService } from "../service/tickets";
import {
  ModalType,
  Page,
  PriorityTypeEnum,
  Result,
  Status,
  StatusEnum,
  Ticket,
} from "../types";
import {
  getMachineProblemType,
  getPriorityTypeWithoutNumbers,
  getStatus,
} from "../utils";
import { navigateTicketTo } from "../utils";

export const TicketReports: React.FC = () => {
  const columns: any = useMemo(
    () => [
      {
        Header: "Линија",
        accessor: (row: Ticket) => {
          return `${row.line?.name}`;
        },
      },
      {
        Header: "Машини",
        accessor: (row: Ticket) => {
          return row.machines.length > 0
            ? row.machines[0].name.slice(0, 11)
            : "Нема машини";
        },
      },
      {
        Header: "Тип на тикет",
        accessor: (row: Ticket) => {
          return getMachineProblemType(row.ticket_type);
        },
      },
      {
        Header: "Време на отварање",
        accessor: (row: Ticket) => {
          return moment
            .utc(row.created_at, "YYYY-MM-DD[T]HH:mm:ss")
            .local()
            .format(DISPLAY_OPEN_DATE_FORMAT);
        },
      },
      {
        Header: "Приоритет",
        accessor: (row: Ticket) => {
          return getPriorityTypeWithoutNumbers(row.priority!);
        },
      },
      {
        Header: "Статус",
        accessor: (row: Ticket) => {
          return getStatus(row.status);
        },
      },
    ],
    []
  );
  const navigate = useNavigate();
  const [user] = useSessionStorage("user");
  const [content, setContent] = useState<Array<Ticket>>([]);
  const [open, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);

  const [fetchClosed, setFetchClosed] = useState(false);

  const getStatusColor = (priority: PriorityTypeEnum) => {
    switch (priority) {
      case PriorityTypeEnum.EFFICIENCY_REDUCTION:
        return "bg-pink-500";
      case PriorityTypeEnum.INACTIVE:
        return "bg-blue-500";
      case PriorityTypeEnum.NON_CRITICAL:
        return "bg-yellow-400";
      case PriorityTypeEnum.POTENTIAL_HALT:
        return "bg-yellow-600";
      case PriorityTypeEnum.TOTAL_HALT:
        return "bg-aptiv-orange";
      case PriorityTypeEnum.PLANNED_ACTION:
        return "bg-aptiv-kiwi-500";
      case PriorityTypeEnum.PREVENTIVE_MAINTENANCE:
        return `bg-emerald-500`;
      default:
        return "bg-black";
    }
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data: content,
      initialState: { pageIndex: 0, pageSize: 12 },
    },
    useSortBy,
    usePagination
  );

  const getStatuses = useCallback(() => {
    let statuses = [
      StatusEnum.OPEN,
      StatusEnum.FORWARDED,
      StatusEnum.PENDING,
      StatusEnum.ON_HOLD,
      StatusEnum.MATERIAL_NOT_AVAILABLE,
      StatusEnum.WAITING_FOR_APPROVAL,
      StatusEnum.PRIORITY,
      StatusEnum.APPROVED,
    ];
    if (fetchClosed) {
      statuses.push(StatusEnum.CLOSED);
      statuses.push(StatusEnum.REJECTED);
    }
    return statuses;
  }, [fetchClosed]);

  useEffect(() => {
    TicketService.fetchAllTickets(0, 10000, undefined, getStatuses()).then(
      (resp: Result<Page<Ticket>>) => {
        if (resp.status === Status.SUCCESS) {
          setContent(resp.value.content);
        }
      }
    );
  }, [getStatuses]);

  const exportCSV = () => {
    TicketService.exportCSV(user.id_).then((resp) => {
      if (resp.status === Status.SUCCESS) {
        setIsOpen(true);
        setModalProps({
          title: "Успешно",
          subtitle: `Ќе ви пристигне меил накратко`,
        });
      } else if (resp.status === Status.ERROR) {
        setIsOpen(true);
        setModalProps({
          title: "Настана грешка",
          subtitle: `${resp.message}`,
        });
      }
    });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <nav className="my-4 flex  items-center justify-between border-gray-200 px-4 sm:px-0">
        <div className="flex w-0 flex-1">
          {canPreviousPage && (
            <button
              onClick={() => previousPage()}
              className="inline-flex items-center rounded-md border-transparent bg-slate-500 py-4 px-3 text-xl font-medium text-white"
            >
              <ArrowNarrowLeftIcon
                className="mr-3 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              Претходно
            </button>
          )}
        </div>
        <div className="flex justify-center space-x-4">
          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center rounded-md border-transparent bg-aptiv-orange py-4 px-8 text-xl font-medium text-white"
          >
            Назад
          </button>
          <label className="inline-flex items-center rounded-md border-transparent bg-aptiv-orange py-4 px-8 text-xl font-medium text-white">
            <div>Види затворени тикети</div>
            <input
              type="checkbox"
              name="fetchClosed"
              onChange={() => setFetchClosed((old_value) => !old_value)}
              className="h-4 w-4 ml-3 mt-2"
            />
          </label>
          <button
            onClick={() => {
              exportCSV();
            }}
            className="inline-flex items-center rounded-md border-transparent bg-aptiv-orange py-4 px-8 text-xl font-medium text-white"
          >
            Преземи во CSV
          </button>
        </div>
        <div className="-mt-px flex w-0 flex-1 justify-end">
          {canNextPage && (
            <button
              onClick={() => nextPage()}
              className="inline-flex items-center rounded-md border-transparent bg-slate-500 py-4 px-3 text-xl font-medium text-white"
            >
              Следно
              <ArrowNarrowRightIcon
                className="ml-3 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </button>
          )}
        </div>
      </nav>
      <div className="flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-300"
              >
                <thead className="bg-gray-800">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6"
                        >
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-gray-200 bg-black"
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className={`${getStatusColor(
                          row.original.priority!
                        )} cursor-pointer`}
                        onClick={() => navigate(navigateTicketTo(row.original))}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-black sm:pl-6"
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} setOpen={setIsOpen} modalProps={modalProps} />
    </div>
  );
};
