import * as Yup from "yup";
import { Form, Formik } from "formik";
import { TextInput } from "../TextInput/TextInput";
import { useTicket } from "../../TicketContext";
import { useKeyboard } from "../../hooks/useKeyboard";
import { useRef, useState } from "react";
import Keyboard from "react-simple-keyboard";

interface FormProps {
  onSubmit: (corrective_action: string, issueDescription: string) => void;
  cancel: () => void;
}
const Schema = Yup.object().shape({
  corrective_action: Yup.string().required("Полето е задолжително"),
  issueDescription: Yup.string().required("Полето е задолжително"),
});

export const UpdateTicketForm: React.FC<FormProps> = ({ onSubmit, cancel }) => {
  const { state } = useTicket();
  const [isVisible, toggleVisible] = useKeyboard(false);
  const keyboard = useRef(null);
  const [inputs, setInputs] = useState({});
  console.log(inputs);
  const [inputName, setInputName] = useState("default");
  const [layoutName, setLayoutName] = useState("default");
  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  const onChangeAll = (inputs: any) => {
    setInputs({ ...inputs });
  };

  const handleKeyboardStatusChange = () => {
    toggleVisible();
  };

  const onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };
  return (
    <Formik
      initialValues={{
        corrective_action: state.correctiveAction,
        issueDescription: state.issueDescription,
      }}
      onSubmit={(values, actions) => {
        onSubmit(values.corrective_action, values.issueDescription);
      }}
      validationSchema={Schema}
    >
      {(props) => (
        <Form>
          <TextInput
            type="text"
            name="issueDescription"
            id="issueDescription"
            placeholder="Опис на проблем"
            label="Опис на проблем"
            onClick={handleKeyboardStatusChange}
            setInputName={setInputName}
          ></TextInput>
          <TextInput
            type="text"
            name="corrective_action"
            id="corrective_action"
            placeholder="Опис на решение"
            label="Опис на решение"
            onClick={handleKeyboardStatusChange}
            setInputName={setInputName}
          ></TextInput>

          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="submit"
              onClick={() => props.handleSubmit()}
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Промени
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => cancel()}
            >
              Откажи
            </button>
          </div>
          {isVisible && (
            <Keyboard
              keyboardRef={(r) => (keyboard.current = r)}
              inputName={inputName}
              onChangeAll={(inputs: any) => {
                props.setFieldValue(
                  "issueDescription",
                  inputs.issueDescription
                );
                props.setFieldValue(
                  "corrective_action",
                  inputs.corrective_action
                );
                onChangeAll(inputs);
              }}
              //layout={layout}
              theme="hg-theme-default myTheme2"
              onKeyPress={onKeyPress}
              layoutName={layoutName}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};
