import moment from "moment";
import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from "react";
import { ContextAction, ReducerType, ContextHook } from "./react-app-env";
import {
  Machine,
  MachineProblemTypeEnum,
  PriorityTypeEnum,
  Product,
  Reason,
  StatusEnum,
  User,
} from "./types";
export type Button = {
  isClicked: boolean;
  color: string;
  disabled: boolean;
  clickedAt?: Date | null;
};
export const buttonNotClicked: Button = {
  isClicked: false,
  color: "bg-aptiv-light-gray",
  disabled: false,
};
export const buttonIsClicked: Button = {
  isClicked: true,
  color: "bg-aptiv-dark-gray-800",
  disabled: true,
};
export const buttonIsDisabled: Button = {
  isClicked: false,
  color: "bg-aptiv-dark-gray-800",
  disabled: true,
};
export interface ContextState {
  ticketType: MachineProblemTypeEnum | null;
  machines: Array<Machine>;
  priority: PriorityTypeEnum | null | string;
  reason: Reason | null;
  operatorId: User | null;
  componentNumber: string;
  openedAt: Date | null;
  maintenanceTechnician: User | null;
  l1ReceivedAt: Date | null;
  l1ForwardedAt?: Date | null;
  forwardedTo : User | null;
  maintenanceSupervisor?: User | null;
  l2ReceivedAt?: Date | null;
  closedAt: Date | null;
  issueDescription: string;
  correctiveAction: string;
  requestAction: boolean;
  date: Date | null;
  timeFrom: moment.Moment | null;
  timeTo: moment.Moment | null;
  ticket_id: number | null;
  product: Product | null;
  status: StatusEnum | null;
  closedBy: string;
  productionLeaderButton: Button;
  maintenanceTehnicianButtons: Array<Button>;
  maintenanceSupervisorButtons: Array<Button>;
  preventiveMaintenanceButtons: Array<Button>;
  requestPlannedActionBy: User | null;
  approvePlannedActionBy: User | null;
  rejectPlannedActionBy: User | null;
}
const initialState: ContextState = {
  ticketType: null,
  machines: [],
  priority: null,
  reason: null,
  operatorId: null,
  componentNumber: "",
  openedAt: null,
  maintenanceTechnician: null,
  l1ReceivedAt: null,
  l1ForwardedAt: null,
  maintenanceSupervisor: null,
  forwardedTo : null,
  l2ReceivedAt: null,
  closedAt: null,
  issueDescription: "",
  correctiveAction: "",
  requestAction: false,
  date: null,
  timeFrom: null,
  timeTo: null,
  ticket_id: null,
  product: null,
  status: null,
  closedBy: "",
  productionLeaderButton: buttonNotClicked,
  maintenanceTehnicianButtons: [
    buttonNotClicked,
    buttonIsDisabled,
    buttonIsDisabled,
  ],
  preventiveMaintenanceButtons: [buttonNotClicked, buttonIsDisabled],
  maintenanceSupervisorButtons: [buttonNotClicked, buttonIsDisabled],
  requestPlannedActionBy: null,
  approvePlannedActionBy: null,
  rejectPlannedActionBy: null,
};

const TicketContext = createContext<{
  state: ContextState;
  dispatch: Dispatch<ContextAction>;
}>({
  state: initialState,
  dispatch: () => {},
});
const reducer: ReducerType = (state, action) => {
  switch (action.type) {
    case "SET_TICKET_TYPE":
      return { ...state, ticketType: action.payload };
    case "SET_REASON":
      return { ...state, reason: action.payload };
    case "SET_MACHINE":
      return { ...state, machines: action.payload };
    case "SET_PRIORITY":
      return { ...state, priority: action.payload };
    case "SET_OPERATOR_ID":
      return { ...state, operatorId: action.payload };
    case "SET_COMPONENT_NUMBER":
      return { ...state, componentNumber: action.payload };
    case "SET_OPENED_AT":
      return { ...state, openedAt: action.payload };
    case "SET_MAINTENANCE_TECHNICIAN":
      return { ...state, maintenanceTechnician: action.payload };
    case "SET_L1_RECEIVED_AT":
      return { ...state, l1ReceivedAt: action.payload };
    case "SET_L1_FORWARDED_AT":
      return { ...state, l1ForwardedAt: action.payload };
    case "SET_MAINTENANCE_SUPERVISOR":
      return { ...state, maintenanceSupervisor: action.payload };
    case "SET_FORWARDED_TO":
      return { ...state, forwardedTo: action.payload };
    case "SET_L2_RECEIVED_AT":
      return { ...state, l2ReceivedAt: action.payload };
    case "SET_CLOSED_AT":
      return { ...state, closedAt: action.payload };
    case "SET_ISSUE_DESCRIPTION":
      return { ...state, issueDescription: action.payload };
    case "SET_CORRECTIVE_ACTION":
      return { ...state, correctiveAction: action.payload };
    case "SET_REQUEST_ACTION":
      return { ...state, requestAction: action.payload };
    case "SET_DATE":
      return { ...state, date: action.payload };
    case "SET_TIME_FROM":
      return { ...state, timeFrom: action.payload };
    case "SET_TIME_TO":
      return { ...state, timeTo: action.payload };
    case "SET_TICKET_ID":
      return { ...state, ticket_id: action.payload };
    case "SET_PRODUCT":
      return { ...state, product: action.payload };
    case "SET_STATUS":
      return { ...state, status: action.payload };
    case "SET_CLOSED_BY":
      return { ...state, closedBy: action.payload };
    case "APPROVE_PLANNED_ACTION":
      return { ...state, approvePlannedActionBy: action.payload };
    case "REQUEST_PLANNED_ACTION":
      return { ...state, requestPlannedActionBy: action.payload };
    case "REJECT_PLANNED_ACTION":
      return { ...state, rejectPlannedActionBy: action.payload };
    case "START_PREVENTIVE_MAINTENANCE":
      return {
        ...state,
        preventiveMaintenanceButtons: [
          (state.preventiveMaintenanceButtons[0] = {
            ...buttonIsClicked,
            clickedAt: new Date(),
          }),
          (state.preventiveMaintenanceButtons[1] = buttonNotClicked),
        ],
      };
    case "CLOSE_PREVENTIVE_MAINTENANCE":
      return {
        ...state,
        preventiveMaintenanceButtons: [
          (state.preventiveMaintenanceButtons[0] = buttonIsDisabled),
          (state.preventiveMaintenanceButtons[1] = {
            ...buttonIsClicked,
            clickedAt: new Date(),
          }),
        ],
      };
    case "OPEN_BUTTON_PRODUCTION_LEADER":
      return {
        ...state,
        productionLeaderButton: { ...buttonIsClicked, clickedAt: new Date() },
      };
    case "RECEIVE_BUTTON_MAIN_TECH":
      return {
        ...state,
        maintenanceTehnicianButtons: [
          (state.maintenanceTehnicianButtons[0] = {
            ...buttonIsClicked,
            clickedAt: new Date(),
          }),
          (state.maintenanceTehnicianButtons[1] = buttonNotClicked),
          (state.maintenanceTehnicianButtons[2] = buttonNotClicked),
        ],
      };
    case "CLOSE_BUTTON_MAIN_TECH":
      return {
        ...state,
        maintenanceTehnicianButtons: [
          state.maintenanceTehnicianButtons[0],
          (state.maintenanceTehnicianButtons[1] = {
            ...buttonIsClicked,
            clickedAt: new Date(),
          }),
          (state.maintenanceTehnicianButtons[2] = buttonIsDisabled),
        ],
      };
    case "FORWARD_BUTTON_MAIN_TECH":
      return {
        ...state,
        maintenanceTehnicianButtons: [
          state.maintenanceTehnicianButtons[0],
          (state.maintenanceTehnicianButtons[1] = buttonIsDisabled),
          (state.maintenanceTehnicianButtons[2] = {
            ...buttonIsClicked,
            clickedAt: new Date(),
          }),
        ],
      };
    case "RECEIVE_BUTTON_MAIN_SUPERVISOR":
      return {
        ...state,
        maintenanceSupervisorButtons: [
          (state.maintenanceSupervisorButtons[0] = {
            ...buttonIsClicked,
            clickedAt: new Date(),
          }),
          (state.maintenanceSupervisorButtons[1] = buttonNotClicked),
        ],
      };
    case "CLOSE_BUTTON_MAIN_SUPERVISOR":
      return {
        ...state,
        maintenanceSupervisorButtons: [
          state.maintenanceSupervisorButtons[0],
          (state.maintenanceSupervisorButtons[1] = {
            ...buttonIsClicked,
            clickedAt: new Date(),
          }),
        ],
      };
    case "REFRESH_STATE":
      return {
        ...initialState,
        productionLeaderButton: buttonNotClicked,
        maintenanceTehnicianButtons: [
          buttonNotClicked,
          buttonIsDisabled,
          buttonIsDisabled,
        ],
        maintenanceSupervisorButtons: [buttonNotClicked, buttonIsDisabled],
      };
    case "UPDATE_STATE":
      return action.payload;

    default:
      return state;
  }
};
type TicketProviderProps = {
  children: ReactNode;
};

export const TicketProvider = ({ children }: TicketProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <TicketContext.Provider value={{ state, dispatch }}>
      {children}
    </TicketContext.Provider>
  );
};

export const useTicket: ContextHook = () => {
  const { state, dispatch } = useContext(TicketContext);
  return { state, dispatch };
};
