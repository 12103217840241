import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ButtonComponent } from "../../components/ButtonComponent/ButtonComponent";
import { Loader } from "../../components/Loader/Loader";
import { useSessionStorage } from "../../hooks/useSessionStorage";

import { TicketService } from "../../service/tickets";
import { Page, Result, Status, StatusEnum, Ticket } from "../../types";

type Props = {
  deactivateLine: () => void;
};

export const ActiveLine: React.FC<Props> = ({ deactivateLine }) => {
  const navigate = useNavigate();

  const [openTickets, setOpenTickets] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [user] = useSessionStorage("user");
  useEffect(() => {
    TicketService.fetchAllByLineId(user.id_, 0, 100, [
      StatusEnum.OPEN,
      StatusEnum.FORWARDED,
      StatusEnum.PENDING,
      StatusEnum.ON_HOLD,
      StatusEnum.MATERIAL_NOT_AVAILABLE,
      StatusEnum.WAITING_FOR_APPROVAL,
      StatusEnum.PRIORITY,
      StatusEnum.APPROVED,
    ]).then((resp: Result<Page<Ticket>>) => {
      if (resp.status === Status.SUCCESS) {
        setOpenTickets(resp.value.content.length);
      }
      setIsLoading(false);
    });
  }, [user.id_]);

  const { t } = useTranslation();

  return (
    <>
      <div className="grid h-full">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="mx-8 mt-auto grid grid-cols-3 justify-items-center">
              <ButtonComponent
                onClick={() => navigate("/create-ticket")}
                classes="py-4 mr-6 rounded-md text-7xl text-white"
                buttonName={t("homePage.createNewTicket")}
              />

              <span className="relative inline-flex">
                <ButtonComponent
                  onClick={() =>
                    navigate("/ongoing-tickets", { state: "/ongoing-tickets" })
                  }
                  classes="py-4 rounded-md text-7xl relative text-white"
                  buttonName={t("homePage.openedTickets")}
                />
                {openTickets > 0 && (
                  <span className="absolute -right-4 -top-3 flex h-16 w-16">
                    <span className="absolute -mr-1 -mt-2 inline-flex h-full w-full animate-ping rounded-full bg-aptiv-sun opacity-75"></span>
                    <span className="relative -mr-2 -mt-2 inline-flex h-16 w-16 rounded-full bg-aptiv-sun text-4xl font-medium">
                      <span className="m-auto">{openTickets}</span>
                    </span>
                  </span>
                )}
              </span>

              <ButtonComponent
                onClick={() =>
                  navigate("/closed-tickets", { state: "/closed-tickets" })
                }
                classes="py-3 ml-6 rounded-md text-7xl text-white"
                buttonName={t("homePage.closedTickets")}
              />
            </div>
            <div className="mx-8 mb-4 mt-auto flex justify-between">
              <button
                className={
                  " rounded-md bg-blue-500 px-8 pb-4 text-center text-6xl font-normal text-white"
                }
                onClick={() => {
                  deactivateLine();
                }}
              >
                {t("homePage.deactivate")}
              </button>
              <ButtonComponent
                onClick={() => navigate("/login")}
                classes="px-8 rounded-md pb-4 text-6xl text-white"
                buttonName={t("common.logOut")}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
