import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonComponent } from "../components/ButtonComponent/ButtonComponent";
export const AdminHomePage = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  return (
    <div className="grid h-full">
      <div className="mx-4 mt-auto grid grid-cols-3 justify-items-center gap-3">
        {/* <ButtonComponent  classes="bg-aptiv-dark-gray rounded-md text-6xl  py-12 w-full" disabled={true} buttonName="Продукција" /> */}
        <div
          className="group w-full cursor-pointer rounded-md border-2 border-white border-opacity-20 bg-black bg-opacity-60 p-2 transition duration-200 ease-in hover:bg-white hover:bg-opacity-25"
          onClick={() => navigate("/login/user")}
        >
          <img
            src="/andon.png"
            alt="andon system"
            className="mx-auto h-24 w-auto max-w-sm pt-8"
          />
          <div className="my-12 mx-auto w-4/5 rounded-md bg-aptiv-orange py-8 text-center text-6xl font-normal text-white transition duration-200 ease-in group-hover:bg-aptiv-orange-700">
            Andon
          </div>
        </div>
        <div
          className="group w-full cursor-pointer rounded-md border-2 border-white border-opacity-20 bg-black bg-opacity-60 p-2 transition duration-200 ease-in hover:bg-white hover:bg-opacity-25"
          onClick={() => {
            // Redirect to reports page while NOT using react router
            window.location.href =
              process.env.REACT_APP_REPORTS_URL ?? "/bi";
          }}
        >
          <img
            src="/reports.png"
            alt="reports"
            className="mx-auto h-24 w-auto pt-8"
          />
          <div className="my-12 mx-auto w-4/5 rounded-md bg-aptiv-orange py-8 text-center text-6xl font-normal text-white transition duration-200 ease-in group-hover:bg-aptiv-orange-700">
            {t("userHomePage.reports")}
          </div>
        </div>
        <div
          className="group w-full cursor-pointer rounded-md border-2 border-white border-opacity-20 bg-black bg-opacity-60 p-2 transition duration-200 ease-in hover:bg-white hover:bg-opacity-25"
          onClick={() =>
            (window.location.href = process.env.REACT_APP_ADMIN_URL ?? "")
          }
        >
          <img
            src="/vlez.png"
            alt="admin-panel"
            className="mx-auto h-24 w-auto pt-8"
          />
          <div className="my-12 mx-auto w-4/5 rounded-md bg-aptiv-orange py-8 text-center text-6xl font-normal text-white transition duration-200 ease-in group-hover:bg-aptiv-orange-700">
          {t("userHomePage.input")}
          </div>
        </div>
      </div>
      <div className="mx-8 mb-4 mt-auto flex justify-between">
        <ButtonComponent
          onClick={() => navigate("/login")}
          classes="px-8 rounded-md pb-4 text-6xl text-white"
          buttonName={t("common.logOut")}
        />
        {/* <ButtonComponent onClick={() => navigate("/")} classes="bg-aptiv-dark-gray px-8 rounded-md pb-4 text-6xl" buttonName="Почетна" /> */}
      </div>
    </div>
  );
};
