import { Machine } from "../../types";
import { useNavigate } from "react-router-dom";
import { IssueTypeHeader } from "../IssueTypeHeader/IssueTypeHeader";
import { useTicket } from "../../TicketContext";
import { ButtonComponent } from "../ButtonComponent/ButtonComponent";

type MachineProp = {
  machines: Array<Machine>;
  loader: any;
};
export const MachineList: React.FC<MachineProp> = ({ machines, loader }) => {
  const navigate = useNavigate();

  const { dispatch } = useTicket();

  return (
    <div className="grid h-full">
      <IssueTypeHeader issueType="Машини на линија" />
      <div className="flex justify-end mr-4">
        <div className="my-auto text-red-700 text-xl font-bold">
          Повлечи за повеќе машини
        </div>
        <img src="/pointer.svg" alt="pointer" className="align-center" />
      </div>
      <div className="no-scrollbar grid gap-5 grid-flow-col grid-rows-2 overflow-x-auto">
        {machines.map((m) => {
          return (
            <button
              key={m.id_}
              onClick={() => {
                dispatch({
                  type: "SET_MACHINE",
                  payload: [m],
                });
                navigate("/status-of-the-line");
              }}
              className="h-36 w-[32rem] text-center text-4xl font-bold bg-aptiv-orange rounded-md text-white"
            >
              {m.name}
            </button>
          );
        })}
        <div ref={loader} className="block"></div>
      </div>

      <div className="flex justify-between mb-auto mx-8">
        <ButtonComponent
          onClick={() => navigate("/")}
          classes="px-8  rounded-md pb-4 text-6xl"
          buttonName="Почетна"
        />

        <ButtonComponent
          onClick={() => navigate(-1)}
          classes="px-8  rounded-md pb-4 text-6xl"
          buttonName="Назад"
        />
      </div>
    </div>
  );
};
