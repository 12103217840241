import React, { useRef, useState } from "react";
import { Formik } from "formik";

import { TextInput } from "../components/TextInput/TextInput";
import { useTicket } from "../TicketContext";
import { TestButton } from "../components/TestButton/TestButton";
import { useNavigate } from "react-router-dom";
import { useKeyboard } from "../hooks/useKeyboard";
import Keyboard from "react-simple-keyboard";
import {
  CloseTicketRequest,
  ModalType,
  Result,
  Status,
  StatusEnum,
  Ticket,
  User,
} from "../types";
import { TicketService } from "../service/tickets";
import { AccountService } from "../service/accounts";
import { Modal } from "../components/Modal/Modal";

export const layout = {
  default: [
    "1 2 3 4 5 6 7 8 9 0 - = {bksp}",
    "{tab} q w e r t y u i o p [ ] \\",
    "{lock} a s d f g h j k l ; ' {enter}",
    "{shift} z x c v b n m , . / {space}",
  ],
  shift: [
    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
    "{tab} Q W E R T Y U I O P { } |",
    '{lock} A S D F G H J K L : " {enter}',
    "{shift} Z X C V B N M < > ? {space}",
  ],
};

export const TicketProblemDescription: React.FC = () => {
  const navigate = useNavigate();
  const ticketContext = useTicket();

  const dispatchTicket = ticketContext.dispatch;

  const [isVisible, toggleVisible] = useKeyboard(false);
  const keyboard = useRef(null);
  const [inputs, setInputs] = useState({});
  console.log(inputs);
  const [inputName, setInputName] = useState("default");
  const [layoutName, setLayoutName] = useState("default");
  const [open, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalType>({} as ModalType);
  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  const onChangeAll = (inputs: any) => {
    setInputs({ ...inputs });
  };

  const handleKeyboardStatusChange = () => {
    toggleVisible();
  };

  const onKeyPress = (button: string) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };
  const handleClose = async (
    card_id: string,
    ticket_id: number,
    ticket: CloseTicketRequest
  ) => {
    console.log(ticket);
    const user: Result<User> = await AccountService.fetchByCardId(card_id);
    if (user.status === Status.ERROR) {
      setIsOpen(true);
      setModalProps({
        title: "Настана грешка",
        subtitle: `${user.message}`,
      });
      return;
    }

    const result: Result<Ticket> = await TicketService.closeTicket(
      ticket_id,
      ticket
    );
    if (result.status === Status.SUCCESS) {
      dispatchTicket({
        type: "SET_CLOSED_AT",
        payload: Date.now(),
      });
      dispatchTicket({
        type: "SET_STATUS",
        payload: StatusEnum.CLOSED,
      });
      dispatchTicket({
        type: "SET_ISSUE_DESCRIPTION",
        payload: ticket.issue_description,
      });
      dispatchTicket({
        type: "SET_CORRECTIVE_ACTION",
        payload: ticket.corrective_action,
      });
      navigate(`/tickets/${ticket_id}`);
    }
  };

  return (
    <>
      <Formik
        initialValues={{ issueDescription: "", correctiveAction: "" }}
        onSubmit={(values, actions) => {
          const closeTicket: CloseTicketRequest = {
            card_id: ticketContext.state.closedBy,
            corrective_action: values.correctiveAction,
            issue_description: values.issueDescription,
          };
          handleClose(
            ticketContext.state.closedBy,
            ticketContext.state.ticket_id,
            closeTicket
          );
        }}
      >
        {(props) => (
          <form
            className="mt-5 w-full justify-center"
            onSubmit={(e) => e.preventDefault()}
            action="return false;"
          >
            <div className="flex justify-center">
              <div className="mr-5 rounded-lg border px-12 py-12">
                <label
                  htmlFor="id"
                  className="ml-px block pb-3 pl-4 text-center text-2xl font-medium text-white"
                >
                  Опис на проблемот
                </label>
                <div className="mt-3 flex justify-center text-center">
                  <TextInput
                    type="text"
                    name={"issueDescription"}
                    id={"issueDescription"}
                    autoFocus={true}
                    placeholder={"issueDescription"}
                    onClick={handleKeyboardStatusChange}
                    setInputName={setInputName}
                  ></TextInput>
                </div>
              </div>

              <div className="rounded-lg border px-12 py-12">
                <label
                  htmlFor="id"
                  className="ml-px block pb-3 pl-4 text-center text-2xl font-medium text-white"
                >
                  Опис на решението
                </label>
                <div className="mt-3 flex justify-center">
                  <TextInput
                    type="text"
                    name={"correctiveAction"}
                    id={"correctiveAction"}
                    autoFocus={true}
                    placeholder={"correctiveAction"}
                    onClick={handleKeyboardStatusChange}
                    setInputName={setInputName}
                  ></TextInput>
                </div>
              </div>
            </div>
            <div className="mt-3 flex justify-center">
              <TestButton
                type="button"
                className={
                  "mt-3 mr-2   mb-2 rounded-md bg-aptiv-light-gray py-4 px-10 text-3xl"
                }
                onClick={() => {
                  props.handleSubmit();
                }}
              >
                Зачувај
              </TestButton>
            </div>
            {isVisible && (
              <Keyboard
                keyboardRef={(r) => (keyboard.current = r)}
                inputName={inputName}
                onChangeAll={(inputs: any) => {
                  props.setFieldValue(
                    "issueDescription",
                    inputs.issueDescription
                  );
                  props.setFieldValue(
                    "correctiveAction",
                    inputs.correctiveAction
                  );
                  onChangeAll(inputs);
                }}
                //layout={layout}
                theme="hg-theme-default myTheme2"
                onKeyPress={onKeyPress}
                layoutName={layoutName}
              />
            )}
          </form>
        )}
      </Formik>
      <Modal open={open} setOpen={setIsOpen} modalProps={modalProps} />
    </>
  );
};
