import { IssueTypeHeader } from "../IssueTypeHeader/IssueTypeHeader";

import React from "react";
import { Formik, Form } from "formik";
import { IssueCheckbox } from "../IssueCheckbox/IssueCheckbox";
import { useNavigate } from "react-router";
import { useTicket } from "../../TicketContext";
import { Machine } from "../../types";
import { useEffect } from "react";

type PreventiveMaintenanceProp = {
  machines: Array<Machine>;
};
export const PreventiveMaintenanceList: React.FC<PreventiveMaintenanceProp> = ({
  machines,
}) => {
  const { dispatch, state } = useTicket();
  const navigate = useNavigate();
  const ticketContext = useTicket();
  const dispatchTicket = ticketContext.dispatch;
  const handleChange = () => {
    return state.requestAction ? "/request-action" : "/status-of-the-line";
  };
  const handleChecked = () => {
    return state.requestAction ? [] : machines.map((m) => String(m.id_));
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: "SET_REQUEST_ACTION",
        payload: false,
      });
    };
  }, [dispatch]);

  return (
    <>
      {machines.length > 0 && (
        <div className="grid h-full">
          <IssueTypeHeader issueType="Избери машина" />
          <div className="absolute right-0 flex justify-end mr-4">
            <div className="my-auto text-red-700 text-xl font-bold">
              Повлечи за повеќе машини
            </div>
            <img src="/pointer.svg" alt="pointer" className="align-center" />
          </div>
          <div>
            <Formik
              initialValues={{
                checked: handleChecked(),
              }}
              onSubmit={(values, actions) => {
                dispatchTicket({
                  type: "SET_MACHINE",
                  payload: machines.filter((m: Machine) =>
                    values.checked.includes(String(m.id_))
                  ),
                });
                navigate(handleChange());
              }}
            >
              {(props) => (
                <Form
                  className="w-screen overflow-auto no-scrollbar"
                  onSubmit={(e) => e.preventDefault()}
                  action="return false;"
                >
                  <div className=" w-full  grid gap-5 grid-flow-col no-scrollbar grid-rows-2 overflow-x-auto">
                    {machines.map((machine, idx) => {
                      return (
                        <IssueCheckbox
                          machineName={machine.name}
                          machineId={machine.id_}
                          checkedValues={props.values.checked}
                        />
                      );
                    })}
                  </div>
                  {/* <div className="flex justify-center">
                <button
                  type="button"
                  className="mt-20 p-4 text-6xl bg-aptiv-orange rounded-md"
                  onClick={() => {
                    props.handleSubmit();
                  }}
                >
                  Поднеси барање
                </button>
              </div> */}
                  <div className="flex justify-between my-auto mx-8">
                    <button
                      type="button"
                      onClick={() => navigate("/")}
                      className="mt-20 p-4 text-6xl bg-aptiv-orange rounded-md"
                    >
                      Почетна
                    </button>
                    <button
                      type="button"
                      className="mt-20 p-4 text-6xl bg-aptiv-orange rounded-md"
                      onClick={() => {
                        props.handleSubmit();
                      }}
                    >
                      Поднеси барање
                    </button>
                    <button
                      type="button"
                      onClick={() => navigate(-1)}
                      className="mt-20 p-4 text-6xl bg-aptiv-orange rounded-md"
                    >
                      Назад
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};
