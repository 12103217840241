import { AxiosResponse } from "axios";
import { instance } from "./axios";
import { CreateComponentRequest, Page, Component } from "../types";

export interface IComponentApi {
  fetchAll(
    page: number,
    page_size: number
  ): Promise<AxiosResponse<Page<Component>>>;
  fetchById(component_id: number): Promise<AxiosResponse<Component>>;
  createComponent(
    component: CreateComponentRequest
  ): Promise<AxiosResponse<Component>>;
  // update(
  //   component_id: number,
  //   component: CreateComponentRequest
  // ): Promise<AxiosResponse<Component>>;
  delete(component_id: number): Promise<AxiosResponse<string>>;
}

export const ComponentApi: IComponentApi = {
  async fetchAll(
    page: number,
    page_size: number
  ): Promise<AxiosResponse<Page<Component>>> {
    const route = "/components/";
    return await instance.get<Page<Component>>(route, {
      params: { page, page_size },
    });
  },
  async fetchById(component_id: number): Promise<AxiosResponse<Component>> {
    const route = `/components/${component_id}`;
    return await instance.get<Component>(route);
  },
  async createComponent(
    component: CreateComponentRequest
  ): Promise<AxiosResponse<Component>> {
    const route = "/components/";
    return await instance.post<Component>(route, component);
  },
  // async update(
  //   component_id: number,
  //   component: CreateComponentRequest
  // ): Promise<AxiosResponse<Component>> {
  //   const route = `/components/${component_id}`;
  //   return await instance.patch<Component>(route, component);
  // },
  async delete(component_id: number): Promise<AxiosResponse<string>> {
    const route = `/components/${component_id}`;
    return await instance.delete<string>(route);
  },
};
