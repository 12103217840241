import { Form, Formik } from "formik";
import { TextInput } from "../TextInput/TextInput";
import * as Yup from "yup";
import { ChangePasswordRequest } from "../../types";
import { useSessionStorage } from "../../hooks/useSessionStorage";

interface FormProps {
  onClick: (req: ChangePasswordRequest) => void;
  cancel: () => void;
}

const PasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Полето е задолжително"),
  password: Yup.string()
    .required("Полето е задолжително")
    .notOneOf(
      [Yup.ref("oldPassword")],
      "Новата лозинка не може да биде старата"
    ),
  confirmPassword: Yup.string()
    .required("Полето е задолжително")
    .oneOf([Yup.ref("password")], "Лозинките не се совпаѓаат"),
});

export const UpdatePasswordForm: React.FC<FormProps> = ({
  onClick,
  cancel,
}) => {
  const [user] = useSessionStorage("user");

  return (
    <Formik
      initialValues={{
        oldPassword: "",
        password: "",
        confirmPassword: "",
      }}
      onSubmit={(values, actions) => {
        onClick({
          old_password: values.oldPassword,
          new_password: values.password,
          repeat_password: values.confirmPassword,
          username: user.username,
        });
      }}
      validationSchema={PasswordSchema}
    >
      <Form>
        <TextInput
          type="password"
          name="oldPassword"
          id="oldPassword"
          placeholder="Стара лозинка"
          label="Стара лозинка"
          keyboard={false}
        ></TextInput>

        <TextInput
          type="password"
          name="password"
          id="password"
          placeholder="Нова лозинка"
          label="Нова лозинка"
          keyboard={false}
        ></TextInput>
        <TextInput
          type="password"
          name="confirmPassword"
          id="confirmPassword"
          placeholder="Потврди ја лозинката"
          label="Потврди ја лозинката"
          keyboard={false}
        ></TextInput>

        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="submit"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Промени
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => cancel()}
          >
            Откажи
          </button>
        </div>
      </Form>
    </Formik>
  );
};
