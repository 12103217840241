import React, { useCallback, useEffect, useState } from "react";
import { Result, Status, Ticket } from "../types";
import { useNavigate, useParams } from "react-router-dom";
import { TicketService } from "../service/tickets";
import { useTicket } from "../TicketContext";
import moment from "moment";
import { Loader } from "../components/Loader/Loader";
import { TableDetail } from "../components/Table/TableDetail";
import { useSessionStorage } from "../hooks/useSessionStorage";
export const TicketOverviewDetail: React.FC = () => {
  const { id } = useParams<"id">();
  //const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { dispatch } = useTicket();
  const [hasMachine, setHasMachine] = useState(false);
  const changeTicketContext = useCallback(
    (ticket: Ticket) => {
      if (ticket.machines.length > 0) {
        setHasMachine(true);
      }
      dispatch({
        type: "UPDATE_STATE",
        payload: {
          ticketType: ticket.ticket_type,
          machines: ticket.machines,
          status: ticket.status,
          ticket_id: ticket.id_,
          date: ticket.datetime_from,
          timeFrom: ticket.datetime_from ? moment(ticket.datetime_from) : null,
          timeTo: ticket.datetime_to ? moment(ticket.datetime_to) : null,
          priority: ticket.priority,
          reason: ticket.reason,
          operatorId: ticket.created_by,
          componentNumber: ticket.component_id,
          openedAt: ticket.created_at,
          product: ticket.product,
          correctiveAction: ticket.corrective_action,
          issueDescription: ticket.issue_description,
          closedAt : ticket.closed_at || null, 
          l1ReceivedAt:
            ticket.handled_by.length > 0
              ? ticket.handled_by[0].accepted_on
              : null,
          maintenanceTechnician:
            ticket.handled_by.length > 0 ? ticket.handled_by?.[1].user : null,
          closedBy: ticket.closed_by,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    setIsLoading(true);

    TicketService.fetchById(parseInt(id!)).then((resp: Result<Ticket>) => {
      if (resp.status === Status.SUCCESS) {
        changeTicketContext(resp.value);
        setIsLoading(false);
      }
    });
  }, [id, changeTicketContext]);

  useEffect(() => {
    return () => {
      dispatch({
        type: "REFRESH_STATE",
      });
    };
  }, [dispatch]);

  const [isLine] = useSessionStorage("isLine");
  const handleNavigate = () => {
    return isLine ? "/" : "/user";
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="grid">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <button
              className="bg-aptiv-orange-500 hover:bg-aptiv-orange-700 text-white font-bold py-2 px-4 rounded mx-auto mt-4 uppercase"
              onClick={() => {
                dispatch({
                  type: "REFRESH_STATE",
                });
                navigate(handleNavigate());
              }}
            >
              Почетна
            </button>
            <div className="flex mt-8">
              <div className="ml-4 w-1/3">
                <div className="flex justify-center">
                  {hasMachine && (
                    <img
                      src="https://images.unsplash.com/photo-1563968743333-044cef800494?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1058&q=80"
                      width={250}
                      height={250}
                      alt="placeholder"
                    />
                  )}
                </div>
              </div>
              <div className="ml-4 w-2/3">
                <TableDetail title={"Информации за тикетот"} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
