import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Result, Status, StatusEnum, Ticket } from "../types";
import { TicketService } from "../service/tickets";
import {
  useTicket,
  buttonIsClicked,
  buttonNotClicked,
  buttonIsDisabled,
} from "../TicketContext";
import { Loader } from "../components/Loader/Loader";
import { OpenNewTicket } from "./OpenNewTicket";

export const PendingTicket: React.FC = () => {
  const { id } = useParams<"id">();

  const { dispatch } = useTicket();
  const [isLoading, setIsLoading] = useState(true);

  const changeTicketContext = useCallback(
    (ticket: Ticket) => {
      const payloadTicket = {
        ticketType: ticket.ticket_type,
        machines: ticket.machines,
        priority: ticket.priority,
        reason: ticket.reason,
        operatorId: ticket.created_by,
        componentNumber: ticket.component_id,
        openedAt: ticket.created_at,
        product: ticket.product,
        status: ticket.status,
        productionLeaderButton: buttonIsClicked,
        ticket_id: ticket.id_,
      };
      if (ticket.status === StatusEnum.OPEN) {
        dispatch({
          type: "UPDATE_STATE",
          payload: {
            ...payloadTicket,
            maintenanceTehnicianButtons: [
              buttonNotClicked,
              buttonIsDisabled,
              buttonIsDisabled,
            ],
            preventiveMaintenanceButtons: [buttonNotClicked, buttonIsDisabled],
            maintenanceSupervisorButtons: [buttonNotClicked, buttonIsDisabled],
          },
        });
      } else if (ticket.status === StatusEnum.PENDING) {
        if (ticket.handled_by.length === 2) {
          dispatch({
            type: "UPDATE_STATE",
            payload: {
              ...payloadTicket,
              maintenanceTehnicianButtons: [
                buttonIsClicked,
                buttonNotClicked,
                buttonNotClicked,
              ],
              maintenanceTechnician: ticket.handled_by[1].user,
              maintenanceSupervisorButtons: [
                buttonNotClicked,
                buttonIsDisabled,
              ],
              l1ReceivedAt: ticket.handled_by[1].accepted_on,
              preventiveMaintenanceButtons: [buttonIsClicked, buttonNotClicked],
            },
          });
        } else if (ticket.handled_by.length >= 3) {
          dispatch({
            type: "UPDATE_STATE",
            payload: {
              ...payloadTicket,
              maintenanceTehnicianButtons: [
                buttonIsClicked,
                buttonNotClicked,
                buttonIsClicked,
              ],
              maintenanceTechnician:
                ticket.forwarded_to.length > 0
                  ? ticket.forwarded_to[0].from_user
                  : ticket.handled_by[1].user,
              maintenanceSupervisorButtons: [buttonIsClicked, buttonNotClicked],
              maintenanceSupervisor: ticket.handled_by[2].user,
              l1ReceivedAt: ticket.handled_by[1].accepted_on,
              l2ReceivedAt: ticket.handled_by[2].accepted_on,
              l1ForwardedAt:
                ticket.forwarded_to.length > 0
                  ? ticket.forwarded_to[0].forwarded_on
                  : ticket.handled_by[1].accepted_on,
              forwardedTo:
                ticket.forwarded_to.length > 0
                  ? ticket.forwarded_to[0].to_user
                  : null,
            },
          });
        }
      } else if (ticket.status === StatusEnum.FORWARDED) {
        dispatch({
          type: "UPDATE_STATE",
          payload: {
            ...payloadTicket,
            maintenanceTehnicianButtons: [
              buttonIsClicked,
              buttonNotClicked,
              buttonIsClicked,
            ],
            maintenanceSupervisorButtons: [buttonNotClicked, buttonIsDisabled],
            l1ReceivedAt: ticket.handled_by[1].accepted_on,
            l1ForwardedAt:
              ticket.forwarded_to.length > 0
                ? ticket.forwarded_to[0].forwarded_on
                : ticket.handled_by[1].accepted_on,
            forwardedTo:
              ticket.forwarded_to.length > 0
                ? ticket.forwarded_to[0].to_user
                : null,
            maintenanceTechnician:
              ticket.forwarded_to.length > 0
                ? ticket.forwarded_to[0].from_user
                : ticket.handled_by[1].user,
          },
        });
      } else if (
        ticket.status === StatusEnum.PRIORITY ||
        ticket.status === StatusEnum.ON_HOLD
      ) {
        if (ticket.handled_by.length === 0) {
          dispatch({
            type: "UPDATE_STATE",
            payload: {
              ...payloadTicket,
              maintenanceTehnicianButtons: [
                buttonNotClicked,
                buttonIsDisabled,
                buttonIsDisabled,
              ],
            },
          });
        } else if (ticket.handled_by.length === 1) {
          let payload: any = {
            ...payloadTicket,
          };
          if (ticket.handled_by[0].accepted_on) {
            payload = {
              ...payload,
              maintenanceTehnicianButtons: [
                buttonIsClicked,
                buttonNotClicked,
                buttonNotClicked,
              ],
              maintenanceTechnician: ticket.handled_by[0].user,
              maintenanceSupervisorButtons: [
                buttonNotClicked,
                buttonIsDisabled,
              ],
              l1ReceivedAt: ticket.handled_by[0].accepted_on,
            };
          }
          if (ticket.handled_by[0].forwarded_on) {
            payload = {
              ...payload,
              maintenanceTehnicianButtons: [
                buttonIsClicked,
                buttonNotClicked,
                buttonIsClicked,
              ],
              maintenanceSupervisorButtons: [
                buttonNotClicked,
                buttonIsDisabled,
              ],
              l1ReceivedAt: ticket.handled_by[0].accepted_on,
              l1ForwardedAt: ticket.handled_by[0].forwarded_on,
              maintenanceTechnician: ticket.handled_by[0].user,
            };
          }

          dispatch({
            type: "UPDATE_STATE",
            payload: {
              ...payload,
            },
          });
        } else if (ticket.handled_by.length >= 2) {
          dispatch({
            type: "UPDATE_STATE",
            payload: {
              ...payloadTicket,
              maintenanceTehnicianButtons: [
                buttonIsClicked,
                buttonNotClicked,
                buttonIsClicked,
              ],
              maintenanceTechnician: ticket.handled_by[0].user,
              maintenanceSupervisorButtons: [buttonIsClicked, buttonNotClicked],
              maintenanceSupervisor: ticket.handled_by[1].user,
              l1ReceivedAt: ticket.handled_by[0].accepted_on,
              l2ReceivedAt: ticket.handled_by[1].accepted_on,
            },
          });
        }
      }
    },
    [dispatch]
  );
  useEffect(() => {
    setIsLoading(true);
    let mounted = true;
    TicketService.fetchById(parseInt(id!)).then((resp: Result<Ticket>) => {
      if (resp.status === Status.SUCCESS) {
        if (mounted) {
          changeTicketContext(resp.value);
          setIsLoading(false);
        }
      }
    });
    return () => {
      mounted = false;
    };
  }, [id, changeTicketContext]);

  return (
    <>
      <div className="grid">{isLoading ? <Loader /> : <OpenNewTicket />}</div>
    </>
  );
};
