import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useKeyboard } from "../../hooks/useKeyboard";
import { TextInput } from "../TextInput/TextInput";
import { KeyboardWrapper } from "../Keyboard/KeyboardWrapper";
import { TestButton } from "../TestButton/TestButton";
import { AccountService } from "../../service/accounts";
import { Result, Status, User } from "../../types";
type AuthenticateModalProps = {
  open: boolean;
  setOpen: (input: boolean) => void;
  setOpenUpdateTicket: (input: boolean) => void;
  setCardId: (input: string) => void;
};

const AuthSchema = Yup.object().shape({
  id: Yup.string().required("Задолжително поле"),
});

export const Authenticate: React.FC<AuthenticateModalProps> = ({
  open,
  setOpen,
  setOpenUpdateTicket,
  setCardId
}) => {
  const keyboard = useRef(null);
  const [isVisible, toggleVisible] = useKeyboard(false);
  const [error, setError] = useState<string | undefined>(undefined);
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block h-screen w-screen transform overflow-hidden rounded-lg bg-black p-16 pt-28 text-left align-bottom shadow-xl transition-all">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  ></button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-white"
                    >
                      Автентикација
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-5 ml-14 sm:mt-4 sm:text-left">
                  <Formik
                    initialValues={{ id: "" }}
                    validationSchema={AuthSchema}
                    onSubmit={async (values) => {
                      const user: Result<User> =
                        await AccountService.fetchByCardId(values.id);
                      if (user.status === Status.SUCCESS) {
                        setOpen(false);
                        setOpenUpdateTicket(true);
                        setCardId(values.id);
                      } else if (user.status === Status.ERROR) {
                        setError(user.message);
                      }
                    }}
                  >
                    {(props) => {
                      return (
                        <Form>
                          <TextInput
                            name="id"
                            label="Card id"
                            placeholder="Card id"
                            type="text"
                            onClick={toggleVisible}
                            id={"id"}
                          ></TextInput>
                          {error && (
                            <div className="mt-2 text-center font-bold text-red-600 text-xl">
                              {error}
                            </div>
                          )}
                          <div className="mt-3 flex justify-center">
                            <TestButton
                              className={
                                "mt-3 mr-2 mb-2 rounded-md bg-aptiv-orange py-4 px-4 text-3xl text-white"
                              }
                              type="button"
                              onClick={() => {
                                props.handleSubmit();
                              }}
                            >
                              Автентицирај се
                            </TestButton>
                          </div>
                          {isVisible && (
                            <div className="absolute right-0 bottom-16 w-2/3">
                              <KeyboardWrapper
                                keyboardRef={keyboard}
                                onChange={(input: string) => {
                                  props.handleChange(input);
                                  props.setFieldValue("id", input);
                                }}
                              />
                            </div>
                          )}
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
