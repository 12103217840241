import {
  EventsConfig,
  MachineProblemTypeEnum,
  StatusEnum,
  TeamEnum,
  Ticket,
} from "./types";

export const getMachineProblemType = (id: number) => {
  switch (id) {
    case 0:
      return "Проблем со машина";
    case 1:
      return "Проблем со квалитет";
    case 2:
      return "Превентивно одржување";
    case 3:
      return "Проблем со материјали";
    case 4:
      return "Планирана акција";
    default:
      return "";
  }
};

export const getPriorityType = (id: number) => {
  switch (id) {
    case 0:
      return "1 - Прекин на производство";
    case 1:
      return "2 - Намалување на ОЕЕ";
    case 2:
      return "3 - Потенцијално застанување";
    case 3:
      return "4 - Не е критично";
    case 4:
      return "5 - Неактивно";
    case 5:
      return "Планирана акција";
    case 6:
      return "Превентивно одржување";
    default:
      return "";
  }
};

export const getPriorityTypeWithoutNumbers = (prio: number) => {
  switch (prio) {
    case 0:
      return "Прекин на производство";
    case 1:
      return "Намалување на ОЕЕ";
    case 2:
      return "Потенцијално застанување";
    case 3:
      return "Не е критично";
    case 4:
      return "Неактивно";
    case 5:
      return "Планирана акција";
    default:
      return "";
  }
};

export const getStatus = (id: number) => {
  switch (id) {
    case 0:
      return "Отворен";
    case 1:
      return "Затворен";
    case 2:
      return "Во тек";
    case 3:
      return "Препратен";
    case 4:
      return "Паузиран";
    case 5:
      return "Чека за одобрување";
    case 6:
      return "Одобрено";
    case 7:
      return "Во тек";
    case 8:
      return "Одбиено";
    case 9:
      return "Приоритетно";
    case 10:
      return "Материјалот не е достапен";
    default:
      return "";
  }
};

export const getTeam = (team: TeamEnum) => {
  switch (team) {
    case TeamEnum.A:
      return "A";
    case TeamEnum.B:
      return "B";
    case TeamEnum.C:
      return "C";
    case TeamEnum.N:
      return "N";
    default:
      return "nema tim";
  }
};

export const navigateTicketTo = (ticket: Ticket) => {
  if (
    ticket.status === StatusEnum.CLOSED ||
    ticket.status === StatusEnum.REJECTED
  ) {
    return `/tickets/${ticket.id_}`;
  } else if (ticket.ticket_type === MachineProblemTypeEnum.PLANNED_ACTION) {
    return `/pending-action/${ticket.id_}`;
  } else if (ticket.ticket_type === MachineProblemTypeEnum.MATERIALS_PROBLEM) {
    return `/pending-material-ticket/${ticket.id_}`;
  }
  return `/open-new-ticket/${ticket.id_}`;
};

export const getEscalationRoleByTicketType = (
  config: EventsConfig,
  ticketType: MachineProblemTypeEnum
) => {
  switch (ticketType) {
    case MachineProblemTypeEnum.MACHINE_PROBLEM:
      return config.MACHINE_PROBLEM.forwarded_escalation.role_id;
    case MachineProblemTypeEnum.MAINTENANCE_PROBLEM:
      return config.MAINTENANCE_PROBLEM.forwarded_escalation.role_id;
    case MachineProblemTypeEnum.MATERIALS_PROBLEM:
      return config.MATERIALS_PROBLEM.escalation_1.role_id;
    case MachineProblemTypeEnum.QUALITY_PROBLEM:
      return config.QUALITY_PROBLEM.forwarded_escalation.role_id;
    default:
      return 0;
  }
};
