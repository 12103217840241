import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import { Layout } from "./components/Layout/Layout";
import { ConsoleHomePage } from "./pages/ConsoleHomePage";
import { AdminHomePage } from "./pages/AdminHomePage";
import { CreateTicket } from "./pages/CreateTicket";
import { MachineInterruption } from "./pages/MachineInterruption";
import { LoginPage } from "./pages/LoginPage";
import { MaterialIssue } from "./pages/MaterialIssue";
import { MachineOnTheLine } from "./pages/MachineOnTheLine";
import { StatusOfTheLine } from "./pages/StatusOfTheLine";
import { PreventiveMaintenance } from "./pages/PreventiveMaintenance";
import { MaterialIssueForm } from "./pages/MaterialIssueForm";
import { OngoingTickets } from "./pages/Tickets";
import { TicketProvider } from "./TicketContext";
import { OpenNewTicket } from "./pages/OpenNewTicket";
import { TicketOverviewDetail } from "./pages/TicketOverviewDetail";
import { TicketProblemDescription } from "./pages/TicketProblemDescription";
import { PlannedAction } from "./pages/PlannedAction";
import { RequestAction } from "./pages/RequestAction";
import { PendingAction } from "./pages/PendingAction";
import { PrivateRoute } from "./pages/PrivateRoute";
import { PendingTicket } from "./pages/PendingTicket";
import { UserReports } from "./pages/UserReports";
import { LineReports } from "./pages/LineReports";
import { ReportDetails } from "./pages/ReportDetails";
import { ChooseReport } from "./pages/ChooseReport";
import { AddNewReason } from "./pages/AddNewReason";
import { AddNewComponent } from "./pages/AddNewComponent";
import { StatusEnum } from "./types";
import { PendingMaterialTicket } from "./pages/PendingMaterialTicket";
import { AndonLines } from "./pages/AndonLines";
import { TicketReports } from "./pages/TicketReports";
import { AndonLineDetails } from "./pages/AndonLineDetails";
import { LanguageContextProvider } from "./LanguageContext";
import { MachineReports } from "./pages/MachineReports";
const App: React.FC = () => {
  return (
    <BrowserRouter>
      <TicketProvider>
        <LanguageContextProvider>
          <Layout>
            <Routes>
              <Route
                path="/login"
                element={<LoginPage isUserAlreadyLoggedIn={false} />}
              />

              <Route
                path="/login/user"
                element={
                  <PrivateRoute>
                    <LoginPage isUserAlreadyLoggedIn={true} />
                  </PrivateRoute>
                }
              />

              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <ConsoleHomePage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/user"
                element={
                  <PrivateRoute>
                    <AdminHomePage />{" "}
                  </PrivateRoute>
                }
              />
              <Route
                path="/create-ticket"
                element={
                  <PrivateRoute>
                    <CreateTicket />
                  </PrivateRoute>
                }
              />
              <Route
                path="/machine-interruption"
                element={
                  <PrivateRoute>
                    <MachineInterruption />
                  </PrivateRoute>
                }
              />
              <Route
                path="/material-issue"
                element={
                  <PrivateRoute>
                    <MaterialIssue />
                  </PrivateRoute>
                }
              />
              <Route
                path="/machine-on-the-line"
                element={
                  <PrivateRoute>
                    <MachineOnTheLine />
                  </PrivateRoute>
                }
              />
              <Route
                path="/status-of-the-line"
                element={
                  <PrivateRoute>
                    <StatusOfTheLine />
                  </PrivateRoute>
                }
              />
              <Route
                path="/preventive-maintenance"
                element={
                  <PrivateRoute>
                    <PreventiveMaintenance />
                  </PrivateRoute>
                }
              />
              <Route
                path="/add-new-reason"
                element={
                  <PrivateRoute>
                    <AddNewReason />
                  </PrivateRoute>
                }
              />
              <Route
                path="/add-new-component"
                element={
                  <PrivateRoute>
                    <AddNewComponent />
                  </PrivateRoute>
                }
              />
              <Route
                path="/material-issue/new"
                element={
                  <PrivateRoute>
                    <MaterialIssueForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/open-new-ticket"
                element={
                  <PrivateRoute>
                    <OpenNewTicket />
                  </PrivateRoute>
                }
              />
              <Route
                path="/open-new-ticket/:id"
                element={
                  <PrivateRoute>
                    <PendingTicket />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ongoing-tickets"
                element={
                  <PrivateRoute>
                    <OngoingTickets
                      status={[
                        StatusEnum.OPEN,
                        StatusEnum.FORWARDED,
                        StatusEnum.PENDING,
                        StatusEnum.ON_HOLD,
                        StatusEnum.MATERIAL_NOT_AVAILABLE,
                        StatusEnum.WAITING_FOR_APPROVAL,
                        StatusEnum.PRIORITY,
                        StatusEnum.APPROVED,
                      ]}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/closed-tickets"
                element={
                  <PrivateRoute>
                    <OngoingTickets
                      status={[StatusEnum.CLOSED, StatusEnum.REJECTED]}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/pending-requests"
                element={
                  <PrivateRoute>
                    <OngoingTickets
                      status={[StatusEnum.WAITING_FOR_APPROVAL]}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/approved-requests"
                element={
                  <PrivateRoute>
                    <OngoingTickets status={[StatusEnum.APPROVED]} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ticket-problem-description"
                element={
                  <PrivateRoute>
                    <TicketProblemDescription />
                  </PrivateRoute>
                }
              />
              <Route
                path="/pending-material-ticket/:id"
                element={
                  <PrivateRoute>
                    <PendingMaterialTicket />
                  </PrivateRoute>
                }
              />
              <Route
                path="/tickets/:id"
                element={
                  <PrivateRoute>
                    <TicketOverviewDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="/planned-action"
                element={
                  <PrivateRoute>
                    <PlannedAction />
                  </PrivateRoute>
                }
              />
              <Route
                path="/request-action"
                element={
                  <PrivateRoute>
                    <RequestAction />
                  </PrivateRoute>
                }
              />
              <Route
                path="/pending-action/:id"
                element={
                  <PrivateRoute>
                    <PendingAction />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reports"
                element={
                  <PrivateRoute>
                    <ChooseReport />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reports/users"
                element={
                  <PrivateRoute>
                    <UserReports />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reports/tickets"
                element={
                  <PrivateRoute>
                    <TicketReports />
                  </PrivateRoute>
                }
              />
              <Route path="/reports/lines" element={<LineReports />} />
              <Route
                path="/reports/:id"
                element={
                  <PrivateRoute>
                    <ReportDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reports/andon-lines"
                element={
                  <PrivateRoute>
                    <AndonLines />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reports/andon-machines"
                element={
                  <PrivateRoute>
                    <MachineReports />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reports/andon-lines/:id"
                element={
                  <PrivateRoute>
                    <AndonLineDetails />
                  </PrivateRoute>
                }
              />
            </Routes>
          </Layout>
        </LanguageContextProvider>
      </TicketProvider>
    </BrowserRouter>
  );
};

export default App;
