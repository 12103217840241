import { Form, Formik } from "formik";
import { useRef } from "react";
import { useKeyboard } from "../../hooks/useKeyboard";
import { KeyboardWrapper } from "../Keyboard/KeyboardWrapper";
import { TestButton } from "../TestButton/TestButton";
import { TextInput } from "../TextInput/TextInput";
import * as Yup from "yup";

const PendingActionSchema = Yup.object().shape({
  id: Yup.string().required("Задолжително поле"),
});

type StartActionProps = {
  onReject: (card_id: string) => void;
  onStart: (card_id: string) => void;
};

export const StartAction: React.FC<StartActionProps> = ({
  onReject,
  onStart,
}) => {
  const keyboard = useRef(null);
  const [isVisible, toggleVisible] = useKeyboard(false);
  //const {state} = useTicket();
  return (
    <Formik
      initialValues={{ id: "" }}
      onSubmit={(values, actions) => {}}
      validationSchema={PendingActionSchema}
    >
      {(props) => (
        <Form
          className="mt-5 w-full"
          autoComplete="off"
          onSubmit={(e) => e.preventDefault()}
          action="return false;"
        >
          <label
            htmlFor="name"
            className="block ml-px pb-3 pl-4 text-center text-white text-2xl font-medium"
          >
            Одговорен на смена
          </label>

          <div className="flex justify-center mt-3">
            <TextInput
              type="text"
              name="id"
              id="id"
              autoFocus={true}
              placeholder="id"
              onClick={toggleVisible}
            ></TextInput>
          </div>
          <div className="grid grid-cols-2 ">
            <TestButton
              className={
                "py-4 px-4 mt-3 rounded-md ml-2 mb-2 text-3xl bg-aptiv-orange "
              }
              type="button"
              onClick={() => onStart(props.values.id)}
            >
              Започни
            </TestButton>
            <TestButton
              className={
                "py-4 px-4 mt-3 rounded-md m-2 mb-2 text-3xl bg-aptiv-orange "
              }
              type="button"
              onClick={() => onReject(props.values.id)}
            >
              Одбиј барање
            </TestButton>
          </div>
          <div className="">
            {isVisible && (
              <KeyboardWrapper
                keyboardRef={keyboard}
                onChange={(input: string) => {
                  props.handleChange(input);
                  props.setFieldValue("id", input);
                }}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
