import { ComponentApi } from "../api/component";
import { AxiosResponse } from "axios";
import { Handler } from "./result";
import { Component, CreateComponentRequest, Result, Page } from "../types";

export interface IComponentService {
  fetchAll(page: number, page_size: number): Promise<Result<Page<Component>>>;
  fetchById(component_id: number): Promise<Result<Component>>;
  createComponent(
    component: CreateComponentRequest
  ): Promise<Result<Component>>;
  // update(
  //   component_id: number,
  //   component: CreateComponentRequest
  // ): Promise<Result<Component>>;
  delete(component_id: number): Promise<Result<string>>;
}

export const ComponentService: IComponentService = {
  async fetchAll(
    page: number,
    page_size: number
  ): Promise<Result<Page<Component>>> {
    return await ComponentApi.fetchAll(page, page_size)
      .then((resp: AxiosResponse<Page<Component>>) =>
        Handler.SuccessResult<Page<Component>>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async fetchById(component_id: number): Promise<Result<Component>> {
    return await ComponentApi.fetchById(component_id)
      .then((resp: AxiosResponse<Component>) =>
        Handler.SuccessResult<Component>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  async createComponent(
    component: CreateComponentRequest
  ): Promise<Result<Component>> {
    return await ComponentApi.createComponent(component)
      .then((resp: AxiosResponse<Component>) =>
        Handler.SuccessResult<Component>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
  // async update(
  //   component_id: number,
  //   component: CreateComponentRequest
  // ): Promise<Result<Component>> {
  //   return await ComponentApi.update(component_id, component)
  //     .then((resp: AxiosResponse<Component>) =>
  //       Handler.SuccessResult<Component>(resp.data)
  //     )
  //     .catch((reason) => Handler.ErrorResult(reason));
  // },
  async delete(component_id: number): Promise<Result<string>> {
    return await ComponentApi.delete(component_id)
      .then((resp: AxiosResponse<string>) =>
        Handler.SuccessResult<string>(resp.data)
      )
      .catch((reason) => Handler.ErrorResult(reason));
  },
};
